import { CSSProperties } from "react";

export interface Styles {
  applyBtn: CSSProperties;
  addressTitle: CSSProperties;
  addressIcon: CSSProperties;
  addressDescription: CSSProperties;
  wrap: CSSProperties;
  addressDescriptionText: CSSProperties;
  panelName: CSSProperties;
  whiteWrap: CSSProperties;
  value500: CSSProperties;
  value600: CSSProperties;
  cartTitle: CSSProperties;
  shopWrap: CSSProperties;
  offerWrap: CSSProperties;
  cartLeftCol: CSSProperties;
  cartRightCol: CSSProperties;
  productImageWrap: CSSProperties;
  productImage: CSSProperties;
  productNameWt: CSSProperties;
  productName: CSSProperties;
  productWt: CSSProperties;
  productPrice: CSSProperties;
  oneCart: CSSProperties;
  cartFirst: CSSProperties;
  cartItem: CSSProperties;
  supplyMethod: CSSProperties;
  supplyMethodText: CSSProperties;
  emptyCartMessage: CSSProperties;
  noProduct: CSSProperties;
  btnName: CSSProperties;
  trash: CSSProperties;
  trashIcon: CSSProperties;
  selectedAddressWrap: CSSProperties;
  selectedAddressTitle: CSSProperties;
  selectedAddressText: CSSProperties;
  max400: CSSProperties;
  error:  CSSProperties;
}

const baseStyles: Styles = {
  applyBtn: {
    fontSize: 12,
    backgroundColor: "var(--primary-color)",
    color: "var(--white-color)",
    fontFamily: "var(--primary-font-medium)",
  },
  whiteWrap: {
    paddingLeft: 50,
    paddingRight: 50,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingTop: 100,
    paddingBottom: 60,
  },
  error: {
    color: "#EA4335",
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
  },
  value500: {
    fontSize: 18,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    gap:"2rem"
  },
  value600: {
    fontSize: 18,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
  cartTitle: {
    fontSize: 18,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
    marginBottom: 16,
  },
  shopWrap: {
    marginBottom: 10,
  },
  offerWrap: {
    backgroundColor: "#F0F6FF",
    borderRadius: 16,
    padding: 10,
  },
  cartRightCol: {
    paddingLeft: 30,
    paddingBottom: 50,
  },
  cartLeftCol: {
    paddingRight: 30,
    borderRightWidth: 1,
    borderRightStyle: "solid",
    borderRightColor: "#D9D9D9",
  },
  cartFirst: {
    // marginBottom:50,
    borderBottomWidth: 0,
    borderBottomStyle: "solid",
    borderBottomColor: "#D9D9D9",
    alignItems: "flex-start",
  },
  productImageWrap: {
    width: 100,
    height: 100,
    overflow: "hidden",
    marginRight: 10,
    // boxShadow: "0px 0px 2px 0px #00000040",
    borderRadius: 16,
    backgroundColor: "#F5F5F5",
    padding: 7,
    cursor:"pointer"
  },
  productImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  productNameWt: {
    maxWidth: 200,
  },
  productName: {
    fontSize: 16,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
    marginBottom: 20,
    cursor:"pointer"
  },
  btnName: {
    fontSize: 16,
    paddingLeft: 20,
    paddingRight: 20,
    fontFamily: "var(--primary-font-semibold)",
  },
  panelName: {
    fontSize: 18,
    textDecoration: "none",
    color: "var(--text-color)",
    fontFamily: "var(--primary-font-semibold)",
  },
  productWt: {
    fontSize: 16,
    marginBottom: 0,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
  },
  productPrice: {
    fontSize: 20,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
  },
  emptyCartMessage: {},
  oneCart: { marginBottom: 20 },
 cartItem : {
    paddingTop: 20,

    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#D9D9D9",
  },
  supplyMethod: {
    background: "#F5F5F5",
    borderRadius: 6,
    padding: 7,
    paddingLeft: 16,
    paddingRight: 16,
  },
  supplyMethodText: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 14,
    color: "var(--text-color)",
    marginBottom: 0,
  },
  noProduct: {
    fontSize: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "var(--primary-font-semibold)",
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    padding: 20,
    borderRadius: 16,
  },
  addressTitle: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    marginLeft: 10,
    textTransform: "capitalize",
    color: "var(--text-color)",
  },
  addressIcon: {
    color: "#808080",
    fontSize: 20,
    marginLeft: 10,
  },
  addressDescription: {
    backgroundColor: "#F5F5F5",
    borderRadius: 6,
    paddingTop: 40,
    minHeight: 210,
    paddingBottom: 40,
    position: "relative",
    paddingInline: 20,
    marginTop: 10,
  },
  addressDescriptionText: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 18,
    color: "var(--text-color)",
    marginBottom: 0,
  },
  wrap: {
    display: "flex",
    marginRight: 25,
    alignItems: "center",
  },
  trash: {
    background: "none",
    position: "absolute",
    right: 10,
    top: 10,
    border: 0,
  },
  trashIcon: {
    color: "#eb4a3c",
  },
  selectedAddressWrap: {
    borderRadius: 16,
    padding: 10,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#D9D9D9",
  },
  selectedAddressTitle: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 18,
    color: "var(--text-color)",
  },
  selectedAddressText: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--text-color)",
  },
  max400: { maxWidth: 400 },
};

const mobileStyles: Partial<Styles> = {
  cartLeftCol: {
    paddingRight: 15,
    borderRightWidth: 0,
  },
  max400: { maxWidth: "100" },
  whiteWrap: {
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingTop: 0,
    paddingBottom: 50,
  },
  productWt: {
    fontSize: 11,
    marginBottom: 0,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
  },
  productImageWrap: {
    width: 70,
    height: 70,
    overflow: "hidden",
    marginRight: 10,
    // boxShadow: "0px 0px 2px 0px #00000040",
    borderRadius: 16,
    backgroundColor: "#F5F5F5",
    padding: 7,
  },
  cartTitle: {
    fontSize: 14,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
    marginBottom: 16,
  },
  supplyMethodText: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 11,
    color: "var(--text-color)",
    marginBottom: 0,
  },
  supplyMethod: {
    background: "#F5F5F5",
    borderRadius: 6,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  productPrice: {
    fontSize: 14,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
  value500: {
    fontSize: 14,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
  },
  value600: {
    fontSize: 14,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
  addressTitle: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 14,
    marginLeft: 10,
    textTransform: "capitalize",
    color: "var(--text-color)",
  },
  addressIcon: {
    color: "#808080",
    fontSize: 14,
    marginLeft: 10,
  },
  addressDescription: {
    backgroundColor: "#F5F5F5",
    borderRadius: 6,
    paddingTop: 20,
    // minHeight:210,
    paddingBottom: 20,
    position: "relative",
    paddingInline: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  addressDescriptionText: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 14,
    color: "var(--text-color)",
    marginBottom: 0,
  },
  wrap: {
    display: "flex",
    marginRight: 0,
    alignItems: "center",
  },
  trash: {
    background: "none",
    position: "absolute",
    right: 10,
    top: 10,
    border: 0,
  },
  trashIcon: {
    color: "#eb4a3c",
  },
  selectedAddressWrap: {
    borderRadius: 16,
    padding: 10,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#D9D9D9",
  },
  selectedAddressTitle: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 18,
    color: "var(--text-color)",
  },
  selectedAddressText: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--text-color)",
  },
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};
