import React, { useState, useEffect } from "react";
import styles from "./MainCategories.style";
import Section from "../Section/Section";
import { Col, Container, Image, Row } from "react-bootstrap";
import Heading from "../Heading/Heading";
import { categories } from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { mainId } from "../../Redux/dataSlice";
import { RootState } from "../../Redux/Store/Store";
import { SET_MAIN_CATEGORY_LIST, SET_SELECTED_CATEGORY } from "../../Redux/mainCategory";
import noProduct from '../../assets/images/no-product.png';
import { useTranslation } from "react-i18next";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { Player } from '@lottiefiles/react-lottie-player';
import lottieCategory from '../../assets/lotties/main-category.json';

const MainCategory: React.FC = () => {
  interface Category {
    id: string;
    name: string;
    category_image: string;
    language_data: any;
  }

  const { t } = useTranslation();

  const [mainCategory, setMainCategory] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [cateName, setCateName] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const languageId: any = useSelector((state: RootState) => state.lan);
  const MINIMUM_LOADING_TIME = 3000; // 2 seconds

  const dispatch = useDispatch();
  dispatch(mainId(selectedCategory));

  const fetchCategories = async () => {
    const startTime = Date.now();

    try {
      setLoading(true);
      const response = await categories();
      if (response && response.status === 200) {
        // console.log('fetchCategories------>', response.data);
        setMainCategory([...response.data]);
        dispatch(SET_MAIN_CATEGORY_LIST(response.data));

        const defaultCategory = [...response.data].sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0))[0];
        // console.log('defaultCategory------------>', defaultCategory);
        dispatch(SET_SELECTED_CATEGORY(defaultCategory));
        setSelectedCategory(defaultCategory.id);
        localStorage.setItem(STORAGE_CONSTANTS.CATEGORY_NAME, defaultCategory.name);
      } else {
        console.log("Error fetching categories");
      }
    } catch (error) {
      console.log("Error fetching categories", error);
    } finally {
      const elapsedTime = Date.now() - startTime;
      const remainingTime = MINIMUM_LOADING_TIME - elapsedTime;
      setTimeout(() => {
        setLoading(false);
      }, remainingTime > 0 ? remainingTime : 0);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleId = (item: any) => {
    dispatch(SET_SELECTED_CATEGORY(item));
    setCateName(item?.name);
    setSelectedCategory(item?.id);
  };

  return (
    <Section className="mainCategory mt-5">
      <Container>
        <Row style={{ marginBottom: 20 }}>
          <Col>
            <Heading text={t("homeScreen.titleSectionCategories")} alignment="left" />
          </Col>
        </Row>
        <Row className="cat-row">
          {loading && (
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                <Player
                  src={lottieCategory}
                  loop
                  autoplay
                  className="player"
                  style={{ height: '100px', width: '100px' }}
                />
              </Col>
            </Row>
          )}
          {!loading && mainCategory.length === 0 && (
            <Row className="noData">
              <Col className="d-flex align-items-center justify-content-center">
                <h3 style={styles.noProduct}>
                  <Image src={noProduct} className="mx-2" style={{ width: 32, height: 32 }} /> No Shops found in your area
                </h3>
              </Col>
            </Row>
          )}
          {!loading && mainCategory.length > 0 && mainCategory
            .sort((a, b) => (a.id < b.id ? -1 : 1))
            .map((item) => {
              const isSelected = selectedCategory === item.id;
              return (
                <Col key={item.id}>
                  <div
                    className={`main-category-col ${isSelected ? 'selected-category' : ''}`}
                    style={styles.singleCategoryWrap}
                    onClick={() => handleId(item)}
                  >
                    <h2 style={styles.categoryName} className="desktop-category">
                      {item.language_data.length === 0 ? item.name : languageId === "ne" ? item?.language_data[0]?.name : item?.name}
                    </h2>
                    <div style={styles.categoryImgWrap} className="main-cat-img-wrap">
                      <img
                        src={item.category_image}
                        alt={item.name}
                        style={styles.categoryImage}
                      />
                    </div>
                  </div>
                  <div>
                    <h2 className="mobile-category">
                      {item.language_data.length === 0 ? item.name : languageId === "ne" ? item.language_data[0].name : item.name}
                    </h2>
                  </div>
                </Col>
              );
            })}
        </Row>
      </Container>
    </Section>
  );
};

export default MainCategory;
