import React, { useEffect, useState } from "react";
import Section from "../../Components/Section/Section";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Modal,
  Card,
  Accordion,
  Spinner,
} from "react-bootstrap";
import Heading from "../../Components/Heading/Heading";
import { getStyles } from "./OrderSuccessFail.style";
import { FaArrowRight, FaRegClock } from "react-icons/fa";
import ShopIcon from "../../assets/images/default-shop.png";
import OfferIcon from "../../assets/images/percent.png";
import ProductImage from "../../assets/images/aata.png";
import BillingIcon from "../../assets/images/billingInfo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Past_Orders, Payment_Status, View_Cart } from "../../Api";
import { base_url } from "../../Api/ApiConst";
import TopPickForYou from "../../Components/TopPickForYou/TopPickForYou";
import BargainRecommendation from "../../Components/BargainRecommendation/BargainRecommendation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import { CLEAR_USER_ADDRESS, INITIAL_TAB } from "../../Redux/User";
import { CLEAR_CART } from "../../Redux/Cart";
import { toast } from "react-toastify";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";

const OrderSuccessFail: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const breadcrumbs: string[] = ["Home", "Thank You"];

  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<any>("");
  const [uuid, setUuid] = useState<any>("");
  const dispatch = useDispatch();
  const userAddress: any = useSelector(
    (state: RootState) => state.User.UserAddress
  );
  const PickUp: any = useSelector(
    (state: RootState) => state.Cart.PickupAddress
  );

  //   const [products, setProducts] = useState<any>("");
  //   const [amount, setAmount] = useState<any>("");
  const navigate = useNavigate();

  const handleMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMethod(event.target.value);
  };

  const { first_name, last_name, address_str, city, pincode, mobile } =
    userAddress;

  const handleClear = () => {
    dispatch(CLEAR_CART());
    localStorage.removeItem(STORAGE_CONSTANTS.UUID);
    localStorage.removeItem(STORAGE_CONSTANTS.ORDER_ID);
  };

  const handleHome = () => {
    handleClear();
    dispatch(CLEAR_USER_ADDRESS());
    navigate("/");
  };

  const handleTrack = () => {
    handleClear();
    dispatch(CLEAR_USER_ADDRESS());
    navigate("/profile");
    dispatch(INITIAL_TAB("Order"));
    // navigate("/");
  };

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await Past_Orders(currentPage);
      if (response && response.status === 200) {
        // console.log(response.data.store_list.language_data[0].store_name);
        // console.log(response.data.user_order_list[0], "orders---------->");
        setProducts(response.data.user_order_list[0].order_details);
        setAmount(response.data.user_order_list[0]);
        handleClear();
        // setStores(response.data.user_order_list);
      } else {
        console.log("Error fetching past orders");
      }
    } catch (error) {
      console.log("Error fetching past orders", error);
    } finally {
      setLoading(false);
    }
  };

  const PaymentStatus = async () => {
    if (uuid) {
      try {
        const response = await Payment_Status(uuid);
        if (response && response.status === 200) {
          console.log(response.data, "status--------------->");

          if (response.data.payment_status === true) {
            // console.log("DDDDDDDDDDDDDDDDDDDDDDD")
            toast.success("Order Placed Successfully");
            dispatch(CLEAR_CART());
            localStorage.removeItem("uuid");
            localStorage.removeItem(STORAGE_CONSTANTS.ORDER_ID);
          }
        } else {
          console.log("Error fetching Payment status");
        }
      } catch (error: any) {
        console.log("Error fetching Payment status", error);
      }
    } else {
      console.log("submit order");
    }
  };

  useEffect(() => {
    if (uuid) {
      PaymentStatus();
    }
  }, [uuid]);

  useEffect(() => {
    fetchOrders();
    // console.log(window.location.href+"kkkkkkkkkkkk")
    setUuid(localStorage.getItem(STORAGE_CONSTANTS.UUID));
    window.scrollTo(0, 0);
    // toast.success("Order SUCCESSSSSSSSSSSSSS RESPAGE Successfully")
  }, []);

  const renderPickupAddress = (PickUpPaams: any) => {
    const findStore = products.find(
      (ele) => ele?.product?.product_vendor_store?.id == PickUpPaams?.id
    );

    // console.log("findStore----------------------->", findStore);

    return (
      !loading &&
      products.length !== 0 && (
        <div style={styles.selectedAddressWrap} className="py-2 mt-2">
          <Row>
            <Col sm={9}>
              <div style={styles.selectedAddressText}>
                <div style={styles.selectedAddressTitle}>
                  {findStore?.product?.product_vendor_store?.store_name}
                </div>
                {findStore?.product?.product_vendor_store?.address}{" "}
                {findStore?.product?.product_vendor_store?.city} <br />
                {findStore?.product?.product_vendor_store?.pincode}
              </div>
              {/* <div style={styles.day}>
                Pick up Date:
                <span style={styles.deliveryDate}> 12/01/2024</span>
              </div> */}
            </Col>
            <Col sm={3} className="hide-xs">
              <div style={styles.shopImageWrap}>
                <Image
                  src={
                    findStore?.product?.product_vendor_store?.logo
                      ? `${base_url}${findStore?.product?.product_vendor_store?.logo}`
                      : ShopIcon
                  }
                  style={styles.shopImage}
                />
              </div>
            </Col>
          </Row>
        </div>
      )
    );
  };

  const getStoreWithPickUpSelected = () => {
    // console.log(PickUp, "uuuuuuid-------->");
    const storeWithPickUpSelected = PickUp.filter(
      (ele: any) => ele?.selectedOption == "Pick Up"
    );
    return storeWithPickUpSelected.length > 0;
  };

  return (
    <>
      <Section className="viewCart">
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <Row className="py-4">
            <Heading text={t("cart.thankYou")} alignment="left" />
          </Row>
        </Container>
        <div style={styles.whiteWrap}>
          <Container className="below">
            <Row style={styles.cartFirst}>
              <Col sm={7} style={styles.cartLeftCol}>
                <div style={styles.cartTitle}>
                  <p className="mb-1"> {t("cart.confirmation")} </p>
                </div>

                <div style={styles.selectedAddressWrap} className="py-4">
                  <h3 style={styles.cartTitle}>{t("cart.shippingTo")}:</h3>
                  <div style={styles.selectedAddressText}>
                    <div style={styles.selectedAddressTitle}>
                      {" "}
                      {first_name && last_name && `${first_name} ${last_name}`}
                    </div>
                    {address_str}
                    <br></br>
                    {`${city},${pincode}`}
                    <br></br> {mobile}
                  </div>
                </div>
                {!loading && getStoreWithPickUpSelected() && (
                  <>
                    <div className="mt-4">
                      <h3 style={styles.cartTitle}>{t("cart.pickupFrom")}</h3>
                    </div>
                    {PickUp.map((ele: any) => {
                      if (ele?.selectedOption == "Pick Up") {
                        return renderPickupAddress(ele);
                      }
                    })}
                  </>
                )}
                <Row>
                  <Col sm={6}>
                    <div className="justify-content-start d-flex mt-3">
                      <Button
                        className="btnBlue"
                        style={{ width: "100%" }}
                        onClick={handleTrack}
                      >
                        {t("cart.trackYourOrder")}
                      </Button>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="justify-content-start d-flex mt-3">
                      <Button
                        className="btnBlue"
                        style={{ width: "100%" }}
                        onClick={handleHome}
                      >
                        {t("cart.continueShopping")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col sm={5} style={styles.cartRightCol}>
                {loading && (
                  <Row>
                    <Col className="d-flex align-items-center justify-content-center">
                      <Spinner animation="grow" variant="primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Col>
                  </Row>
                )}
                {!loading && products.length !== 0 && (
                  <div className="success-order-summary">
                    <h3 style={styles.cartTitle}>{t("cart.orderSummary")}</h3>
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span style={styles.value500}>
                        {t("cart.subTotal")} ({products.length}{" "}
                        {products.length > 1 ? "Items" : "Item"})
                      </span>
                      <span style={styles.value600}>₹{amount?.sub_total}</span>
                    </div>
                    {amount?.discount > 0 && (
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span style={styles.value500}>
                          {t("cart.itemSaving")}
                        </span>
                        <span style={styles.value600}>
                          ₹{Math.floor(amount?.discount)}
                        </span>
                      </div>
                    )}
                    <hr />
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span style={styles.value600}>
                        {t("cart.orderTotal")}
                      </span>
                      <span style={styles.value600}>₹{amount?.amount}</span>
                    </div>
                    <div style={styles.cartItem} className="success-order-item">
                      {products &&
                        products.map((ele: any) => {
                          // console.log(ele, "ele-------->");
                          return (
                            <Row
                              className="d-flex align-items-center cart-item-row "
                              style={styles.oneCart}
                            >
                              <Col sm={2} className="cartImageCol">
                                <div style={styles.productImageWrap}>
                                  <Image
                                    src={
                                      `${base_url}/${ele?.product?.product_image}` ||
                                      ProductImage
                                    }
                                    style={styles.productImage}
                                  />
                                </div>
                              </Col>
                              <Col sm={5} className="cartProductInfoCol">
                                <div style={styles.productNameWt}>
                                  <h3
                                    style={styles.productName}
                                    className="productName"
                                  >
                                    {ele?.product?.product_name}
                                  </h3>
                                  <h2 style={styles.productWt}>
                                    {ele?.product?.PRDWeight} kg
                                  </h2>
                                </div>
                              </Col>
                              <Col sm={2} className="cartQuantityCol">
                                <div
                                  className="add-to-cart"
                                  style={{ borderRadius: 10, padding: 5 }}
                                >
                                  <h2 style={styles.cartQty}>
                                    {" "}
                                    {t("userProfile.qty")} : {ele?.quantity}
                                  </h2>
                                </div>
                              </Col>
                              <Col sm={3} className="cartPriceCol">
                                <div style={styles.productPrice}>
                                  ₹{ele?.price}
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="mt-3">
              <TopPickForYou />
            </Row>
            <Row className="mt-3">
              <BargainRecommendation />
            </Row>
          </Container>
        </div>
      </Section>
    </>
  );
};

export default OrderSuccessFail;
