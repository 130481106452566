import { CSSProperties } from "react";
import TitleBg from "../../assets/images/title-bg.png";

export interface Styles {
  bg: CSSProperties;
  storeWrap: CSSProperties;
  storeImageWrap: CSSProperties;
  storeImage: CSSProperties;
  storeNameWrap: CSSProperties;
  storeName: CSSProperties;
  storeHeader: CSSProperties;
  card: CSSProperties;
  text:CSSProperties;
  total:CSSProperties;
  range:CSSProperties;
  date:CSSProperties;
  btnWrap:CSSProperties;
  btnBlue:CSSProperties;
}

const baseStyles: Styles = {
  bg: {
    backgroundColor:"#F0F6FF",
    paddingTop: 20,
    paddingBottom: 50,
  },
  storeWrap: {},
  storeImageWrap: { width: 70, height: 70 },
  storeImage: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    borderRadius: 15,
  },
  storeNameWrap: {},
  storeName: {
    marginLeft: 20,
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 50,
    color: "var(--text-color)",
    marginBottom: 0,
  },
  storeHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginBottom: 15,
  },
  card:{
    marginBottom:20,
    marginTop:50,
    paddingTop:60,
    paddingBottom:60,
    backgroundColor:"var(--white-color)",
    borderRadius:16,
    boxShadow: "8px 8px 15px 0px #0000000D",
    maxWidth:1135,
    marginLeft:"auto",
    marginRight:"auto",
    textAlign:"center"
  },
  text:{
    fontFamily:"var(--primary-font-regular)",
    fontSize:40,
    marginBottom:0,
    color:"var(--text-color)"
  },
  total:{
    fontFamily:"var(--primary-font-semibold)",
    fontSize:40,
    color:"var(--text-color)"
  },
  range:{
    fontFamily:"var(--primary-font-regular)",
    fontSize:40,
    color:"var(--text-color)",
    marginBottom:40,
  },
  date:{
    fontFamily:"var(--primary-font-semibold)",
    fontSize:40,
    color:"var(--text-color)"
  },
  btnWrap:{
    maxWidth:1135,
    marginLeft:"auto",
    marginRight:"auto", 
  },
  btnBlue:{
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "var(--white-color)",
    paddingTop: 10,
    paddingBottom: 10
  }
};
const mobileStyles: Partial<Styles> = {
  range:{
    fontFamily:"var(--primary-font-regular)",
    fontSize:16,
    color:"var(--text-color)",
    borderStyle:"solid",
    borderWidth:1,
    borderColor:"#999",
    width:"fit-content",
    paddingInline:15,
    paddingTop:10,
    paddingBottom:10,
    margin:"auto",
    marginBottom:10,
    borderRadius:15
  },
  text:{
    fontFamily:"var(--primary-font-regular)",
    fontSize:16,
    marginBottom:0,
    color:"var(--text-color)"
  },
  total:{
    fontFamily:"var(--primary-font-semibold)",
    fontSize:20,
    color:"var(--text-color)"
  },
  storeImageWrap: { width: 30, height: 30 },
  storeName: {
    marginLeft: 20,
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "var(--text-color)",
    marginBottom: 0,
  },
  card:{
    marginBottom:10,
    marginTop:10,
    paddingTop:20,
    paddingBottom:10,
    backgroundColor:"var(--white-color)",
    borderRadius:16,
    boxShadow: "8px 8px 15px 0px #0000000D",
    maxWidth:"auto",
    marginLeft:"auto",
    marginRight:"auto",
    textAlign:"center"
  },
  date:{
    fontFamily:"var(--primary-font-semibold)",
    fontSize:18,
    color:"var(--text-color)"
  },
  btnBlue:{
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 16,
    color: "var(--white-color)",
    paddingTop: 7,
    paddingBottom: 7
  }
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};