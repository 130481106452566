// Login.style.ts

import { CSSProperties } from "react";
import { Right } from "react-bootstrap/lib/Media";
import TitleBg from "../../assets/images/title-bg.png";
export interface Styles {
  title: CSSProperties;
  info: CSSProperties;
  image: CSSProperties;
  bg: CSSProperties;
  titleInfoWrap: CSSProperties;
}

const baseStyles: Styles = {
  bg: {
    backgroundImage: `url(${TitleBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: 80,
    paddingBottom: 0,
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    color: "var(--text-color)",
    fontFamily: "var(--primary-font-semibold)",
  },
  titleInfoWrap : {
    marginTop:50,
  },
  info: {
    fontSize: 18,
    marginTop: 10,
    color: "#919191",
    marginBottom: 50,
    fontFamily: "var(--primary-font-medium)",
  },
  image: {
    bottom: -20,
    position: "absolute",
    right: 0,
    alignItems: "flex-end",
  },
};

const mobileStyles: Partial<Styles> = {
  bg: {
    backgroundImage: `url(${TitleBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: 20,
    paddingBottom: 0,
    marginBottom: 20,
  },
  titleInfoWrap :{
    marginTop:10,
  },
   info: {
    fontSize: 18,
    marginTop: 10,
    color: "#919191",
    marginBottom: 10,
    fontFamily: "var(--primary-font-medium)",
  },
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};