// Login.style.ts

import { CSSProperties } from "react";
import { Right } from "react-bootstrap/lib/Media";

export interface Styles {
  subcategoryName: CSSProperties;
  catWrap: CSSProperties;
}

const styles: Styles = {
  subcategoryName: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    fontSize: 18,
    listStyle: "none",
    borderBottomWidth: 1,
    borderBottomStyle:"solid",
    borderBottomColor: "#d9d9d9",
    paddingBottom: 10,
    marginBottom: 0,
    paddingTop: 10,
  },
  catWrap :{
    paddingLeft:0,
  }
};

export default styles;
