import { CSSProperties } from "react";
import TitleBg from "../../assets/images/title-bg.png";

export interface Styles {
  bg: CSSProperties;
  whiteWrap: CSSProperties;
  btnBlue: CSSProperties;
  btnRed: CSSProperties;
  btnCancel: CSSProperties;
  error: CSSProperties;
}

const baseStyles: Styles = {
  btnBlue: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "var(--white-color)",
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 60,
    width: "31%",
    marginLeft: "3%",
    marginRight: "3%",
  },
  btnRed: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "var(--text-color)",
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 60,
    borderColor: "#EA4335",
    backgroundColor: "transparent",
    width: "25%",
  },
  btnCancel: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "var(--text-color)",
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 60,
    borderColor: "var(--text-color)",
    backgroundColor: "transparent",
    width: "31%",
  },
  bg: {
    backgroundImage: `url(${TitleBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: 40,
    paddingBottom: 100,
  },
  whiteWrap: {
    paddingLeft: 50,
    paddingRight: 50,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingTop: 60,
    paddingBottom: 60,
  },
  error: {
    color: "#EA4335",
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
  },
};

const mobileStyles: Partial<Styles> = {
  bg: {
    backgroundImage: `url(${TitleBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: 40,
    paddingBottom: 40,
  },

  whiteWrap: {
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingTop: 40,
    paddingBottom: 20,
  },
  btnCancel: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 16,
    color: "var(--text-color)",
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 20,
    borderColor: "var(--text-color)",
    backgroundColor: "transparent",
    width: "31%",
  },
  btnBlue: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 16,
    color: "var(--white-color)",
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 20,
    width: "31%",
    marginLeft: "3%",
    marginRight: "3%",
  },
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};
