import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Form, Button, Image } from 'react-bootstrap';
import LoginImg from "../../assets/images/login-modal.png";
import ThemeButton from '../../Components/Buttons/ThemeButton';
import ThemeButtonLight from '../../Components/Buttons/ThemeButtonLight';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Cart_OrderId, Get_User_Details, User_LogIn, View_Cart, login_OTP } from '../../Api';
import { toast } from 'react-toastify';
import Logo from '../../assets/images/logo.png';
import { useDispatch } from 'react-redux';
import { setLoginStatus } from '../../Redux/loginSlice';
import { FaTimes } from 'react-icons/fa';
import { getStyles } from './VerifyDetails.style';
import { STORAGE_CONSTANTS } from '../../shared/Constants/Constants';
import { VIEW_CART } from '../../Redux/Cart';
import { useTranslation } from 'react-i18next';

const VerifyDetails: React.FC =() => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const location = useLocation();
  const [otp, setOtp] = useState<string[]>(['', '', '', '', '', '']);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [fake, setFake] = useState<any>([]);
  const [lonumber, setLonumber] = useState<string | null>("");
  const [userid, setUserid] = useState<string | null>("");
  const [userOTP, setUserOTP] = useState<any>([]);
  const dispatch = useDispatch()
  const [checkoutStatus,setCheckoutStatus] = useState<string | null>("")
  const [error, setError] = useState({
    otp: "",
  });
  const navigate = useNavigate()
  // const enteredOtp = otp.join('');


  const handleChange = (index: number, value: string) => {
    if (value === "") {
      setError((error) => ({
        ...error,
        otp: t("responseMessage.enterOtp"),
      }));
    } else {
      setError((error) => ({
        ...error,
        otp: "",
      }));
    }
    
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to the next input field
    if (index < otp.length - 1 && value.length === 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  useEffect(() => {
    setLonumber(localStorage.getItem(STORAGE_CONSTANTS.NUMBER));
    setUserid(localStorage.getItem(STORAGE_CONSTANTS.USER_ID));
    const storedOTP = localStorage.getItem(STORAGE_CONSTANTS.USER_OTP);
    if (storedOTP) {
      const otpArray = storedOTP.split('');
      setFake(otpArray);
      setOtp(otpArray);
    }
    // setUserOTP(fake)
    setCheckoutStatus(localStorage.getItem(STORAGE_CONSTANTS.CHECKOUT_STATUS))
    
  }, []);

  const handleResendOTP = async () => {
    try {
      let response = await User_LogIn(lonumber); // Call your API function to resend OTP
      if (response && response.status === 200) {
        // localStorage.setItem("user_otp", response.data.otp);
        console.log(response.data.otp.toString().split(''),"split================>") 
        setUserOTP(response.data.otp.toString().split(''))  // Update OTP in localStorage
        toast.success("OTP sent successfully");
      } else {
        toast.error(response["data"]["message"]);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const enteredOtp = otp.join('');
    if(enteredOtp !== "" && enteredOtp.length === 6){
      const VerifyOTP = async () => {
        try {
          const response = await login_OTP(userid, enteredOtp);
          if (response && response.status === 200) {
            localStorage.setItem(STORAGE_CONSTANTS.USER_TOKEN,response?.data?.token)            
            toast.success(response["data"]["message"]);

           if(response.data.token){
              const Cartresponse = await Cart_OrderId();
            if (Cartresponse && Cartresponse.status === 200) {
              console.log(Cartresponse.data,"99999999999999")
              if(!localStorage.getItem(STORAGE_CONSTANTS.ORDER_ID)){
                localStorage.setItem(STORAGE_CONSTANTS.ORDER_ID,Cartresponse?.data?.cart_details?.order?.id ?? "");
              }

              const storeResponse = await Get_User_Details();
              if (storeResponse && storeResponse.status === 200) {
                localStorage.setItem(STORAGE_CONSTANTS.USER_NUMBER, storeResponse?.data?.user_details?.mobile_number)
              }

              const Cartresponses = await View_Cart();
              if (Cartresponses && Cartresponses.status === 200) {
                // console.log(Cartresponses.data, "kkkkkkkkkkkk");
                dispatch(VIEW_CART(Cartresponses.data));
                // console.log(Cartresponse.data,"99999999999999")
              }
            }
           }
            localStorage.setItem(STORAGE_CONSTANTS.IS_LOGGED_IN, 'true');
            dispatch(setLoginStatus(true))
            if(checkoutStatus === "true"){
              navigate('/view-cart')
            }else{
              navigate('/')
            }
          } else {
            toast.error(response["data"]["message"]);
          }
        } catch (error:any) {
          console.log("OTP Error",error);
          toast.error(error.response.data.message)
        }
      }
      VerifyOTP()
    }else {
      setError((error) => ({
        ...error,
        otp: t("responseMessage.enterOtp"),
      }));
    }
  };
  console.log(fake,"otp=============>");
  return (
    <Container fluid style={{ height: '100vh' }}>
      <Row style={{ height: '100%' }} className="sm-login">
        <Col md={6} style={styles.leftColumnStyle}>
          {/* Left column content */}
          <Link to="/"><FaTimes style={styles.closeIcon}  className='hide-lg'/> {/* Add the close button */}
          </Link>
          <div style={{ textAlign: "left" }}>
          <Link to={`/`} >
            <Image src={Logo} style={{width:224,height:88,marginBottom:50}}/>
            </Link>
            <h2 style={styles.formTitle}>{t("login.verifyDetails")}</h2>
            <h3 style={styles.formSubtitle}>{t("login.enterOtp")} {lonumber} </h3>
            <Form onSubmit={handleSubmit}>
              <div style={{ display: 'flex', justifyContent: 'center',marginBottom:10 }}>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    type="text"
                    maxLength={1}
                    value={digit}
                    className='form-control'
                    style={styles.input}
                    onChange={(e) => handleChange(index, e.target.value)}
                    // style={{
                    //   width: '30px',
                    //   height: '30px',
                    //   marginRight: '5px',
                    //   textAlign: 'center'
                    // }}
                  />
                ))}
                 
              </div>
              <p style={styles.bottomText} onClick={handleResendOTP}>{t("login.otpNotReceived")} <span style={styles.requestAgain}>{t("login.requestAgain")}</span></p>
              {error && (
            <span style={styles.error}>{error.otp}</span>
            
          )}
            
              <Button type="submit" style={styles.btnBlue}>{t("login.continue")}</Button>
            </Form>
          </div>
        </Col>
        <Col md={6} style={styles.rightColumnStyle} className='hide-xs hide-sm'>
          {/* Right column content */}
          <Link to="/"><FaTimes style={styles.closeIcon} /> {/* Add the close button */}
          </Link>
          <img src={LoginImg} alt="Image" style={styles.imageStyle} />
          <h2 style={styles.title}>Welcome Back</h2>
          <p style={styles.para}>Lorem Ipsum has been the industry's standard dummy text</p>
        </Col>
      </Row>
    </Container>
  );
};

export default VerifyDetails;
