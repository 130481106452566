import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import {
  Container,
  Form,
  Button,
  ProgressBar,
  Image,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import Section from "../../Components/Section/Section";
import { getStyles } from "./BankDetails.style";
import { toast } from "react-toastify";
import {
  GET_BANK_DETAILS,
  Store_BankDetails,
  Store_Registration,
  categories,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCheckCircle,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FaStore } from "react-icons/fa";
import StepAccount from "../../assets/images/stepAccount.png";
import StepStore from "../../assets/images/stepStore.png";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ACTIVE_USER } from "../../Redux/User";
import { base_url } from "../../Api/ApiConst";

interface FormData {
  businessName: string;
  ownerName: string;
  businessType: string;
  address: string;
  city: string;
  pinCode: string;
  registrationNumber: string;
  email: string;
  // contactPersonName: string;
  // contactPersonNumber: string;
  // idNumber: string;
  // idNumber2: string;
  // areaPinCode: string;
  // accountHolderName: string;
  // bank: string;
  // accountNumber: string;
  // ifscCode: string;
  // branch: string;
}

interface FileDetails {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
  }
  

const BankDetails: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const [step, setStep] = useState(2);
  interface Category {
    id: string;
    name: string;
    store_name: string;
    email: string;
    contact_number: any;
  }
  const [showModal, setShowModal] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    businessName: "",
    ownerName: "",
    businessType: "",
    address: "",
    city: "",
    pinCode: "",
    registrationNumber: "",
    email: "",
    // contactPersonName: "",
    // contactPersonNumber: "",
  });
  const [errorss, setErrorss] = useState<Partial<FormData>>({});
  // const [contactError,setContactError] = useState<string | number>({
  //    contactNumber : ""
  // })
  const [storeError, setStoreError] = useState<{
    cheque_copy?: string;
    // email?: string;
    // contact_number?: string;
    // store_type?: string;
  }>({});
  const [invalid, setInvalid] = useState<Category[]>([]);
  const [contactNumber, setContactNumber] = useState<any>("");
  const [contactPersonName, setContactPersonName] = useState<any>("");
  const [contactPersonNumber, setContactPersonNumber] = useState<any>("");
  const [mainCategory, setMainCategory] = React.useState<Category[]>([]);
  const [userToken, setUserToken] = useState<string | null>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [idNumber, setIdNumber] = useState<any>("");
  const [idNumber2, setIdNumber2] = useState<any>("");
  const [areaPinCode, setAreaPinCode] = useState<any>("");
  const [bankData, setBankData] = useState<any>("");
  const [bank, setBank] = useState<any>("");
  const [ifscCode, setIfscCode] = useState<any>("");
  const [branch, setBranch] = useState<any>("");
  const [accountNumber, setAccountNumber] = useState<any>("");
  // const [errors, setErrors] = useState<any>("");
  const [idNumber1File, setIdNumber1File] = useState<File | null>(null);
  const [idNumber2File, setIdNumber2File] = useState<File | null>(null);
  const [chequeFile, setChequeFile] = useState<any>("");
  const [prevChequeFile, setPrevChequeFile] = useState<any>("");



  const [error, setError] = useState({
    cheque: "",
  });

  const schema = yup.object().shape({
    branch: yup.string().required(t("responseMessage.branchNameRequired")),
    // idNumber2: yup.string().required("* lastname is required"),
    // email: yup
    //   .string()
    //   .required("* Enter your email")
    //   .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, " Email id is invalid"),
    // idNumber: yup.string().required("* Enter your Id"),

    // ifscCode: yup
    //   .string()
    //   .required(t("responseMessage.enterIfsc")),
      // .matches(/^[a-zA-Z0-9\s]+$/, t("responseMessage.invalidCode")),

    areaPinCode: yup
      .string()
      .required("* Enter your pincode")
      .matches(/^(?:[0-9\s,]*\d){1,6}$/, "Invalid pincode"),

    // idNumber2: yup.string().required("* Enter your Id"),

    accountNumber: yup
      .string()
      .required(t("responseMessage.enterAccountNumber"))
      .matches(/^[0-9]+$/, t("responseMessage.onlyDigits")),

    bank: yup.string().required(t("responseMessage.accountName")),
    accountHolderName: yup.string().required(t("responseMessage.accountName")),

    // date: yup
    //   .string()
    //   .required("* Enter your dob")
    //   .matches(
    //     /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
    //     "Date of Birth must be a valid date in the format YYYY-MM-DD"
    //   ),
  });

  const {
    register,
    formState: { errors },
    control,
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchCategories = async () => {
    try {
      const response = await categories();
      if (response && response.status === 200) {
        console.log(response.data);
        setMainCategory(response.data);
      } else {
        console.log("Error fetching categories");
      }
    } catch (error) {
      console.log("Error fetching categories", error);
    }
  };

  useEffect(() => {
    setContactNumber(localStorage.getItem(STORAGE_CONSTANTS.USER_NUMBER));
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    // setAreaPinCode(localStorage.getItem(STORAGE_CONSTANTS.AREA_PIN));
    const pincode = localStorage.getItem(STORAGE_CONSTANTS.AREA_PIN);
    reset({
      areaPinCode: pincode ?? "",
    });
    fetchCategories();
    BankDetails();
    // fetchFile()
    window.scrollTo(0, 0);
  }, []);

  const BankDetails = async () => {
    try {
      const response = await GET_BANK_DETAILS(userToken);
      if (response && response.status === 200) {
        // console.log(response.data, "respoonse==========>");
        const data = response.data.bank_details;
        setBankData(data);
        // console.log(data.id);
        // setBusinessType(data.buisness_type);
        // setStoreName(data.store_name);
        setIdNumber(data.id_document_1);
        setIdNumber2(data.id_document_2);
        if(data.cheque_copy){
          fetchFile(data.cheque_copy)
        }
        // setPrevChequeFile(data?.cheque_copy)
        // setStoreImages(data.logo);
        // setId(data.id);
        // localStorage.setItem(
        //   STORAGE_CONSTANTS.CONTACT_NUMBER,
        //   data.contact_number
        // );

        reset({
          // branch: data.branch ?? "",
          // idNumber: data.idNumber ?? "",
          // ifscCode: data.swift_code ?? "",
          // areaPinCode: areaPinCode ?? "",
          // idNumber2: data.idNumber2 ?? "",
          accountNumber: data.account_number ?? "",
          bank: data.bank_name ?? "",
          accountHolderName: data.account_name ?? "",
          branch: data.country ?? "",
        });
      } else {
        console.log("Error fetching set-store details");
      }
    } catch (error) {
      console.log("Error fetching set-store details", error);
    }
  };

  const fetchFile = async (ele:any) => {
    try {
     // Fetch the file from the URL
     const response = await fetch(`${base_url}${ele}`);
     const blob = await response.blob();

     // Create a File object with the desired properties
     const fileName = 'React_Js_Documentation.docx';
     const file = new File([blob], fileName, {
        type: blob.type,
        lastModified: Date.now() // Set last modified to the current timestamp
     });

     // Set the File object to state
     setPrevChequeFile(file);
    } catch (error) {
     console.error('Error fetching file:', error);
    }
};





  // const handleSubmits = (e: any) => {
  //   e.preventDefault();
  //   if (validateForm()) {
  //     console.log("valid");
  //     const handleAPIcall = async () => {
  //       try {
  //         const response: any = await Store_Registration(
  //           formData,
  //           userToken,
  //           contactNumber,
  //           contactPersonName,
  //           selectedOption,
  //           contactPersonNumber,
  //         );
  //         if (response && response.status === 201) {
  //           dispatch(ACTIVE_USER("store"));
  //           toast.success(response["data"]["message"]);
  //           localStorage.setItem(STORAGE_CONSTANTS.USER, contactPersonName);
  //           localStorage.setItem(
  //             STORAGE_CONSTANTS.STORE_ID,
  //             response.data.store_id
  //           );
  //           localStorage.setItem(
  //             STORAGE_CONSTANTS.STORE_NAME,
  //             response.data.store_name
  //           );
  //           navigate('/store-dashboard')
  //           // console.log(response.data);
  //           // setStep(2);
  //           // navigate('/login')
  //         } else {
  //           console.log(response.data);
  //         }
  //       } catch (error: any) {
  //         console.log("Error occurred:", error.response.data);
  //         setStoreError(error.response.data.error_message);
  //       }
  //     };
  //     handleAPIcall();
  //     console.log(formData);
  //   }
  // };

  // const handleStore = async () => {
  //   try {
  //     const response = await Store_BankDetails(idNumber,idNumber2,areaPinCode,branch,bank,accountHolderName,ifscCode,accountNumber,userToken);
  //     if (response && response.status === 200) {
  //       toast.success(response["data"]["message"]);
  //       // console.log(response);
  //       // localStorage.setItem("user", formData.contactPersonName)
  //       navigate("/set-store");
  //     } else {
  //       toast.error(response["data"]["message"]);
  //     }
  //   } catch (error: any) {
  //     // const errorObject = JSON.parse(error.message);
  //     // const storeNameError = errorObject;
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   if (
  //     idNumber !== "" &&
  //     idNumber2 !== "" &&
  //     areaPinCode !== "" &&
  //     branch !== "" &&
  //     bank !== "" &&
  //     accountHolderName !== "" &&
  //     ifscCode !== "" &&
  //     accountNumber !== ""
  //   ){
  //     handleStore();
  //   }
  // }, [
  //   idNumber,idNumber2,areaPinCode,accountHolderName,ifscCode,accountNumber,branch,bank
  // ]);

  // const validateForm = () => {
  //   let valid = true;
  //   const newErrors: Partial<FormData> = {};

  //   // Required fields validation
  //   for (const key in formData) {
  //     if (!formData[key as keyof FormData]) {
  //       newErrors[key as keyof FormData] = "Required";
  //       valid = false;
  //     }
  //   }

  //   // Email format validation
  //   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailPattern.test(formData.email)) {
  //     newErrors.email = "Invalid email format";
  //     valid = false;
  //   }

  //   // Phone number format validation (assuming 10 digits without formatting)
  //   // const phonePattern = /^\d{10}$/;
  //   // if (!phonePattern.test(formData.contactPersonNumber)) {
  //   //   newErrors.contactPersonNumber = "Invalid phone number format";
  //   //   valid = false;
  //   // }

  //   // const contactNumberPattern = /^\d{10}$/;
  //   // if (!contactNumberPattern.test(contactNumber)) {
  //   //   contactNumber = "Invalid phone number format";
  //   //   valid = false;
  //   // }

  //   // Pincode format validation (assuming 6 digits without formatting)
  //   const pincodePattern = /^\d{6}$/;
  //   if (!pincodePattern.test(formData.pinCode)) {
  //     newErrors.pinCode = "Invalid pincode format";
  //     valid = false;
  //   }

  //   if (!formData.businessType) {
  //     newErrors.businessType = "Required";
  //     valid = false;
  //   }

  //   setErrorss(newErrors);
  //   return valid;
  // };

  // const validationforBank = () => {
  //   let valid = true;
  //   const newErrors: Partial<FormData> = {};

  //   // Required fields validation
  //   for (const key in formData) {
  //     if (!formData[key as keyof FormData]) {
  //       newErrors[key as keyof FormData] = "Required";
  //       valid = false;
  //     }
  //   }

  //   // Email format validation
  //   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailPattern.test(formData.email)) {
  //     newErrors.email = "Invalid email format";
  //     valid = false;
  //   }

  //   // Phone number format validation (assuming 10 digits without formatting)
  //   const phonePattern = /^\d{10}$/;
  //   if (!phonePattern.test(formData.contactPersonNumber)) {
  //     newErrors.contactPersonNumber = "Invalid phone number format";
  //     valid = false;
  //   }

  //   // const contactNumberPattern = /^\d{10}$/;
  //   // if (!contactNumberPattern.test(contactNumber)) {
  //   //   contactNumber = "Invalid phone number format";
  //   //   valid = false;
  //   // }

  //   // Pincode format validation (assuming 6 digits without formatting)
  //   const pincodePattern = /^\d{6}$/;
  //   if (!pincodePattern.test(formData.pinCode)) {
  //     newErrors.pinCode = "Invalid pincode format";
  //     valid = false;
  //   }

  //   setErrors(newErrors);
  //   return valid;
  // };
  // console.log("Error>>>>>",handleError.contact_number)

  // const handleNextClick = (e: React.FormEvent) => {
  //   e.preventDefault();
  //     console.log("clicked")
  //   handleSubmit(e);

  // };
  const [selectedOption, setSelectedOption] = useState<string>("");

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
  };

  // Function to open modal
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Function to close modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFile1Change = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setIdNumber1File(file);
    }
  };

  const handleFile2Change = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setIdNumber2File(file);
    }
  };

  const handleFileCheque = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    console.log("file",file);
    if (file) {
      setChequeFile(file);
    }
  };

  const handleClick = async (data: any) => {
    // Check if chequeFile is empty
    if (!chequeFile && !prevChequeFile) {
      setError((error) => ({
        ...error,
        cheque: "* Upload your cheque copy",
      }));
      return; // Stop execution if neither a new file nor a previous file is provided
    }

    // console.log("jiiiiiiiiiiii")

    // Proceed with the API call only if data is provided and chequeFile is not empty
    if (data) {
      try {
        const response = await Store_BankDetails(
          data,
          userToken,
          idNumber,
          idNumber2,
          chequeFile,
          idNumber1File,
          idNumber2File,
          prevChequeFile
        );

        if (response && response.status === 200) {
          toast.success(response["data"]["message"]);
          navigate("/profile");
        } else {
          toast.error(response["data"]["message"]);
        }
      } catch (error: any) {
        console.log(error.response.data, "error==========>");
        // Optionally, set an error state here if needed
        setStoreError(error.response.data.error);
      }
    }
};

console.log(error.cheque,"prev==========>")

  // const handleArea = (e: any) => {
  //   let newValue = e.target.value.trimStart();
  //   setAreaPinCode(newValue);
  //   let alpha = /^(?:[0-9\s,]*\d){1,6}$/;

  //   if (newValue === "") {
  //     setError((error) => ({
  //       ...error,
  //       pin: t("responseMessage.pincodeRequired"),
  //     }));
  //   } else if (!alpha.test(newValue)) {
  //     setError((error) => ({
  //       ...error,
  //       pin: t("responseMessage.validPincode"),
  //     }));
  //   } else {
  //     setError((error) => ({
  //       ...error,
  //       pin: "",
  //     }));
  //   }
  // };


  return (
    <Section
      className="storeRegistration"
      style={{ backgroundColor: "#F0F6FF", paddingTop: 50 }}
    >
      <Container className="mb-5">
        <h2 style={styles.mainTitle}>{t("storeRegister.pageTitle")}</h2>
        <ProgressBar now={(step / 2) * 100} label={`Step ${step} of 2`} />
        <div style={styles.step}>
          <div style={styles.stepWrap}>
            <div
              style={{
                backgroundColor: step === 1 ? "#075CF6" : "#075CF6",
                ...styles.stepBgWrap,
              }}
            >
              <Image src={StepStore} style={styles.stepIcon} />
            </div>
            <h3 style={styles.stepTitle}>{t("storeRegister.stepOne")}</h3>
          </div>
          <div style={styles.stepWrap}>
            <div
              style={{
                backgroundColor: step === 2 ? "#075CF6" : "#AED5FF",
                ...styles.stepBgWrap,
              }}
            >
              <Image src={StepAccount} style={styles.stepIcon} />
            </div>
            <h3 style={styles.stepTitle}>{t("storeRegister.stepTwo")}</h3>
          </div>
        </div>
      </Container>
      <div style={styles.whiteWrap}>
        <Container>
          {step === 2 && (
            <>
              <Form>
                <Form.Group controlId="idNumber">
                  <Form.Label>{t("storeRegister.idNumber")} 1</Form.Label>
                  <Form.Control
                    type="text"
                    // name="idNumber"
                    // {...register("idNumber")}
                    value={idNumber}
                    onChange={(e) => setIdNumber(e.target.value)}
                  />
                  {/* {errors.idNumber && (
                    <span style={styles.error}>{errors.idNumber.message}</span>
                  )} */}
                </Form.Group>

                <Row className="align-items-center xs-row-wrap">
                  {bankData.id_document_1_file && (
                    <Col sm={1}>
                      <a
                        href={`${base_url}${bankData.id_document_1_file}`}
                        className="fileDownloadIconWrap"
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          className="fileDownloadIcon"
                        />{" "}
                        Download
                      </a>
                    </Col>
                  )}
                  <Col sm={bankData.id_document_1_file ? 11 : 12}>
                    <Form.Group controlId="idNumber1File">
                      <Form.Label>
                        {t("storeRegister.idNumber")} 1 File
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept=".pdf,.doc,.docx"
                        onChange={handleFile1Change}
                      />
                    </Form.Group>
                  </Col>
                  {/* {idNumber && (<span style={styles.error}>{"* Please attach the file"}</span>) } */}
                </Row>

                <Form.Group controlId="idNumber2">
                  <Form.Label>{t("storeRegister.idNumberTwo")}</Form.Label>
                  <Form.Control
                    type="text"
                    // name="idNumber2"
                    // {...register("idNumber2")}

                    value={idNumber2}
                    onChange={(e) => setIdNumber2(e.target.value)}
                  />
                  {/* {errors.idNumber2 && (
                    <span style={styles.error}>{errors.idNumber2.message}</span>
                  )} */}
                </Form.Group>
                <Row className="align-items-center xs-row-wrap">
                 
                  <Col sm={bankData.id_document_2_file ? 11 : 12}>
                    <Form.Group controlId="idNumber2File">
                      <Form.Label>
                        {t("storeRegister.idNumberTwo")} File
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept=".pdf,.doc,.docx"
                        onChange={handleFile2Change}
                      />
                    </Form.Group>
                  </Col>
                  {/* {idNumber2 && (<span style={styles.error}>{"* Please attach the file"}</span>) } */}
                </Row>
                <Form.Group controlId="areaPinCode">
                  <Form.Label>{t("storeRegister.pinCodeService")}</Form.Label>
                  <Form.Control
                    type="text"
                    // name="areaPinCode"
                    {...register("areaPinCode")}
                    // value={areaPinCode}
                    // onChange={handleArea}
                  />
                 
                  {errors.areaPinCode && (
                    <span style={styles.error}>
                      {errors.areaPinCode.message}
                    </span>
                  )}{" "}
                </Form.Group>
                <Form.Group controlId="accountHolderName">
                  <Form.Label>
                    {t("storeRegister.accountHolderName")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    // name="accountHolderName"
                    {...register("accountHolderName")}

                    // value={accountHolderName}
                    // onChange={handleChange}
                  />
                  {errors.accountHolderName && (
                    <span style={styles.error}>
                      {errors.accountHolderName.message}
                    </span>
                  )}
                </Form.Group>
                {/* <Form.Group controlId="bank">
                  <Form.Label>{t("storeRegister.selectBank")}</Form.Label>
                  <Form.Control
                    as="select"
                    // name="bank"
                    // value={bank}
                    {...register("bank")}

                  >
                    <option value="">{t("storeRegister.selectBank")}</option>
                    <option value="bank1">Bank 1</option>
                    <option value="bank2">Bank 2</option>
                    <option value="bank3">Bank 3</option>
                  </Form.Control>
                </Form.Group> */}
                <Form.Group controlId="bank">
                  <Form.Label>{"Bank Name"}</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("bank")}
                    // value={accountNumber}
                    // onChange={handleChange}
                  />
                  {errors.bank && (
                    <span style={styles.error}>
                      {errors.bank.message}
                    </span>
                  )}
                </Form.Group>
                <Form.Group controlId="accountNumber">
                  <Form.Label>{t("storeRegister.accountNumber")}</Form.Label>
                  <Form.Control
                    type="text"
                    // name="accountNumber"
                    {...register("accountNumber")}
                    // value={accountNumber}
                    // onChange={handleChange}
                  />
                  {errors.accountNumber && (
                    <span style={styles.error}>
                      {errors.accountNumber.message}
                    </span>
                  )}
                </Form.Group>
               <Row className="align-items-center xs-row-wrap">
               {bankData.cheque_copy && (
                    <Col sm={1}>
                      <a
                        href={`${base_url}${bankData.cheque_copy}`}
                        className="fileDownloadIconWrap"
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          className="fileDownloadIcon"
                        />{" "}
                        Download
                      </a>
                    </Col>
                  )}

               <Col sm={bankData.cheque_copy ? 11 : 12}>
               <Form.Group controlId="ifscCode">
                  <Form.Label>{"Cheque Copy/Scan"}</Form.Label>
                  <Form.Control
                      type="file"
                      accept=".pdf,.doc,.docx"
                      onChange={handleFileCheque}
                    />
                  {error.cheque && (
                    <span style={styles.error}>{error.cheque}</span>
                  )}
                   {storeError.cheque_copy && (
                    <span style={styles.error}>{storeError.cheque_copy}</span>
                  )}
                </Form.Group>
               </Col>
               </Row>

                <Form.Group controlId="branch">
                  <Form.Label>{t("storeRegister.branch")}</Form.Label>
                  <Form.Control
                    type="text"
                    // name="branch"
                    {...register("branch")}
                    // value={branch}
                    // onChange={handleChange}
                  />
                  {errors.branch && (
                    <span style={styles.error}>{errors.branch.message}</span>
                  )}
                </Form.Group>
                <div className="align-items-center justify-content-center d-flex">
                  {/* Step 2 fields */}
                  <Button
                    variant="primary"
                    className="btnBlue submit"
                    onClick={handleSubmit(handleClick)}
                  >
                    {t("commonFormLabels.submit")}
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Container>
      </div>
    </Section>
  );
};

export default BankDetails;
