import React, { ReactNode, CSSProperties } from "react";


interface SectionProps {
    className?: string;
    children: ReactNode;
    style?: CSSProperties; 
}

const Section: React.FC<SectionProps> = (props) => {
    const classes = "wrapper_" + props.className;
    return <section className={classes} style={props.style}>{props.children}</section>;
}

export default Section;
