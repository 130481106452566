import { CSSProperties } from "react";

export interface Styles {
  label: CSSProperties;
  whiteWrap: CSSProperties;
  error: CSSProperties;
  image:CSSProperties;
  imageWrap:CSSProperties;
  btnBlue:CSSProperties;
  btnRed:CSSProperties;
  btnCancel:CSSProperties;
  heading:CSSProperties;
}

const baseStyles: Styles = {
  
  label: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 28,
    color: "var(--text-color)",
    lineHeight: 1.2,
    display: "block",
  },
  
  error: {
    color: "#EA4335",
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
  },
  whiteWrap: {
    paddingLeft: 50,
    paddingRight: 50,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingTop: 100,
    paddingBottom: 60,
  },
  btnBlue: {
     fontFamily:"var(--primary-font-semibold)",
    fontSize:20,
    color:"var(--white-color)",
    paddingTop:10,
    paddingBottom:10,
    marginTop:60,
    width:"31%",
    marginLeft:"3%",
    marginRight:"3%"
  },
  btnRed:{
    fontFamily:"var(--primary-font-semibold)",
    fontSize:20,
    color:"var(--text-color)",
    paddingTop:10,
    paddingBottom:10,
    marginTop:60,
    borderColor:"#EA4335",
    backgroundColor:"transparent",
    width:"31%",

  },
  
  heading:{
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "var(--text-color)",
  },
  btnCancel :{
     fontFamily:"var(--primary-font-semibold)",
    fontSize:20,
    color:"var(--text-color)",
    paddingTop:10,
    paddingBottom:10,
    marginTop:60,
    borderColor:"var(--text-color)",
    backgroundColor:"transparent",
    width:"31%",

  },
  imageWrap: { width: 80, height: 80 },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    borderRadius: 15,
  },
  
};

const mobileStyles: Partial<Styles> = {
  // labelText: {
  //   fontFamily: "var(--primary-font-semibold)",
  //   fontSize: 12,
  //   color: "var(--text-color)",
  //   borderTopWidth:1,
  //   borderTopColor:"#F5F5F5",
  //   borderTopStyle:"solid",
  //   marginTop:20,
  //   paddingTop:20,
  //   marginBottom:20
  // },
  imageWrap: { width: 60, height: 60 },

  btnBlue: {
    fontFamily:"var(--primary-font-semibold)",
   fontSize:14,
   color:"var(--white-color)",
   paddingTop:10,
   paddingBottom:10,
   marginTop:20,
   width:"31%",
   marginLeft:"3%",
   marginRight:"3%"
 },
 btnRed:{
   fontFamily:"var(--primary-font-semibold)",
   fontSize:14,
   color:"var(--text-color)",
   paddingTop:10,
   paddingBottom:10,
   marginTop:20,
   borderColor:"#EA4335",
   backgroundColor:"transparent",
   width:"31%",

 },
 btnCancel :{
    fontFamily:"var(--primary-font-semibold)",
   fontSize:14,
   color:"var(--text-color)",
   paddingTop:10,
   paddingBottom:10,
   marginTop:20,
   borderColor:"var(--text-color)",
   backgroundColor:"transparent",
   width:"31%",
 },
  whiteWrap: {
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingTop: 10,
    paddingBottom: 10,
  },
  
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};
