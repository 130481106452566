import { useSelector } from "react-redux";
import { getOutOfStockProductList } from "../Api";
import { RootState } from "../Redux/Store/Store";
import { PRODUCT_OUTOFSTOCK } from "../Redux/offer";
import store from "../Redux/Store/Store";

export const getOutOfStockProduct = async () => {
  try {
    const reduxStoreData = store.getState();
    const userLatitude = reduxStoreData.Location.selected_latitude;

    const userLongitude = reduxStoreData.Location.selected_longitude;

    const payload = {
      "lat": userLatitude,
      "long": userLongitude,
    };
    const res: any = await getOutOfStockProductList(payload);
    if (res?.data?.status) {
      if (res?.data?.product_list?.length > 0) {
        const extractedIds = res?.data?.product_list.map((item: any) => item.id);
        // console.log(extractedIds);
        store.dispatch(PRODUCT_OUTOFSTOCK(extractedIds));
      } else {
        store.dispatch(PRODUCT_OUTOFSTOCK([]));
      }
    }
    return res?.product_list;
  } catch (error) {
    console.log("error getOutOfStockProduct -->", error);
    store.dispatch(PRODUCT_OUTOFSTOCK([]));
    return error;
  }
};
