import React, { useEffect } from 'react';
import styles from './Footer.style';
import Section from '../Section/Section';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import "react-multi-carousel/lib/styles.css";
import LogoWhite from '../../assets/images/logo-white.png';
import { Link } from 'react-router-dom';
import { RootState } from '../../Redux/Store/Store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { STORAGE_CONSTANTS } from '../../shared/Constants/Constants';
const Footer: React.FC = () => {
    const { t, i18n } = useTranslation();

 const role = useSelector((state: RootState) => state.User.ActiveRole)

 useEffect(() =>{
    const savedLanguage = localStorage.getItem(
        STORAGE_CONSTANTS.CURRENT_SELECTED_LANGUAGE
      );
      if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
      }
 },[])

    return (

        <Section className="footer" >
            <div style={{ backgroundColor: 'var(--primary-color)', paddingTop: 50, paddingBottom: 50 }}>
                <Container>
                    <Row>
                        <Col sm={3} className="f-col1">
                            <Image src={LogoWhite} style={{ width: 122, height: 46 }} />
                            <p style={styles.para}>Lorem ipsum dolor sit amet,
                                consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut</p>

                            {/* <div className="d-grid gap-2">  */}
                            <Button style={styles.btnFooter} >{t("footer.askQuestion")}</Button>
                            {/* </div> */}
                        </Col>
                        <Col sm={3} className="f-col2">
                            <h3 style={styles.footerHeading}>{t("footer.quickLinks")}</h3>
                            <ul style={{ paddingLeft: 0 }}>
                                <li style={styles.navItem}>
                                    <Link to="about-us">{t("footer.aboutUs")}</Link>
                                </li>
                                <li style={styles.navItem}>
                                    <Link to="faqs">FAQ's</Link>
                                </li>
                                <li style={styles.navItem}>
                                    <Link to="terms-conditions">{t("footer.terms")}</Link>
                                </li>
                                <li style={styles.navItem}>
                                    <Link to="privacy-policy">{t("footer.privacy")}</Link>
                                </li>
                                <li style={styles.navItem}>
                                    <Link to="contact-us">{t("footer.contactUs")}</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col sm={3} className="f-col3">
                            <h3 style={styles.footerHeading}>{t("footer.navigation")}</h3>
                            <ul style={{ paddingLeft: 0 }}>
                                <li style={styles.navItem}>
                                   { role !== "store" ? <Link to="/">{t("footer.home")}</Link> : <Link to="/store-dashboard">{t("footer.home")}</Link>}
                                </li>
                                <li style={styles.navItem}>{t("footer.product")}</li>
                                <li style={styles.navItem}>{t("footer.blog")}</li>
                            </ul>
                        </Col>
                        <Col sm={3} className="f-col4">
                            <h3 style={styles.footerHeading}>{t("footer.contact")}</h3>
                            <p style={styles.para}>Lorem ipsum dolor sit amet,
                                consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut</p>
                            <p style={styles.para}>+91 000 000 0000   &nbsp;lorem@ipsumdolorsit</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Section>

    );
};

export default Footer;
