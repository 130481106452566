import { createSlice } from '@reduxjs/toolkit';

const initialState:any = {
   ActiveRole:'user',
   ActiveTab :"",
   productIdInWishList: [],
   UserAddress:[],
   selectedSubCategory: {}
}

const User = createSlice({
  name: 'User',
  initialState,
  reducers: {
    ACTIVE_USER:(state, action)=>{
      state.ActiveRole = action.payload;
    },
    SET_PRODUCT_ID_IN_WISHLIST:(state, action)=>{
      state.productIdInWishList = action.payload;
    },
    SET_USER_ADDRESS:(state, action)=>{
      state.UserAddress = action.payload;
    },
    CLEAR_USER_ADDRESS:(state)=>{
      state.UserAddress = "";
    },
    INITIAL_TAB:(state,action)=>{
      state.ActiveTab = action.payload;
    },
    CLEAR_ACTIVE_ROLE:(state)=>{
      state.ActiveRole = "user";
    },
    CLEAR_USER_DATA:(state)=>{
      state.productIdInWishList = "";
    },
    SET_SELECTED_STORE_SUBCATEGORY:(state, action)=>{
      state.selectedSubCategory = action.payload;
    },
  },
});

export const { ACTIVE_USER, SET_PRODUCT_ID_IN_WISHLIST, CLEAR_USER_DATA,SET_USER_ADDRESS,CLEAR_USER_ADDRESS,CLEAR_ACTIVE_ROLE,INITIAL_TAB, SET_SELECTED_STORE_SUBCATEGORY} = User.actions;

export default User.reducer;