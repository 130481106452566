import React , {useState,useEffect, ReactNode} from 'react';
import Section from '../Section/Section';
import { Col, Container, Row } from 'react-bootstrap';
import TitleBg from '../../assets/images/title-bg.png';
import { FaArrowRight } from "react-icons/fa6";
import { getStyles } from './TitleBar.style';
interface TitleBarProps {
    breadcrumbs: ReactNode[];
    title: string;
    info?: string;
    imageUrl?: string;
}

const TitleBar: React.FC<TitleBarProps> = ({ breadcrumbs, title, info, imageUrl }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth <= 767);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const styles = getStyles(isMobile);
    return (
        <Section className="titlebar" style={styles.bg}>
            <Container>
                <Row style={{position:"relative"}}>
                    <Col sm={8}>
                        <div className="breadcrumbs">
                            {breadcrumbs.map((crumb, index) => (
                                <React.Fragment key={index}>
                                    <span style={{ color: index === 0 ? "var(--primary-color)" : "#BEB8B8", fontFamily: "var(--primary-font-medium)", fontSize: 16 }}>{crumb}</span>
                                    {index !== breadcrumbs.length - 1 && <FaArrowRight  className='mx-2' style={{ color: "#BEB8B8" }} />}
                                </React.Fragment>
                            ))}
                        </div>
                        <div className="title" style={styles.titleInfoWrap}>
                            <h1 style={styles.title}>{title}</h1>
                            <p style={styles.info}>{info}</p>
                        </div>
                    </Col>
                    <Col sm={4} className='hide-xs'>
                        <div className="image">
                            <img src={imageUrl} alt="Title Image" style={styles.image}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default TitleBar;
