import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../Redux/Store/Store";

const Payment: React.FC = () => {
  
  const payment = useSelector((state:RootState) => state.Payment.paymentList)

  console.log(payment,"payment------->")

  return (
    <>
      <h1>Payment</h1>
      <div>
        
        <iframe 
        src={payment}
        // sandbox='allow-popups;allow-same-origin;allow-scripts;allow-forms' 
        // // @ts-ignore
        // allowpaymentrequest='true'
        // allow="accelerometer;autoplay;clipboard-write; encrypted-media; gyroscope; picture-in-picture"
       width='100%' height='100%'
        ></iframe>
        {/* <embed type="text/html"  src="https://admin.easylife.progfeel.co.in/payments/ini%E2%80%A6/esewa/?uuid=MjAyNC0wNS0xN1QxMTozODoyNC45NTU4NDk=" width="300" height="200"></embed> */}
      </div>
    </>
  );
};

export default Payment;
