// Heading.tsx

import React from 'react';
import styles from './Heading.style';

interface HeadingProps {
  text: string;
  style?: React.CSSProperties;
  alignment?: 'left' | 'center' | 'right'; // Define alignment prop

}

const Heading: React.FC<HeadingProps> = ({ text,alignment='left' }) => {
    const alignmentClass = alignment === 'left' ? styles.leftAlign : alignment === 'center' ? styles.centerAlign : styles.rightAlign;

  return (
    <h1 style={{ ...styles.heading, ...alignmentClass }} className='titlebar-title'>
      {text}
    </h1>
  );
};

export default Heading;
