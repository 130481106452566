import { CSSProperties } from "react";
import TitleBg from "../../assets/images/title-bg.png";

export interface Styles {
  bg: CSSProperties;
  whiteWrap: CSSProperties;
  orderTableWrap: CSSProperties;
  orderTable: CSSProperties;
  orderInfo: CSSProperties;
  orderInfoo: CSSProperties;
  orderImageWrap: CSSProperties;
  orderImage: CSSProperties;
  noProduct: CSSProperties;
  viewOrderInfo: CSSProperties;
  info: CSSProperties;
  orderId: CSSProperties;
  dot: CSSProperties;
  th:CSSProperties;
  value400: CSSProperties;
  value500: CSSProperties;
  value600: CSSProperties;
  label: CSSProperties;
  value:CSSProperties;
  cartItem:CSSProperties;
  shopWrap:CSSProperties;
  cartTitle:CSSProperties;
}

const baseStyles: Styles = {
  bg: {
    backgroundImage: `url(${TitleBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: 40,
    paddingBottom: 100,
  },
  shopWrap: {
    marginBottom: 10,
background:"#f5f5f5",
    padding:10,
  },
  cartTitle: {
    fontSize: 14,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
    marginBottom: 16,
  },
  noProduct: {
    fontSize: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "var(--primary-font-semibold)",
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    padding: 20,
    borderRadius: 16,
  },
  whiteWrap: {
    paddingLeft: 50,
    paddingRight: 50,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingTop: 60,
    paddingBottom: 60,
  },
  cartItem : {
    paddingTop: 20,

    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#D9D9D9",
  },

  orderTableWrap: {
    paddingTop: 30,
    paddingBottom: 0,
  },
  orderTable: {},
  orderInfoo:{
    fontSize: 14,
    fontFamily: "var(--primary-font-regular)",
    color: "var(--text-color)",
    marginBottom:0
  },
  orderInfo: {
    fontSize: 18,
    fontFamily: "var(--primary-font-regular)",
    color: "var(--text-color)",
  },
  orderImageWrap: {
    width: 100,
    height: 100,
    overflow: "hidden",
    boxShadow: "0px 0px 2px 0px #00000040",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 22,
    borderColor: "#f5f5f5",
    padding: 10,
  },
  orderImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },

  viewOrderInfo: {
    fontSize: 20,
    marginBottom: 5,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
  },
  th:{
    fontSize: 18,
    fontFamily: "var(--primary-font-regular)",
    color: "var(--text-color)",
  },
  info: {
    fontSize: 20,
    fontFamily: "var(--primary-font-regular)",
    color: "var(--text-color)",
  },
  orderId: {
    marginTop: 30,
    fontSize: 24,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
  dot: {
    width:25,
    height:25,
    backgroundColor:"#D9D9D9",
    borderRadius:100
  },
  value400:{
    fontSize: 18,
    fontFamily: "var(--primary-font-regular)",
    color: "var(--text-color)",
  },
  value500:{
    fontSize: 18,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
  },
  value600:{
    fontSize: 18,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
  label:{
    fontSize: 18,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
  },
  value:{
    fontSize: 18,
    fontFamily: "var(--primary-font-regular)",
    color: "var(--text-color)",
    backgroundColor:"#F5F5F5",
    width:"100%",
    borderRadius:6,
    textAlign:"center",
    padding:10
  },

};

const mobileStyles: Partial<Styles> = {
  viewOrderInfo: {
    fontSize: 20,
    marginBottom: 5,
    textAlign:"right",
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
  },
  
  bg: {
    backgroundImage: `url(${TitleBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: 40,
    paddingBottom: 40,
  },
  orderImageWrap: {
    width: 50,
    height: 50,
    overflow: "hidden",
    boxShadow: "0px 0px 2px 0px #00000040",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 12,
    borderColor: "#f5f5f5",
    padding: 5,
    marginRight:20
  },

  whiteWrap: {
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingTop: 40,
    paddingBottom: 20,
  },
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};
