// Login.style.ts

import { CSSProperties } from "react";
import { Right } from "react-bootstrap/lib/Media";

export interface Styles {
    singleCategoryWrap:CSSProperties;
    categoryName:CSSProperties;
    categoryImgWrap: CSSProperties
    categoryImage:CSSProperties;
    btnSort:CSSProperties
}

const styles: Styles = {
    singleCategoryWrap:{
        backgroundColor : 'var(--primary-color)',
        borderRadius:15,
        padding:20,
        marginRight:15,
        marginLeft:15,
        paddingTop:20,
        minHeight:271,
        paddingBottom:20
    },
    categoryName:{
        fontFamily:'var(--primary-font-semibold)',
        color:'var(--white-color)',
        fontSize:25,
        marginBottom:20,
    },
    categoryImage:{
        width:181,
        height:155,
        textAlign:"right"
    },
    categoryImgWrap :{
        textAlign:"right"
    },
    btnSort: {
        borderWidth: 0,
        backgroundColor: "var(--white-color)",
        float:"right"
      },
};

export default styles;
