import { CSSProperties } from "react";
import TitleBg from "../../assets/images/title-bg.png";

export interface Styles {
  bg: CSSProperties;
  searchRow: CSSProperties;
  whiteWrap: CSSProperties;
  shopImageWrap: CSSProperties;
  shopImage: CSSProperties;
  shopNameWrap: CSSProperties;
  shopName: CSSProperties;
  head: CSSProperties;
  btnSort: CSSProperties;
  addBtn: CSSProperties;
  productTableWrap:CSSProperties;
  productTable:CSSProperties;
  productInfo:CSSProperties;
  productImageWrap:CSSProperties;
  productImage:CSSProperties;
  productRadio:CSSProperties;
  noProduct:CSSProperties;
  btnSortIcon :CSSProperties;
}

const baseStyles: Styles = {
  bg: {
    backgroundImage: `url(${TitleBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: 40,
    paddingBottom: 100,
  },
  noProduct:{
    fontSize: 18,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    fontFamily: "var(--primary-font-semibold)",
    backgroundColor: "var(--primary-color)",
    color:"#fff",
    padding:20,
    borderRadius:16
  },
  searchRow: {
    marginTop: 50,
    maxWidth: 1142,
    marginLeft: "auto",
    marginRight: "auto",
  },
  whiteWrap: {
    paddingLeft: 50,
    paddingRight: 50,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingTop: 60,
    paddingBottom: 60,
  },
  shopImageWrap: {
    width: 60,
    height: 60,
    overflow: "hidden",
  },
  shopImage: {
    width: "100%",
    objectFit: "contain",
    height: "100%",
  },
  shopNameWrap: {
    marginLeft: 20,
  },
  shopName: {
    fontSize: 32,
    marginBottom: 0,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
  head: {
    fontSize: 28,
    marginBottom: 0,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
  },
  btnSort: {
    borderWidth: 0,
    backgroundColor: "var(--white-color)",
  },
  addBtn:{
    fontSize: 20,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--white-color)",
    backgroundColor:"var(--primary-color)",
    // minWidth:400,
    marginTop:20,
    paddingInline:20,
    textAlign:"center"
  },
  productTableWrap:{
    paddingTop:60,
    paddingBottom:60
  },
  productTable:{},
  productInfo:{
    fontSize: 18,
    fontFamily: "var(--primary-font-regular)",
    color: "var(--text-color)",
    textTransform :"capitalize"
  },
  productImageWrap:{
    width:100,
    height:100,
    overflow:"hidden",
    boxShadow: "0px 0px 2px 0px #00000040",
    borderWidth:1,
    borderStyle:"solid",
    borderRadius:22,
    borderColor:"#f5f5f5",
    padding:10
  },
  productImage:{
    width:"100%",
    height:"100%",
    objectFit:"contain"
  },
  productRadio:{},
  btnSortIcon:{}
};

const mobileStyles: Partial<Styles> = {
  productInfo:{
    fontSize: 11,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
  },
  productImageWrap:{
    width:40,
    height:40,
    overflow:"hidden",
    boxShadow: "0px 0px 2px 0px #00000040",
    borderWidth:1,
    borderStyle:"solid",
    borderRadius:10,
    borderColor:"#f5f5f5",
    padding:5
  },
  btnSort: {
    borderWidth: 0,
    backgroundColor: "var(--white-color)",
    padding:0
  },
  whiteWrap: {
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingTop: 10,
    paddingBottom: 10,
  },
  btnSortIcon: {
    width:40
  },
  addBtn:{
    fontSize: 14,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--white-color)",
    backgroundColor:"var(--primary-color)",
    minWidth:130,
    marginTop:0,
    textAlign:"center"
  },
  bg: {
    backgroundImage: `url(${TitleBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: 20,
    paddingBottom: 50,
  },
  shopName: {
    fontSize: 20,
    marginBottom: 0,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
  shopImageWrap: {
    width: 30,
    height: 30,
    overflow: "hidden",
  },
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};