
// ALL Local storages constants 
//TODO: value naming conventions needs to be changed
export const STORAGE_CONSTANTS = {
    CURRENT_SELECTED_LANGUAGE: "language_code", 
    USER_TOKEN: "user_token",
    ORDER_ID: "order_id",
    SHOP_ID: "shop_id",
    IS_LOGGED_IN: "isLoggedIn",
    LATITUDE: "latitude",
    LONGITUDE: "longitude",
    USER_ID: "user_id",
    USER_OTP: "user_otp",
    USER: "user",
    NUMBER: "number",
    USER_NUMBER: "user_number",
    CONTACT_NUMBER: "contact_number",
    STORE_ID: "storeId",
    STORE_NAME: "store_name",
    STORE_STATUS: "store_status",
    SEARCHED_TEXT: "search",
    CATEGORY_NAME: "cateName",
    UUID: "uuid",
    PRODUCT_ID: "product_Id",
    STORE_LOGO: "storeLogo",
    CHECKOUT_STATUS: "checkout",
    AREA_PIN: "area_pin_code",
    COUPON_CODE: "coupon_code",
    PickupOption: "pickup_code"
};



//Payment Method constants
export const PAYMENT_METHODS_CONSTANTS = {
    ESEWA: "eSewa",
    COD: "cod"
}
