import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Player } from "@lottiefiles/react-lottie-player";
import lottieNearByShop from "../../assets/lotties/verified.json";


const ThankYou: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container className="jumbotron-md text-center shadow-lg thankYouWrapper">
            <Row className="justify-content-md-center">
                <Col md="8">
                    <Player
                        src={lottieNearByShop}
                        loop
                        autoplay
                        className="player"
                        style={{ height: "100px", width: "100px" }}
                    />
                    <h1 className="mb-4">Thank You!</h1>
                    <p className="lead">Your account has been successfully verified.</p>
                    <Button
                        variant="primary"
                        size="lg"
                        className="btnBlue"
                        onClick={() => navigate('/')}
                    >
                        Go to Home
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default ThankYou;
