import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button, Image, Spinner } from 'react-bootstrap';
import styles from './AboutUs.style';
import TitleBar from '../../Components/TitleBar/TitleBar';
import TitleImage from '../../assets/images/about-us.png';
import Section from '../../Components/Section/Section';
import Innvoative from "../../assets/images/innovative.png";
import locationIcon from "../../assets/images/location.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import career from "../../assets/images/career.png";
import Dot from "../../assets/images/dot.png";
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
import Heading from '../../Components/Heading/Heading';
const AboutUs: React.FC = () => {
  const breadcrumbs: string[] = ['Home', 'About Us'];
  const title: string = 'About Us';
  const info: string = "Lorem ipsum dolor sit amet, consectetur adipiscing.";
  const imageUrl: string = TitleImage; // Corrected imageUrl

  

  const navigate = useNavigate()

  useEffect(() => {
    
    window.scrollTo(0, 0);
  }, []);

 


  
  return (
    <div className="about-us">
    
      <TitleBar breadcrumbs={breadcrumbs} title={title} info={info} imageUrl={imageUrl} />
      <Section className="aboutUs" style={{ paddingTop: 100, paddingBottom: 100 }}>
        <Container>
         <Row>
          <Col>
          <Heading text="About The Company" alignment="left" />
          <p style={styles.text}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          </Col>
         </Row>
         <Row className="mt-5">
          <Col>
            <Heading text="Innovative Leadership" alignment='left'/>
            <Row className="mt-4">
              <Col sm="3" className="text-center">
                <div style={styles.imgWrap} className="mb-2">
                  <Image src={Innvoative} style={styles.img} />
                </div>
                <div style={styles.contentWrap}>
                  <h3 style={styles.title} className='mb-0'>
                  Lorem ipsum
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum
                  </p>
                </div>
              </Col>
              <Col sm="3" className="text-center">
                <div style={styles.imgWrap} className="mb-2">
                  <Image src={Innvoative} style={styles.img} />
                </div>
                <div style={styles.contentWrap}>
                  <h3 style={styles.title} className='mb-0'>
                  Lorem ipsum
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum
                  </p>
                </div>
              </Col>
              <Col sm="3" className="text-center">
                <div style={styles.imgWrap} className="mb-2">
                  <Image src={Innvoative} style={styles.img} />
                </div>
                <div style={styles.contentWrap}>
                  <h3 style={styles.title} className='mb-0'>
                  Lorem ipsum
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum
                  </p>
                </div>
              </Col>
              <Col sm="3" className="text-center">
                <div style={styles.imgWrap} className="mb-2">
                  <Image src={Innvoative} style={styles.img} />
                </div>
                <div style={styles.contentWrap}>
                  <h3 style={styles.title} className='mb-0'>
                  Lorem ipsum
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          </Row>
          <Row className="mt-5">
          <Col>
            <Heading text="Career Opportunities" alignment='left'/>
            <Row className="mt-4">
              <Col sm="4">
                <div style={styles.careerImgWrap} className="mb-4">
                  <Image src={career} style={styles.img} />
                </div>
                <div style={styles.contentWrap} className='px-3 mt-2'>
                  <h6 style={styles.subTitle} className="mb-2">Lorem ipsum</h6>
                  <h3 style={styles.title} className='mb-2'>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>
              </Col>
              
              <Col sm="4">
                <div style={styles.careerImgWrap} className="mb-4">
                  <Image src={career} style={styles.img} />
                </div>
                <div style={styles.contentWrap} className='px-3 mt-2'>
                  <h6 style={styles.subTitle} className="mb-2">Lorem ipsum</h6>
                  <h3 style={styles.title} className='mb-2'>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>
              </Col>
              <Col sm="4">
                <div style={styles.careerImgWrap} className="mb-4">
                  <Image src={career} style={styles.img} />
                </div>
                <div style={styles.contentWrap} className='px-3 mt-2'>
                  <h6 style={styles.subTitle} className="mb-2">Lorem ipsum</h6>
                  <h3 style={styles.title} className='mb-2'>
                  Lorem ipsum dolor sit amet consectetur adipiscing elit
                  </h3>
                  <p style={styles.info}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                </div>
              </Col>
             
            </Row>
          </Col>
          </Row>
        </Container>
      </Section>
    </div>
  );
};

export default AboutUs;
