import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    // mainCategory : [],
    global_search : [],
    
};

export const GlobalSearch = createSlice({
    name: "GlobalSearch",
    initialState,
    reducers: {
        SET_SELECTED_SEARCH: (state, action) => {
            state.global_search = action.payload;
        },
        // CLEAR_LONGITUDE:(state) =>{
        //     state.selected_longitude = ""
        // }
    },
});


export const {  SET_SELECTED_SEARCH } = GlobalSearch.actions;
export default GlobalSearch.reducer;