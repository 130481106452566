import { CSSProperties } from "react";
import { Right } from "react-bootstrap/lib/Media";

export interface Styles {
  text: CSSProperties;
  imgWrap:CSSProperties;
  img:CSSProperties;
  contentWrap:CSSProperties;
  title:CSSProperties;
  info:CSSProperties;
  careerImgWrap:CSSProperties;
  subTitle:CSSProperties;
}


const styles: Styles = {
 
  text: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    fontSize: 16,
  },
  imgWrap:{
    width:48,
    height:48,
    overflow:"hidden",
    marginLeft:"auto",
    marginRight:"auto"
  },
  img:{
    height:"100%",
    width:"100%",
    objectFit:"contain"
  },
  contentWrap:{},
  title:{
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--heading-color)",
    fontSize: 20,
  },
  info:{
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    fontSize: 16,
    marginBottom:0
  },
  careerImgWrap:{
    width:350,
    height:244,
    overflow:"hidden",
    marginLeft:"auto",
    marginRight:"auto"
  },
  subTitle:{
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    fontSize: 12, 
  }
 };

export default styles;
