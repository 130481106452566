import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  SearchText: [],
  searchedType: [],
  SearchedStore: [],
  StoreViewSearch: ""
};

const Search = createSlice({
  name: "Search",
  initialState,
  reducers: {
    Search_Text: (state, action) => {
      state.SearchText = action.payload;
    },
    Searched_Type: (state, action) => {
      state.searchedType = action.payload;
    },
    Searched_Store: (state, action) => {
      state.SearchedStore = action.payload;
    },
    CLEAR_TYPE: (state) => {
      state.searchedType = [];
    },
    CLEAR_SEARCH_PRODUCT: (state) => {
      state.SearchText = [];
    },
    CLEAR_SEARCH_STORE: (state) => {
      state.SearchedStore = [];
    },
    SET_STORE_VIEW_SEARCH: (state, action) => {
      state.StoreViewSearch = action.payload;
    },
  },
});

export const { Search_Text, CLEAR_TYPE, Searched_Type, Searched_Store,CLEAR_SEARCH_PRODUCT,CLEAR_SEARCH_STORE, SET_STORE_VIEW_SEARCH } =
  Search.actions;

export default Search.reducer;
