// Header.tsx

import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Image,
  Form,
  FormControl,
  Button,
  Modal,
} from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { FaSearch, FaShoppingCart, FaUser } from "react-icons/fa";
import GPS from "../../assets/images/gps.png";
import Globe from "../../assets/images/global.png";
import Logo from "../../assets/images/logo.png";
import Cart from "../../assets/images/CartBlack.png";
import Heart from "../../assets/images/HeartBlack.png";
import User from "../../assets/images/User.png";
import Language from "../Language/Language";
import styles from "./Header.style";
import arrowDown from "../../assets/images/arrowDown.png";
import {
  Get_User_Details,
  Languages,
  Search_Products,
  Search_Stores,
  View_Cart,
} from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { languageId } from "../../Redux/languageSlice";
import { RootState } from "../../Redux/Store/Store";
import { setLoginStatus } from "../../Redux/loginSlice";
import { ACTIVE_USER, CLEAR_USER_ADDRESS, INITIAL_TAB } from "../../Redux/User";
import {
  CLEAR_SEARCH_PRODUCT,
  CLEAR_SEARCH_STORE,
  CLEAR_TYPE,
  Search_Text,
  Searched_Store,
} from "../../Redux/Search";
import { toast } from "react-toastify";
import { VIEW_CART } from "../../Redux/Cart";
import { base_url } from "../../Api/ApiConst";
import productId from "../../Redux/productId";
import {
  SET_SELECTED_LATITUDE,
  SET_SELECTED_LONGITUDE,
} from "../../Redux/Location";
import { useTranslation } from "react-i18next";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { SET_SELECTED_SEARCH } from "../../Redux/GlobalSearch";
import { Location_tracker } from "../Location/location";
import LocationComponent from "../GoogleMaps/LocationComponent";
// import Location_tracker from "../Location/location";
import { isMobile } from 'react-device-detect';

interface Category {
  id: string;
  language_code: string;
  language_name: string;
}

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState<Category[]>([]);
  const locations = ["Location 1", "Location 2", "Location 3"];
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [loading, setLoading] = useState<any>(false);
  const [userToken, setUserToken] = useState<string | null>("");
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [latitude, setLatitude] = React.useState<string | null>("");
  const [longitude, setLongitude] = React.useState<string | null>("");
  const [searchText, setSearchText] = useState("");

  const [cart, setCart] = useState<any>("");
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const cartlength = useSelector((state: RootState) => state.Cart.View_Cart);
  const [userId, setUserId] = useState<string | null>("");
  const [details, setDetails] = useState<any>({});
  const searchType: any = useSelector(
    (state: RootState) => state.Search.searchedType
  );
  const userPincode = useSelector((state: RootState) => state.Location.pincode);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(languageId(selectedLanguage));
  const role = useSelector((state: RootState) => state.User.ActiveRole);
  const userImage = useSelector(
    (state: RootState) => state.productId.user_image
  );
  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );

  const globalText = useSelector(
    (state: RootState) => state.GlobalSearch.global_search
  );

  useEffect(() => {
    const isAuthenticated =
      localStorage.getItem(STORAGE_CONSTANTS.IS_LOGGED_IN) === "true";
    dispatch(setLoginStatus(isAuthenticated));
  }, [dispatch]);

  // const fetchLanguage = async () => {
  //   try {
  //     const response = await Languages();
  //     if (response && response.status === 200) {
  //       console.log(response.data.language_list, "language----------->");
  //       setLanguage(response.data.language_list);
  //     } else {
  //       console.log("Error fetching Languages");
  //     }
  //   } catch (error) {
  //     console.log("Error fetching Languages", error);
  //   }
  // };

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    setLatitude(localStorage.getItem(STORAGE_CONSTANTS.LATITUDE));
    setLongitude(localStorage.getItem(STORAGE_CONSTANTS.LONGITUDE));
    setUserId(localStorage.getItem(STORAGE_CONSTANTS.USER_ID));
    // setLanCode(
    //   localStorage.getItem(STORAGE_CONSTANTS.CURRENT_SELECTED_LANGUAGE)
    // );
    const savedLanguage = localStorage.getItem(
      STORAGE_CONSTANTS.CURRENT_SELECTED_LANGUAGE
    );
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    }
    // fetchLanguage();

    if(userLatitude === "" || userLongitude === ""){
      setShowLocationModal(true)
    }
  }, []);

  useEffect(() => {
    if (userToken && userId) {
      const fetchUser = async () => {
        try {
          const response = await Get_User_Details();
          if (response && response.status === 200) {
            // console.log(response.data.store_list.language_data[0].store_name);
            console.log(response.data, "details----------");
            setDetails(response.data.user_details);
          } else {
            console.log("Error fetching User Details");
          }
        } catch (error) {
          console.log("Error fetching User Details", error);
        }
      };
      fetchUser();
    }
  }, [userToken, userId, userImage]);

  const handleLocationChange = (location: string) => {
    console.log("Selected location:", location);
  };

  const handleLanguageChange = (language_code: string) => {
    i18n.changeLanguage(language_code);
    console.log("languageCode-------------->", language_code);
    setSelectedLanguage(language_code);
    localStorage.setItem(
      STORAGE_CONSTANTS.CURRENT_SELECTED_LANGUAGE,
      language_code
    );
    setShowLanguageModal(false); // Close the language modal after language selection
  };

  const handleSearch = () => {
    if (searchType === "stores") {
      const SearchStore = async () => {
        console.log(searchType, "=============>");
        console.log("stores-------->");

        if (globalText === "") {
          navigate("/");
        }

        try {
          // localStorage.setItem(STORAGE_CONSTANTS.SEARCHED_TEXT, searchText);
          dispatch(SET_SELECTED_SEARCH(searchText));
          navigate("/search-list");
          const response = await Search_Stores(
            searchText,
            userLatitude,
            userLongitude,
            userPincode
          );
          // console.log(searchText,"search-------->")
          if (response && response.status === 200) {
            console.log(response.data, "stores-------->");
            dispatch(Searched_Store(response.data));
            // navigate("/search-list");
          } else {
            console.log("Error fetching Products");
          }
        } catch (error: any) {
          navigate("/search-list");
          console.log("Error fetching Products", error);
        }
      };
      SearchStore();
    } else {
      const SearchText = async () => {
        console.log(searchType, "=============>");
        console.log("products-------->");
        if (globalText === "") {
          navigate("/");
        }

        try {
          navigate("/search-list");
          dispatch(SET_SELECTED_SEARCH(searchText));
          const response = await Search_Products(
            searchText,
            userLatitude,
            userLongitude,
            userPincode
          );
          // console.log(searchText,"search-------->")
          if (response && response.status === 200) {
            // console.log(response.data,"yuuuuuuuuuuuuuuuuuy")
            dispatch(Search_Text(response.data));
            // navigate("/search-list");
          } else {
            console.log("Error fetching Products");
          }
        } catch (error: any) {
          // toast.error(error.response.data.message);
          navigate("/search-list");
          console.log("Error fetching Products", error);
        }
      };
      SearchText();
    }
    // dispatch(CLEAR_USER_ADDRESS());
  };

  // const handleSearch = (e:any) => {
  //   e.preventDefault()
  //   if(globalText === ""){
  //      navigate("/")
  //   }
  //     dispatch(SET_SELECTED_SEARCH(searchText))
  //     navigate("/search-list");
  // };

  // const handleLocation = () => {
  //   setShowLocationModal(false);
  //   Location_tracker()
  // };

  const handleLogo = () => {
    dispatch(CLEAR_USER_ADDRESS());
    dispatch(CLEAR_TYPE());
    dispatch(CLEAR_SEARCH_PRODUCT());
    dispatch(CLEAR_SEARCH_STORE());
    localStorage.removeItem("uuid");
  };

  const ClickMe = () => {
    navigate("/profile");
    dispatch(INITIAL_TAB("Wishlist"));
    // dispatch(CLEAR_USER_ADDRESS());
  };

  const handleUser = () => {
    if (role === "user") {
      dispatch(INITIAL_TAB("Profile"));
    } else {
      dispatch(INITIAL_TAB("StoreProfile"));
    }
    // dispatch(CLEAR_USER_ADDRESS());
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };
  

  return (
    <>

{isMobile ? (
        <Navbar expand="lg" className="custom-navbar mobile  tablet">
        <Container>
          <Navbar.Brand
            href={role === "user" ? "/" : "/store-dashboard"}
            className="mr-0"
          >
            <Image src={Logo} className="logo" />
          </Navbar.Brand>

          <div className="align-items-center d-flex justify-content-between">
            <div className="mx-2" style={styles.globeIcon}>
              <Image
                src={Globe}
                className="icon"
                onClick={() => setShowLanguageModal(true)}
              />
            </div>
            <div className="mx-2">
              {/* <Image
                src={GPS}
                className="icon pointer"
                onClick={() => setShowLocationModal(true)}
              /> */}
               <LocationComponent/>
            </div>
          </div>
          <div className="search-form hide-xs">
          <input
            type="text"
            placeholder="Search"
            className="mr-sm-2"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={handleKeyDown} 
          />
          <Button variant="outline-primary" onClick={handleSearch}>
            <FaSearch />
          </Button>
          </div>
          <div className="align-items-center d-flex justify-content-between">
            {role === "user" && (
              <>
                {/* <NavLink to="/profile" className="nav-link">
                <Image src={Heart} className="icon" />
              </NavLink> */}
                <div style={{ position: "relative", marginRight: 20 }}>
                  <NavLink to="/view-cart" className="nav-link">
                    <Image src={Cart} className="icon" />
                  </NavLink>
                  {role === "user" &&
                    cartlength?.cart_details?.cart_items >= 1 && (
                      <div
                        style={{
                          color: "white",
                          position: "absolute",
                          right: "-20px",
                          top: "-10px",
                          width: "1.5rem",
                          height: "1.5rem",
                          borderRadius: "50%",
                          backgroundColor: "var(--primary-red)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "small",
                          fontWeight: "bold",
                        }}
                      >
                        {cartlength.cart_details.cart_items}
                      </div>
                    )}
                </div>
              </>
            )}

            <NavLink to="/login" className="nav-link">
              {isLoggedIn ? (
                <Image
                  src={details.image ? `${base_url}/${details.image}` : User}
                  className="icon"
                  style={{ width: 40, height: 40, borderRadius: "50%" }}
                />
              ) : (
                <Button>Login</Button>
              )}
            </NavLink>
          </div>
          <div className="search-form hide-sm">
          <input
            type="text"
            placeholder="Search"
            className="mr-sm-2"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={handleKeyDown} 
          />
          <Button variant="outline-primary" onClick={handleSearch}>
            <FaSearch />
          </Button>
          </div>
        </Container>
      </Navbar>
      ) : (
        <Navbar expand="lg" className="custom-navbar desktop ">
        <Container>
          <Navbar.Brand href={role === "user" ? "/" : "/store-dashboard"}>
            <Image src={Logo} className="logo" onClick={handleLogo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
           <Nav>
            <LocationComponent/>
           </Nav>

            <div className="search-form">
              <input
                type="text"
                placeholder={t("commonHeader.globalSearch")}
                className="mr-sm-2"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={handleKeyDown} 
              />
              <Button variant="outline-primary" onClick={handleSearch}>
                <FaSearch />
              </Button>
            </div>

            <Nav
              className="ml-auto align-items-center"
              style={{ cursor: "pointer" }}
            >
              <Image
                src={Globe}
                className="icon"
                onClick={() => setShowLanguageModal(true)}
              />
              <h2
                style={styles.modalClick}
                onClick={() => setShowLanguageModal(true)}
              >
                {selectedLanguage}
              </h2>
              <Image
                src={arrowDown}
                style={{ width: 32, height: 32 }}
                onClick={() => setShowLanguageModal(true)}
              />

              {role === "user" && (
                <>
                  <NavLink to="/profile" className="nav-link">
                    <Image src={Heart} className="icon" onClick={ClickMe} />
                  </NavLink>
                  <NavLink to="/view-cart" className="nav-link">
                    <Image src={Cart} className="icon" />
                  </NavLink>
                </>
              )}
              {role === "user" && cartlength?.cart_details?.cart_items >= 1 && (
                <div
                  style={{
                    color: "white",
                    position: "relative",
                    right: "1.3rem",
                    bottom: "1rem",
                    width: "1.5rem",
                    height: "1.3rem",
                    borderRadius: "50%",
                    backgroundColor: "red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "small",
                    fontWeight: "bold",
                  }}
                >
                  {cartlength.cart_details.cart_items}
                </div>
              )}
              <NavLink to="/login" className="nav-link">
                {isLoggedIn ? (
                  <Image
                    src={details.image ? `${base_url}/${details.image}` : User}
                    className="icon"
                    style={{ width: 60, height: 60, borderRadius: "50%" }}
                    onClick={handleUser}
                  />
                ) : (
                  <Button>{t("commonHeader.buttonLogin")}</Button>
                )}
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      )}
    

      
      {showLanguageModal && (
        <Modal
          show={showLanguageModal}
          onHide={() => setShowLanguageModal(false)}
          className="language-modal"
        >
          <Modal.Header>
            <Modal.Title>
              <Image src={Globe} className="icon" />
              <h2 style={styles.modalClick}>{selectedLanguage}</h2>
              <Image src={arrowDown} style={{ width: 32, height: 32 }} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <label className="form-check-label">
                <input
                  type="radio"
                  className="form-check-input"
                  name="language"
                  value={"en"}
                  checked={selectedLanguage === "en"}
                  onChange={() => handleLanguageChange("en")}
                />
                &nbsp;&nbsp;{t("commonHeader.languageEnglish")}
              </label>
              <label className="form-check-label">
                <input
                  type="radio"
                  className="form-check-input"
                  name="language"
                  value={"ne"}
                  checked={selectedLanguage === "ne"}
                  onChange={() => handleLanguageChange("ne")}
                />
                &nbsp;&nbsp;{t("commonHeader.languageNepali")}
              </label>
            </>
            {/* {language &&
            language.map((item, index) => (
              <div key={index} className="form-check">
                <label
                  htmlFor={`language-${index}`}
                  className="form-check-label"
                >
                  <input
                    type="radio"
                    id={`language-${index}`}
                    className="form-check-input"
                    name="language"
                    value={item.language_code}
                    checked={selectedLanguage === item.language_code}
                    onChange={() => handleLanguageChange(item.language_code)}
                  />
                  {item.language_name}
                </label>
              </div>
            ))} */}
            {/* {showLocationModal && <div>
              <LocationComponent/></div>} */}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Header;
