// Login.style.ts

import { CSSProperties } from "react";

export interface Styles {
  view : CSSProperties;
  productName: CSSProperties;
  distanceWrap: CSSProperties;
  distance: CSSProperties;
  distanceImage: CSSProperties;
  qty: CSSProperties;
  priceWrap: CSSProperties;
  offerPrice: CSSProperties;
  price: CSSProperties;
  tax: CSSProperties;
  offer:CSSProperties;
  wishlistIcon:CSSProperties;
  radioWrap:CSSProperties;
  productImagesWrap:CSSProperties;
  productInfoWrap:CSSProperties;
  productRow:CSSProperties;
  imagesWrap:CSSProperties;
  productImages:CSSProperties;
  btnAdd:CSSProperties;
}

const baseStyles: Styles = {
  view: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 12,
    color: "var(--primary-color)",
    textDecoration:"none"
  },
  productName: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 24,
    color: "var(--text-color)",
    marginTop: 15,
    marginBottom: 15,
  },
  btnAdd: {
    borderWidth: 2,
    borderColor: "var(--primary-color)",
    borderStyle: "solid",
    backgroundColor: "var(--white-color)",
    color: "var(--text-color)",
    fontSize: 16,
    fontFamily: "var(--primary-font-semibold)",
  },
  distanceWrap: {
    backgroundColor: "#D9D9D9",
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    width: 90,
    paddingInline: 10,
    position: "relative",
  },
  distance: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--text-color)",
  },
  distanceImage: {
    position: "absolute",
    right: -7,
    top: -4,
    bottom: 0,
    width: 35,
  },
  qty: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "#808080",
  },
  priceWrap: {
    marginBottom: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  price: {
    fontSize: 20,
    fontFamily: "var(--primary-font-medium)",
    color: "#808080",
    textDecoration: "line-through",
    marginLeft: 5,
  },
  offerPrice: {
    fontSize: 24,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
  offer:{
    backgroundColor: "var(--primary-color)",
    padding:5,
    paddingInline:10,
    color:"var(--white-color)",
    fontSize:12,
    fontFamily: "var(--primary-font-semibold)",
    marginLeft:20,
    borderRadius:6
  },
  tax: {
    fontSize: 16,
    fontFamily: "var(--primary-font-regular)",
    color: "#808080",
  },
  wishlistIcon:{
    color:"#333",
    fontSize:22,
    // border:"2px solid #075CF6",
    padding:0,
    borderRadius:6,
    cursor :"pointer"
  },
  radioWrap:{
    marginTop:40,
    display:"flex",
    alignItems:"center",
    marginBottom:20
  },
  productImagesWrap:{
   
  },
  
  productRow:{
    borderBottomWidth:1,
    borderBottomColor:"#D9D9D9",
    borderBottomStyle:"solid",
    marginBottom:40,
    alignItems:"center",
    justifyContent:"center",
    display:"flex"
  },
  productInfoWrap:{
    paddingTop:20, 
    paddingBottom:20,
    paddingLeft:25,
    borderLeftWidth:1,
    borderLeftColor:"#D9D9D9",
    borderLeftStyle:"solid"

  },
  imagesWrap:{
    width:400,
    margin:"auto",
    height:400,
    overflow:"hidden"
  },
  productImages:{
    width:"100%",
    height:"100%",
    objectFit:"contain"
  }
};

const mobileStyles: Partial<Styles> = {
  productImagesWrap:{
   marginTop:30,
  },
  wishlistIcon:{
    color:"#333",
    fontSize:22,
    // border:"2px solid #075CF6",
    padding:5,
    borderRadius:6,
    cursor :"pointer",
    position:"absolute",
    right:0
  },
  productName: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize:18,
    color: "var(--text-color)",
    marginTop: 15,
    marginBottom: 15,
  },
  imagesWrap:{
    width:300,
    margin:"auto",
    height:300,
    overflow:"hidden"
  },
  productInfoWrap:{
    paddingTop:50, 
    paddingBottom:20,
    paddingLeft:25,
    borderLeftWidth:1,
    borderLeftColor:"#D9D9D9",
    borderLeftStyle:"solid"

  },
  productRow:{
    borderBottomWidth:1,
    borderBottomColor:"#D9D9D9",
    borderBottomStyle:"solid",
    marginBottom:40,
    alignItems:"center",
    justifyContent:"center",
    display:"flex",
    position:"relative"
  },
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};