// Subcategories.tsx

import React, { useEffect, useState } from "react";
import styles from "./Subcategories.style";
import { Sub_categories } from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import { base_url } from "../../Api/ApiConst";
import { SET_SELECTED_STORE_SUBCATEGORY } from "../../Redux/User";

export interface Category {
  id: string;
  name: string;

  // Add other properties as needed
}

export interface SubcategoriesProps {
  onSubcategorySelect: (subcateId: string) => void;
}

interface SubcategoryProps {
  data: Category[]; // Assuming Category interface is defined globally
}

const Subcategories: React.FC<SubcategoriesProps> = ({
  onSubcategorySelect,
}) => {
  interface response {
    id: string;
    name: string;
    category_image: string;
    language_data: any;
  }

  //redux states
  const dispatch = useDispatch();
  const mainId: any = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );

  const [subCategory, setSubCategory] = React.useState<response[]>([]);
  const id = useSelector((state: RootState) => state.data);
  const [selectedId, setSelectedId] = useState("");
  const languageId: any = useSelector((state: RootState) => state.lan);

  // useEffect(() => {
  //   setID(localStorage.getItem("section_id"));
  // }, []);

  useEffect(() => {
    const fetchSubcategories = async () => {
      try {
        const response = await Sub_categories(id);
        if (response && response.status === 200) {
          setSubCategory(response.data);

          let intialSelectedSubCategory: any = response.data.sort(
            (a: any, b: any) => (a.id < b.id ? -1 : 1)
          )[0];
          onSubcategorySelect(intialSelectedSubCategory?.id);
          setSelectedId(intialSelectedSubCategory?.id);
          dispatch(SET_SELECTED_STORE_SUBCATEGORY(intialSelectedSubCategory));
        } else {
          console.log("Error fetching Sub-categories");
        }
      } catch (error) {
        console.log("Error fetching Sub-categories", error);
      }
    };

    if (id) {
      fetchSubcategories();
    }
  }, [id]);

  const handleClick = (item: any) => {
    onSubcategorySelect(item.id);
    setSelectedId(item.id);
    dispatch(SET_SELECTED_STORE_SUBCATEGORY(item));
  };

  

  return (
    <div>
      <ul style={styles.catWrap} className="subcat">
        {subCategory &&
          subCategory.map((item, index) => {
            return (
              <li
                key={item.id}
                onClick={() => handleClick(item)}
                style={{
                  color: selectedId === item.id ? "#075cfe" : "#282828",
                  fontFamily: "var(--primary-font-medium)",
                  fontSize: 18,
                  listStyle: "none",
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                  borderBottomColor: "#d9d9d9",
                  paddingBottom: 10,
                  marginBottom: 0,
                  paddingTop: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="hide-lg">
                    <div className="subcatImgWrap">
                      <img
                        src={`${base_url}${item.category_image}`}
                        className="subcatImg"
                      />
                    </div>
                    <div className="mobile-subcatName">
                      {" "}
                      {item.language_data.length === 0
                        ? item.name
                        : languageId === "ne"
                        ? item.language_data[0].name
                        : item.name}
                    </div>
                  </div>
                  <div className="subcatName">
                    {" "}
                    {item.language_data.length === 0
                      ? item.name
                      : languageId === "ne"
                      ? item.language_data[0].name
                      : item.name}
                  </div>
                  {selectedId === item.id && (
                    <div
                      className="blue-border"
                      style={{
                        width: "9px",
                        height: "25px",
                        backgroundColor: "#075cfe",
                      }}
                    ></div>
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default Subcategories;
