import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button, Image } from 'react-bootstrap';
import styles from './ContactUs.style';
import TitleBar from '../../Components/TitleBar/TitleBar';
import TitleImage from '../../assets/images/contact-us.png';
import Section from '../../Components/Section/Section';
import Chat from "../../assets/images/sms.png";
import Email from "../../assets/images/email.png";
import Phone from "../../assets/images/phone-ringing.png";
import Address from "../../assets/images/map.png";
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
import Heading from '../../Components/Heading/Heading';


interface FormData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

interface FormErrors {
  name?: string;
  email?: string;
  phone?: string;
  message?: string;
}
const ContactUs: React.FC = () => {
  const breadcrumbs: string[] = ['Home', 'Contact Us'];
  const title: string = 'Contact Us';
  const info: string = "Lorem ipsum dolor sit amet, consectetur adipiscing.";
  const imageUrl: string = TitleImage; // Corrected imageUrl

  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = (): boolean => {
    let valid = true;
    const newErrors: FormErrors = {};

    if (!formData.name) {
      newErrors.name = 'Name is required';
      valid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email format';
      valid = false;
    }

    const phoneRegex = /^\d{10}$/;
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
      valid = false;
    } else if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = 'Phone number must be 10 digits';
      valid = false;
    }

    if (!formData.message) {
      newErrors.message = 'Message is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle form submission
      console.log('Form submitted:', formData);
    }
  };  
  const navigate = useNavigate()

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);





  return (
    <div className="contact-us">

      <TitleBar breadcrumbs={breadcrumbs} title={title} info={info} imageUrl={imageUrl} />
      <Section className="contactUs" style={{ paddingTop: 100, paddingBottom: 100 }}>
        <Container>
          <Row>
            <Col>
              <Heading text="Get in Touch" alignment="center" />
              <Row className="mt-5">
                <Col>
                  <Row className="mt-4 contact-info">
                    <Col sm="3" className="text-center">
                      <div style={styles.imgWrap} className="mb-2">
                        <Image src={Chat} style={styles.img} />
                      </div>
                      <div style={styles.contentWrap}>
                        <h3 style={styles.title} className='mb-0'>
                          Chat
                        </h3>
                        <p style={styles.info}>
                          Lorem ipsum
                        </p>
                      </div>
                    </Col>
                    <Col sm="3" className="text-center">
                      <div style={styles.imgWrap} className="mb-2">
                        <Image src={Email} style={styles.img} />
                      </div>
                      <div style={styles.contentWrap}>
                        <h3 style={styles.title} className='mb-0'>
                          Email
                        </h3>
                        <p style={styles.info}>
                          Lorem ipsum
                        </p>
                      </div>
                    </Col>
                    <Col sm="3" className="text-center">
                      <div style={styles.imgWrap} className="mb-2">
                        <Image src={Phone} style={styles.img} />
                      </div>
                      <div style={styles.contentWrap}>
                        <h3 style={styles.title} className='mb-0'>
                          Phone
                        </h3>
                        <p style={styles.info}>
                          Lorem ipsum
                        </p>
                      </div>
                    </Col>
                    <Col sm="3" className="text-center">
                      <div style={styles.imgWrap} className="mb-2">
                        <Image src={Address} style={styles.img} />
                      </div>
                      <div style={styles.contentWrap}>
                        <h3 style={styles.title} className='mb-0'>
                          Address
                        </h3>
                        <p style={styles.info}>
                          Lorem ipsum
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
<hr className="mt-5"/>
          <Row className="mt-5">
            <Col>
              <Heading text="Message Us" alignment='center' />
              <p style={{ ...styles.info, textAlign: "center" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br></br>
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <div className='form-wrap'>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formName">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formPhone">
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formMessage">
                    <Form.Control
                      as="textarea"
                      placeholder="Message"
                      name="message"
                      rows={3}
                      value={formData.message}
                      onChange={handleChange}
                      isInvalid={!!errors.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.message}
                    </Form.Control.Feedback>
                  </Form.Group>
<div className='text-center'>
                  <Button variant="primary" type="submit" className='btnBlue mt-3' >
                    Submit
                  </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </div>
  );
};

export default ContactUs;
