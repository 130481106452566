import { CSSProperties } from "react";
import TitleBg from "../../assets/images/title-bg.png";

export interface Styles {
  bg: CSSProperties;
  searchTitle: CSSProperties;
  imageWrap: CSSProperties;
  productImage: CSSProperties;
  productContent: CSSProperties;
  productShopName: CSSProperties;
  quantity: CSSProperties;
  productName: CSSProperties;
  priceWrap: CSSProperties;
  shopIcon: CSSProperties;
  price: CSSProperties;
  offerPrice: CSSProperties;
  product: CSSProperties;
  btnAdd: CSSProperties;
  offerBadgeWrap: CSSProperties;
  arrowRight: CSSProperties;
  heartImage: CSSProperties;
  heartImageWrap: CSSProperties;
  head: CSSProperties;
  noProduct: CSSProperties;
  text:CSSProperties;
  singleShopWrap: CSSProperties;
  shopName: CSSProperties;
  shopLocation: CSSProperties;
  shopDistance: CSSProperties;
  shopRating: CSSProperties;
  supplyMethod: CSSProperties;
  shopImage: CSSProperties;
  shopImageWrap: CSSProperties;
  bookmarkBg: CSSProperties;
  locationIcon: CSSProperties;
  viewAll:CSSProperties;
  left:CSSProperties;
  shopCol:CSSProperties;
  innerRow : CSSProperties;
  btnSort: CSSProperties;
  btnSortIcon: CSSProperties;
  productRadio: CSSProperties;
}

const baseStyles: Styles = {
  bg: {
    backgroundColor: "#F0F6FF",
    paddingTop: 40,
    paddingBottom: 40,
  },
  searchTitle: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 24,
    color: "var(--text-color)",
    marginBottom:40,
  },
  imageWrap: {
    backgroundColor: "var(--gray-light)",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    textAlign: "center",
    position: "relative",
  },
  productImage: {
    width: 115,
    height: "6rem",
  },
  shopIcon: {
    width: 30,
  },
  productContent: {
    padding: 15,
  },
  head: {
    fontSize: 12,
    fontFamily: "var(--primary-font-semibold)",
  },
  noProduct: {
    fontSize: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "var(--primary-font-semibold)",
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    padding: 20,
    borderRadius: 16,
  },
  productShopName: {
    color: "var(--text-color)",
    fontSize: 13,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 235,
    fontFamily: "var(--primary-font-semibold)",
  },
  quantity: {
    fontSize: 16,
    fontFamily: "var(--primary-font-regular)",
    color: "var(--black-color)",
  },
  productName: {
    fontSize: 18,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
  product: {
    marginBottom: 30,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "var(--gray-light)",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    cursor: "pointer"
  },
  priceWrap: { marginBottom: 15 },
  price: {
    fontSize: 12,
    fontFamily: "var(--primary-font-medium)",
    color: "#AFABAB",
    textDecoration: "line-through",
    marginLeft: 5,
  },
  offerPrice: {
    fontSize: 16,
    fontFamily: "var(--primary-font-bold)",
    color: "var(--black-color)",
  },
  btnAdd: {
    borderWidth: 2,
    borderColor: "var(--primary-color)",
    borderStyle: "solid",
    backgroundColor: "var(--white-color)",
    color: "var(--text-color)",
    fontSize: 16,
    fontFamily: "var(--primary-font-semibold)",
  },

  offerBadgeWrap: {
    width: 41,
    height: 51,
    backgroundColor: "#EA4335",
    position: "absolute",
    top:0,left:10,
    paddingTop:2,
  },

  arrowRight: {
    width: 0,
    height: 0,
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderBottom: " 20px solid #f5f5f5",
    position: "absolute",
    bottom: -1,
    left: 0,
   
  },
  text:{
    fontSize: 10,
    fontFamily: "var(--primary-font-bold)",
    color: "var(--white-color)",
    
  },
  heartImageWrap: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  heartImage: {
    width: 24,
    height: 24,
  },
  singleShopWrap: {
    backgroundColor: "var(--white-color)",
    borderRadius: 15,
    padding: 0,
    position: "relative",
    marginBottom:20,
    cursor:"pointer"
  },
  
  shopName: {
    fontFamily: "var(--primary-font-bold)",
    color: "var(--subheading-color)",
    fontSize: 18,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 235,
  },
  shopLocation: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--light-text-color)",
    fontSize: 16,
  },
  shopDistance: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--light-text-color)",
    fontSize: 16,
  },
  shopRating: {
    alignItems: "center",
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--white-color)",
    fontSize: 13,
    backgroundColor: "var(--rating)",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 2,
    marginBottom:10,
    width:60,
    borderRadius: 5,
  },
  shopImage: { 
    width: "100%",
    borderRadius:13,
    objectFit:"cover",
    height:"100%"
   },
  supplyMethod: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    fontSize: 16,
    lineHeight: 1.7,
  },
  shopImageWrap: {
    overflow:"hidden",
    backgroundColor: "var(--white-color)",
    borderRadius: 15,
    width: "140px",
    height: "140px",
    borderStyle:"solid",
    borderWidth:1,
    borderColor:"#d9d9d9",
    lineHeight: 1.2,
    padding: 5,
  },
  bookmarkBg: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: 184,
    height: 28,
    textAlign: "center"
  },
  locationIcon: {
    width: 20,
    height: 20,
  },
  viewAll:{
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--primary-color)",
    fontSize: 18,
    textDecoration:"none",
    lineHeight: 2.2,
  },
  btnSort: {
    borderWidth: 0,
    backgroundColor: "var(--white-color)",
    float:"right"
  },
  left:{
    marginLeft:20,
  },

  shopCol:{
    
  },
  innerRow :{},
  productRadio:{},
  btnSortIcon:{}
};

const mobileStyles: Partial<Styles> = {
 
  shopCol :{
    width:"33.33%",
    maxWidth:"33.33%",
    paddingInline:5
  },
  singleShopWrap: {
    backgroundColor: "var(--white-color)",
    borderRadius: 15,
    position: "relative",
    marginBottom:20,
    cursor:"pointer",
    padding:0,
    display:"flex",
    flexDirection:"column"
  },
  shopName: {
    fontFamily: "var(--primary-font-bold)",
    color: "var(--subheading-color)",
    fontSize: 14,
    marginTop:10
  },
  left:{
    marginLeft:0,
    width:"100%"
  },
  shopLocation: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--light-text-color)",
    fontSize: 12,
  },
  shopDistance: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--light-text-color)",
    fontSize: 12,
  },
  supplyMethod: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    fontSize: 11,
    lineHeight: 1.5
  },
  innerRow : {
    padding:0
  },
  shopImageWrap: {
    overflow:"hidden",
    backgroundColor: "var(--white-color)",
    borderRadius: 15,
    width: "100px",
    height: "100px",
    borderStyle:"solid",
    borderWidth:1,
    borderColor:"#d9d9d9",
    lineHeight: 1.2,
    padding: 5,
  },

};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};