import { Tuple, combineReducers, configureStore } from '@reduxjs/toolkit';
import dataReducer from '../dataSlice';
import languageReducer from '../languageSlice'
import loginReducer from '../loginSlice'
import mainCategory from '../mainCategory';
import { persistReducer, persistStore } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';
import productId from '../productId';
import offer from '../offer';
import User from '../User';
import Search from '../Search';
import Cart from '../Cart';
import Location from '../Location';
import Payment from '../Payment';
import Wishlist from '../Wishlist';
import GlobalSearch from '../GlobalSearch';
import storeSlice from '../storeSlice';

const persistConfig = {
  key: 'root',
  storage: localStorage,
}

const rootReducer = combineReducers({
  data: dataReducer,
  lan : languageReducer,
  login : loginReducer,
  // token : userTokenReducer,
  mainCategory: mainCategory,
  productId : productId,
  offer: offer,
  User:User,
  Search:Search,
  Cart:Cart,
  Location:Location,
  Payment:Payment,
  Wishlist:Wishlist,
  GlobalSearch:GlobalSearch,
  Store:storeSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: () => new Tuple()
});

persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export default store;