import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Table,
  Spinner,
} from "react-bootstrap";
import { getStyles } from "./ViewPastOrder.style";
import Section from "../../Components/Section/Section";
import { FaAngleRight, FaArrowRight, FaRegClock, FaSort } from "react-icons/fa";
import Search from "../../Components/Search/Search";
import shopImage from "../../assets/images/shop-icon.png";
import Sort from "../../assets/images/sort.png";
import ProductImage from "../../assets/images/onion.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Search_Past_Orders,
  Search_Store_Orders,
  View_Past_Orders,
} from "../../Api";
import { base_url } from "../../Api/ApiConst";
import noProduct from "../../assets/images/no-product.png";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { INITIAL_TAB } from "../../Redux/User";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import ShopIcon from "../../assets/images/default-shop.png";
import { Player } from "@lottiefiles/react-lottie-player";
import lottieEmptyCart from "../../assets/lotties/emptyCart.json";

interface Category {
  message: string;
  status: boolean;
  user_order_list?: {
    amount: string;
    order_details?: [];
  };
}

const ViewPastOrder: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const breadcrumbs: string[] = ["Home", "Store"];
  const handleSearch = (query: string) => {
    // Implement your search logic here
    console.log("Searching for:", query);
  };
  const [loading, setLoading] = useState<boolean>(false);

  const languageId: any = useSelector((state: RootState) => state.lan);

  // interface Category {
  //   id: string;
  //   quantity: string;
  //   price: string;
  //   product?:{
  //     product_image: number;
  //   };
  //   order_details:any
  // }
  const [sortBy, setSortBy] = useState<string>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [storeName, setStoreName] = useState<string | null>("");
  const [storeId, setStoreId] = useState<string | null>("");
  const [product, setProduct] = useState<any>({});
  const [products, setProducts] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  // const [searchText, setSearchText] = useState("");
  // const [storeId, setStoreId] = useState<string | null>("");

  const [store, setStore] = useState<string>("");
  const [orderId, setOrderId] = useState<string | null>("");
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const handleSort = (criteria: string) => {
    // Implement your sorting logic here
    setSortBy(criteria);
  };

  const dispatch = useDispatch();

  const { id } = state;

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    setOrderId(localStorage.getItem(STORAGE_CONSTANTS.ORDER_ID));
    setStoreId(localStorage.getItem(STORAGE_CONSTANTS.STORE_ID));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userToken) {
      Cart_Details();
    }
  }, [userToken]);

  // const fetchOrderDetails = async () => {
  //   try {
  //     const response = await Search_Past_Orders(id, storeId, currentPage);
  //     if (response && response.status === 200) {
  //       // const retrieveProductDetails=getProductDetails(response.data)
  //       console.log(response.data, "dataaaaaaaa=====>");
  //       setProduct(response.data.user_order_list[0]);
  //       setProducts(response.data.user_order_list[0].order_details);
  //       // setProduct(response.data.store_product_list)
  //     } else {
  //       console.log("Error fetching Past Order Details");
  //     }
  //   } catch (error) {
  //     console.log("Error fetching Past Order Details", error);
  //   }
  // };

  const getProductDetails = (productObject: any) => {
    const getExtraProductInfo = {};
    const getRequiredFields =
      productObject?.user_order_list?.order_details.find((item: any) => {});
  };

  const handleBack = () => {
    navigate("/profile");
    dispatch(INITIAL_TAB("Order"));
  };

  const Cart_Details = async () => {
    try {
      setLoading(true);
      const response = await View_Past_Orders(id);
      if (response && response.status === 200) {
        console.log(response.data, "hgggggggggggggggggggggggj");
        // setOrderId(response.data.cart_details.order.id);
        setProducts(response.data.cart_details.order.order_list);
        setProduct(response.data.cart_details.order.order_details);
        const cartDataItems: any =
          response.data?.cart_details?.order?.order_list;
        let pickupArray: any[] = [];

        // Object.keys(cartDataItems).map((key) => {
        //   const foundPickupOption = selectedPickupOptions.find(
        //     (ele) => ele?.id == key
        //   );
        //   const productsForStore = cartDataItems[key];
        //   let storePickupOptions: any;

        //   if (productsForStore?.length > 0) {
        //     storePickupOptions = getDeliveryOptions(
        //       productsForStore[0]?.product?.product_vendor_store?.store_type
        //     );
        //   }

        //   if (foundPickupOption?.id && foundPickupOption?.selectedOption) {
        //     pickupArray.push(foundPickupOption);
        //   } else if (storePickupOptions.length === 1) {
        //     const Obj = {
        //       id: key,
        //       selectedOption: storePickupOptions[0]?.value,
        //     };
        //     pickupArray.push(Obj);
        //   } else {
        //     const Obj = {
        //       id: key,
        //       selectedOption: "",
        //     };
        //     pickupArray.push(Obj);
        //   }
        // });
        // setSelectedPickupOptions(pickupArray);

        // setStores(response.data.store_list);
      } else {
        console.log("Error fetching Cart details");
      }
    } catch (error: any) {
      console.log("Error fetching Cart details", error);
      // setProducts("")
    } finally {
      setLoading(false);
    }
  };

  // const handleClick = (id: any) => {
  //   const state = {
  //     product_id: id
  //   };
  //   navigate('/view-product', { state: state });
  // }

  // console.log(userToken)
  return (
    <>
      <Section className="viewOrder" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={1}></Col>
            <Col sm={11} className="px-5">
              <h2 style={styles.orderId}>
                {t("viewOrder.orderId")} : #{id}
              </h2>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className="view-order" style={{ marginTop: -30 }}>
        <div style={styles.whiteWrap}>
          <Container>
            {!loading && Object.keys(products).length !== 0 && (
              <Row className="">
                <Col sm={1}>
                  <Button
                    variant="link"
                    style={{ padding: 0 }}
                    onClick={handleBack}
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className="arrow-left"
                    />
                  </Button>
                </Col>
                <Col sm={4}>
                  <h3 style={styles.viewOrderInfo}>
                    {t("viewOrder.orderDate")} :{" "}
                    <span style={styles.info}>
                      {String(product?.purchase_date).split("T")[0]}
                    </span>
                  </h3>
                </Col>
                <Col
                  sm={4}
                  className="align-items-end d-flex justify-content-center pr-3"
                >
                  <h3 style={styles.viewOrderInfo}>
                    {t("orderList.status")} :{" "}
                    <span style={styles.info}>{product?.status}</span>
                  </h3>
                </Col>{" "}
                <Col
                  sm={3}
                  className="align-items-end d-flex justify-content-center pr-3"
                >
                  {product?.expected_delivery && (
                    <h3 style={styles.viewOrderInfo}>
                      {"Delivery Date"} :{" "}
                      <span style={styles.info}>
                        {String(product?.expected_delivery).split("T")[0]}
                      </span>
                    </h3>
                  )}
                </Col>
              </Row>
            )}
            <div style={styles.orderTableWrap}>
              {loading && (
                <Row>
                  <Col className="d-flex align-items-center justify-content-center">
                    <Spinner animation="grow" variant="primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                </Row>
              )}
              {!loading && Object.keys(products).length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3 style={styles.noProduct}>
                    <Image
                      src={noProduct}
                      className="mx-2"
                      style={{ width: 32, height: 32 }}
                    />{" "}
                    {t("cart.emptyCart")}{" "}
                  </h3>
                </div>
              ) : (
                <>
                  {Object.keys(products).map((item, index) => (
                    <React.Fragment key={index}></React.Fragment>
                  ))}
                  <Table
                    style={styles.orderTable}
                    className="productTable mb-0"
                  >
                    {!loading && Object.keys(products).length !== 0 && (
                      <thead>
                        <tr>
                          <th className="hide-xs"></th>
                          <th></th>
                          <th></th>
                          <th style={{ ...styles.th, textAlign: "right" }}>
                            {t("userProfile.qty")}
                          </th>
                          <th style={{ ...styles.th, textAlign: "right" }}>
                            {t("viewOrder.amount")}
                          </th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {Object.keys(products).map((item, index) => (
                        <React.Fragment key={index}>
                          <tr style={styles.cartItem}>
                            <td colSpan={5} style={{ padding: 0 }}>
                              <div
                                className="d-flex align-items-center justify-content-between my-1"
                                style={styles.shopWrap}
                              >
                                <div className="d-flex align-items-center justify-content-start">
                                  <Image
                                    src={
                                      products[item][0]?.product
                                        ?.product_vendor_store?.logo
                                        ? `${base_url}/${products[item][0]?.product?.product_vendor_store?.logo}`
                                        : ShopIcon
                                    }
                                    className="hide-xs"
                                    style={{ width: 44 }}
                                  />
                                  <div className="mt-2 mx-3">
                                    <p
                                      className="mb-0"
                                      style={styles.cartTitle}
                                    >
                                      {products[item][0]?.product
                                        ?.product_vendor_store?.language_data
                                        .length === 0
                                        ? products[item][0]?.product
                                            ?.product_vendor_store?.store_name
                                        : languageId === "ne"
                                        ? products[item][0]?.product
                                            ?.product_vendor_store
                                            ?.language_data[0]?.store_name
                                        : products[item][0]?.product
                                            ?.product_vendor_store?.store_name}
                                    </p>
                                    {/* <div
                                      style={{ display: "flex", gap: "0.2rem" }}
                                    >
                                      <p style={styles.orderInfoo}>
                                        Store Address :
                                      </p>{" "}
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "0.2rem",
                                        }}
                                      >
                                        <p style={styles.orderInfoo}>
                                          {
                                            products[item][0]?.product
                                              ?.product_vendor_store?.address
                                          }
                                        </p>
                                        ,
                                        <p style={styles.orderInfoo}>
                                          {
                                            products[item][0]?.product
                                              ?.product_vendor_store?.city
                                          }
                                        </p>
                                        ,
                                        <p style={styles.orderInfoo}>
                                          {
                                            products[item][0]?.product
                                              ?.product_vendor_store?.pincode
                                          }
                                        </p>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          {products[item].map(
                            (productItem: any, subIndex: any) => (
                              <tr key={subIndex} style={{ paddingTop: 30 }}>
                                <td className="hide-xs">
                                  <div style={styles.dot}></div>
                                </td>
                                <td>
                                  <div style={styles.orderImageWrap}>
                                    <Image
                                      src={
                                        base_url +
                                        productItem?.product?.product_image
                                      }
                                      style={styles.orderImage}
                                    />
                                  </div>
                                </td>
                                <td style={styles.orderInfo}>
                                  <p
                                    className="mb-0"
                                    style={{
                                      fontFamily: "var(--primary-font-medium)",
                                    }}
                                  >
                                    {productItem?.product?.language_data
                                      .length === 0
                                      ? productItem?.product?.product_name
                                      : languageId === "ne"
                                      ? productItem?.product?.language_data[0]
                                          ?.product_name
                                      : productItem?.product?.product_name}
                                  </p>
                                  <p
                                    className="mb-0"
                                    style={{
                                      fontFamily: "var(--primary-font-regular)",
                                      fontSize: 16,
                                    }}
                                  >
                                    {productItem.product.PRDWeight}{" "}
                                    {productItem.product.unit_measure}
                                  </p>
                                </td>
                                <td
                                  style={{
                                    ...styles.orderInfo,
                                    textAlign: "right",
                                  }}
                                >
                                  {productItem.quantity}
                                </td>
                                <td
                                  style={{
                                    ...styles.orderInfo,
                                    textAlign: "right",
                                    fontFamily: "var(--primary-font-semibold)",
                                  }}
                                >
                                  {productItem.price}
                                </td>
                              </tr>
                            )
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}
            </div>
            <hr style={{ borderTopColor: "#000", marginTop: 0 }} />
            {!loading && Object.keys(products).length !== 0 && (
              <div>
                <div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span style={styles.value500}>
                      {t("viewOrder.mrpTotal")}
                    </span>
                    <span style={styles.value600}>₹ {product?.sub_total}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span style={styles.value500}>{t("viewOrder.tax")}</span>
                    <span style={styles.value400}>0</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span style={styles.value500}>
                      {t("viewOrder.shipping")}
                    </span>
                    <span style={styles.value400}>0</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span style={styles.value500}>
                      Discount ({product?.coupon})
                    </span>
                    <span style={styles.value400}>
                      ₹ {product?.discount ? parseFloat(product?.discount) : 0}
                    </span>
                  </div>
                </div>
                <hr style={{ borderTopColor: "#000" }} />
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <span style={styles.value600}>{t("viewOrder.amount")}</span>
                  <span style={styles.value600}>₹ {product?.amount}</span>
                </div>
                <Row className="mt-5">
                  <Col>
                    <h2 style={styles.label}>
                      {t("viewOrder.deliveryZipCode")}
                    </h2>
                    <div style={styles.value}>
                      {
                        <>
                          <span>{product?.shipping_data?.street_address}</span>,
                          <span>{product?.shipping_data?.state}</span>,
                          <span>{product?.shipping_data?.post_code}</span>
                        </>
                      }
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <h2 style={styles.label}>{"Mode of Payment"}</h2>
                    <div style={{ ...styles.value, textAlign: "center" }}>
                      {product?.shipping_data?.payment_method === "cod"
                        ? "Cash on Delivery"
                        : product?.shipping_data?.payment_method ??
                          "Mode of Payment"}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <h2 style={styles.label}>
                      {t("viewOrder.productDeliveredNote")}
                    </h2>
                    <div style={{ ...styles.value, textAlign: "left" }}>
                      {product?.shipping_data?.order_note ??
                        t("viewOrder.deliveryNote")}
                    </div>
                  </Col>
                </Row>
                <Row className="my-5">
                  <Col>
                    <a
                      href={`${base_url}order/generate_invoice/${product?.id}/`}
                      className="btnDownloadInvoice"
                    >
                      <FontAwesomeIcon icon={faDownload} className="mx-2" />
                      {t("viewOrder.downloadOrderSummary")}
                    </a>
                  </Col>
                </Row>
              </div>
            )}
          </Container>
        </div>
      </Section>
    </>
  );
};

export default ViewPastOrder;
