import React, { useEffect, useState } from 'react';
import { getStyles } from './StoreProfile.style';
import { Button, Col, Image, Row, Form } from 'react-bootstrap';
import ProfileImage from '../../assets/images/avtar.png';
import EditIcon from '../../assets/images/edit.png';
import { My_Store } from '../../Api';
import { Link } from 'react-router-dom';
import { STORAGE_CONSTANTS } from '../../shared/Constants/Constants';
import { base_url } from '../../Api/ApiConst';
import { useTranslation } from 'react-i18next';

const StoreProfile: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const [storeid, setStoreid] = useState<string | null>("");
  const [storetoken, setStoretoken] = useState<string | null>("");
  const [storeData, setStoreData] = useState<any>(null);

  useEffect(() => {
    setStoretoken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN))
    window.scrollTo(0, 0);
  }, []);

  useEffect(() =>{
    if (storetoken) {
      const handleAPI = async () => {
        try {
          const response = await My_Store(storetoken);
          if (response && response.status === 200) {
            console.log(response.data)
            setStoreid(response.data.my_stores[0].id)
            setStoreData(response.data.my_stores[0]);

          } else {
            console.log("Error fetching My Store details");
          }
        } catch (error) {
          console.log("Error fetching My Store details", error);
        }
      };
      handleAPI();
    }
  },[storetoken])
  if (!storeData) {
    return null; // or loading indicator
  }

  const { store_name, contact_number, owner_name, contact_person_number, email,logo } = storeData;

  // console.log(logo,"logo------>")

  return (
    <>
    <h3 className="tab-title">{t("userProfile.storeProfile")}</h3>
    <div style={styles.basicInfo} className="d-flex align-items-center profile-row">
      <div style={styles.profileImageWrap}>
        <Image src={logo ? `${base_url}${logo}` : ProfileImage} style={styles.profileImage} />
      </div>
      <div style={styles.info}>
        <h3 style={styles.name}>{store_name}</h3>
        <h3 style={styles.phone}>{contact_number}</h3>
        <h3 style={styles.email}>{email}</h3>
      </div>
      {/* <Button style={styles.editIconWrap}><Image src={EditIcon} style={styles.editIcon} /></Button> */}
    </div>

    {/* <div style={styles.basicInfo} className="mt-3">
      <div>
        <h3 className="tab-title">My Profile</h3>
        <Button style={styles.editIconWrap}>
          <Image src={EditIcon} style={{ ...styles.editIcon, top: 24 }} />
        </Button>
      </div>

      <Row>
        <Col sm={4}>
          <label style={styles.label}>First Name</label>
          <p style={styles.value}>{owner_name}</p>
        </Col>
        <Col sm={4}>
          <label style={styles.label}>Last Name</label>
          <p style={styles.value}>Lorem ipsum</p>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <label style={styles.label}>Phone Number</label>
          <p style={styles.value}>{contact_person_number}</p>
        </Col>
        <Col sm={4}>
          <label style={styles.label}>Email Address</label>
          <p style={styles.value}>{email}</p>
        </Col>
      </Row>
    </div> */}
   
    <div className='d-flex justify-content-end mt-3'>
      <Link to="/set-store" className='btnBlue'>{t("userProfile.updateStore")}</Link>
    </div>
    {/* <div style={styles.deleteWrap}>
      <h3 style={styles.deleteTitle}>Delete Account</h3>
      <p style={styles.deleteInfo}>Deleting your account will remove all your orders, wallet amount and any active referral</p>
    </div> */}
    {/* <div>
        <h3>Welcome User!</h3>
        <div>
          <h4>User Name : {user}</h4>
        </div>
        <div>
          <h4>Phone Number : {userNum}</h4>
        </div>
        <div>
          <Button variant="danger" onClick={handleClick}>Logout</Button>
        </div>
      </div> */}
  </>
  )
};

export default StoreProfile;
