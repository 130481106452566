import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Tabs, Tab, Accordion, Card ,Button} from 'react-bootstrap';
import styles from './Faq.style';
import 'bootstrap/dist/css/bootstrap.min.css';

import TitleBar from '../../Components/TitleBar/TitleBar';
import TitleImage from '../../assets/images/Faq.png';
import Section from '../../Components/Section/Section';
import Innvoative from "../../assets/images/innovative.png";
import locationIcon from "../../assets/images/location.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import career from "../../assets/images/career.png";
import Dot from "../../assets/images/dot.png";
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
import Heading from '../../Components/Heading/Heading';
const Faq: React.FC = () => {
  const breadcrumbs: string[] = ['Home', 'FAQs'];
  const title: string = 'Frequently asked questions';
  const info: string = "Lorem ipsum dolor sit amet, consectetur adipiscing.";
  const imageUrl: string = TitleImage; // Corrected imageUrl



  const navigate = useNavigate()

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);



  const faq = {
    orders: [
      { question: 'How can I track my order?', answer: 'You can track your order through the tracking link provided in the confirmation email.' },
      { question: 'Can I change my order?', answer: 'Changes to orders can be made within 24 hours of placing the order.' },
    ],
    delivery: [
      { question: 'What are the delivery options?', answer: 'We offer standard and express delivery options.' },
      { question: 'Can I schedule my delivery?', answer: 'Yes, you can schedule your delivery at the checkout.' },
    ],
    payments: [
      { question: 'What payment methods are accepted?', answer: 'We accept credit cards, debit cards, and PayPal.' },
      { question: 'Is my payment information secure?', answer: 'Yes, we use industry-standard encryption to protect your payment information.' },
    ],
    returns: [
      { question: 'What is the return policy?', answer: 'You can return items within 30 days of receipt.' },
      { question: 'How do I get a refund?', answer: 'Refunds are processed within 5-7 business days after receiving the returned item.' },
    ],
  };
  
  const FaqAccordion: React.FC<{ items: { question: string; answer: string }[] }> = ({ items }) => (
    <Accordion >
      {items.map((item, index) => (
        <Accordion.Item eventKey={`${index}`} key={index}>
          <Accordion.Header>{item.question}</Accordion.Header>
          <Accordion.Body>{item.answer}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
  
  const FaqTabs: React.FC = () => (
    <Tabs defaultActiveKey="orders" id="faq-tabs">
      <Tab eventKey="orders" title="Orders">
        <FaqAccordion items={faq.orders} />
      </Tab>
      <Tab eventKey="delivery" title="Delivery">
        <FaqAccordion items={faq.delivery} />
      </Tab>
      <Tab eventKey="payments" title="Payments">
        <FaqAccordion items={faq.payments} />
      </Tab>
      <Tab eventKey="returns" title="Returns & Refund">
        <FaqAccordion items={faq.returns} />
      </Tab>
    </Tabs>
  );
  
  


  return (
    <div className="faqs">

      <TitleBar breadcrumbs={breadcrumbs} title={title} info={info} imageUrl={imageUrl} />
      <Section className='faqs' style={{ paddingTop: 100, paddingBottom: 100 }}>
        <Container>


        <FaqTabs />
        </Container>
      </Section>
    </div>
  );
};

export default Faq;
