import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import  { getStyles } from "./CreateOffer.style";
import Section from "../../Components/Section/Section";
import { FaAngleRight, FaArrowRight, FaRegClock, FaSort } from "react-icons/fa";
import { All_Product_List, Create_Offers, My_Store } from "../../Api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Category {
  id: string;
  product_name: string;
  PRDISactive: boolean
}

const CreateOffer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const breadcrumbs: string[] = ["Home", "Offers"];

  const [offer, setOffer] = useState("");
  const [couponType, setCouponType] = useState("");
  const [product, setProduct] = useState<Category[]>([]);
  const [productType, setProductType] = useState("");
  const [selectedOffer, setSelectedOffer] = useState("");
  const [limit, setLimit] = useState("");
  const [amount, setAmount] = useState("");
  const [percent, setPercent] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  const [sendPer, setSendPer] = useState("");
  const [storeId, setStoreId] = useState<string | null>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [errorss, setErrorss] = useState({
    // offer: "",
    // product: "",
    // productType: "",
    limit: "",
    amount: "",
    percent: "",
    // validFrom: "",
    // validTo: "",
    // sendPer: "",
  });
  const navigate = useNavigate();



  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userToken) {
      const handleAPI = async () => {
        try {
          const response = await My_Store(userToken);
          if (response && response.status === 200) {
            console.log(response.data);
            setStoreId(response.data.my_stores[0].id);
          } else {
            console.log("Error fetching My Store details");
          }
        } catch (error) {
          console.log("Error fetching My Store details", error);
        }
      };
      handleAPI();
    }
  }, [userToken]);

  useEffect(() => {
    if (storeId) {
      fetchStoreList();
    }
  }, [storeId]);

  const fetchStoreList = async () => {
    try {
      const response = await All_Product_List(storeId);
      if (response && response.status === 200) {
        console.log(response.data.store_product_list, "jjjjjjjjjjjjjjjjjjjjjjjj");
        setProduct(response.data.store_product_list);
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    }
  };

  const schema = yup.object().shape({
    sendPer: yup.string().required(t("responseMessage.sendPerRequired")),
    validFrom: yup.string().required(t("responseMessage.dateRequired")),
    validTo: yup.string().required(t("responseMessage.dateRequired")),
    productType: yup.string().required(t("responseMessage.selectAnOption")),
    offer: yup.string().required(t("responseMessage.selectAnOption")),
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   productName: 'Default Product Name', // Set your default value here
    // }
  });

  const handleChange = (e: any) => {
    const value = e.target.value;
    console.log(value, "words======>")
    setLimit(value)

    const numRegex = /^[0-9]+(\.[0-9]+)?$/

    if (!numRegex.test(value)) {
      setErrorss((errorss) => ({
        ...errorss,
        limit: "* Invalid value",
      }));
    } else {
      setErrorss((errorss) => ({
        ...errorss,
        limit: "",
      }));
    }
  }

  const handleFormSubmit = (data: any) => {
    // Handle form submission here
    console.log(data, "data======>")
    if (data && limit || amount || percent !== "") {
      const CreateOffer = async () => {
        try {
          const response = await Create_Offers(
            userToken,
            storeId,
            data,
            amount,
            limit,
            percent,
          );
          if (response && response.status === 201) {
            // console.log(response.data.bought_together[0]);
            toast.success(response["data"]["message"]);
            navigate("/offers");
          } else {
            console.log(response.data, "uiiiiiiiiiiiiiiiiiiiiiiiiii")

          }
        } catch (error: any) {
          console.log("Error Editing Store Product Details", error);
          toast.error(error.response.data.message);
        }
      };
      CreateOffer();
    }
  };

  const handleOfferChange = (value: any) => {
    setSelectedOffer(value);
  };


  const handleAmount = (e: any) => {
    const value = e.target.value;
    setAmount(value)

    if (!value.match(/^[0-9]+(\.[0-9]+)?$/)) {
      setErrorss((errorss) => ({
        ...errorss,
        amount: "* Invalid value",
      }));
    } else {
      setErrorss((errorss) => ({
        ...errorss,
        amount: "",
      }));
    }
  }

  const handlePercent = (e: any) => {
    const value = e.target.value;
    setPercent(value)

    if (!value.match(/^[0-9]+(\.[0-9]+)?$/)) {
      setErrorss((errorss) => ({
        ...errorss,
        percent: "* Invalid value",
      }));
    } else {
      setErrorss((errorss) => ({
        ...errorss,
        percent: "",
      }));
    }
  }


  console.log(validFrom);

  return (
    <>
      <Section className="createOffer" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className="createOffer" style={{ marginTop: -30 }}>
        <div style={styles.whiteWrap}>
          <Container>
            <Form onSubmit={handleSubmit(handleFormSubmit)}>
              <Form.Group as={Row} controlId="formOffer">
                <Form.Label column sm={12}>
                  {t("offerList.offer")}

                </Form.Label>
                <Controller
                  name="offer"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      as="select"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        handleOfferChange(e.target.value);
                      }}
                    >
                      <option disabled value="">
                        {t("offerList.selectAnOffer")}

                      </option>
                      <option value="amount">{t("offerList.amount")}</option>
                      <option value="limited">{t("offerList.limited")}</option>
                      <option value="percent">{t("offerList.percent")}</option>
                    </Form.Control>
                  )}
                />
                {/* <Col sm={12}>
                  <Form.Control
                    as="select"
                    value={offer}
                    onChange={(e) => setOffer(e.target.value)}
                    // {...register("offer")}
                  >
                    <option disabled value="">
                      Select an offer
                    </option>
                    <option value="amount">Amount</option>
                    <option value="limited">Limited</option>
                    <option value="percent">Percent</option>
                    {/* Add options dynamically */}
                {errors.offer && (
                  <span style={styles.error}>
                    {errors.offer.message}
                  </span>
                )}
              </Form.Group>

              {/* <Form.Group as={Row} controlId="formCouponType">
                                <Form.Label column sm={12}>
                                    Coupon Type
                                </Form.Label>
                                <Col sm={12}>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Reusable"
                                        name="couponType"
                                        value="reusable"
                                        checked={couponType === 'reusable'}
                                        onChange={(e) => setCouponType(e.target.value)}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unique"
                                        name="couponType"
                                        value="unique"
                                        checked={couponType === 'unique'}
                                        onChange={(e) => setCouponType(e.target.value)}
                                    />
                                </Col>
                            </Form.Group> */}

              <Form.Group as={Row} controlId="formProduct">
                <Form.Label column sm={12}>
                  {t("offerList.selectProduct")}
                </Form.Label>
                <Controller
                  name="productType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      as="select"
                      {...field}
                    >
                      <option value="">{t("offerList.selectProduct")}</option>
                      {product &&
                        product.map((item) => {
                          if (item.PRDISactive) {
                            return (
                              <option value={item.id}>{item.product_name}</option>
                            );
                          }
                        })}
                    </Form.Control>
                  )}
                />
                {errors.productType && (
                  <span style={styles.error}>
                    {errors.productType.message}
                  </span>
                )}
                {/* <Col sm={12}>
                  <Form.Control
                    as="select"
                    value={productType}
                    onChange={(e) => setProductType(e.target.value)}
                  >
                    <option value="">Select a product</option>
                    {product &&
                      product.map((item) => {
                          if(item.PRDISactive){
                        return (
                          <option value={item.id}>{item.product_name}</option>
                        );
                          }
                      })}
                    {/* Add options dynamically */}
              </Form.Group>

              {selectedOffer === "limited" ? (
                <Form.Group as={Row} controlId="formLimit">
                  <Form.Label column sm={12}>
                    {t("offerList.limit")}
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type="text"
                      placeholder={t("offerList.enterLimit")}
                      value={limit}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </Col>
                  {errorss && (
                    <span style={styles.error}>
                      {errorss.limit}
                    </span>
                  )}
                </Form.Group>
              ) : selectedOffer === "amount" ? (
                <Form.Group as={Row} controlId="formAmount">
                  <Form.Label column sm={12}>
                    {t("offerList.amount")}
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type="text"
                      placeholder={t("offerList.enterAmount")}
                      value={amount}
                      onChange={(e) => handleAmount(e)}
                      required
                    />
                  </Col>
                  {errorss && (
                    <span style={styles.error}>
                      {errorss.amount}
                    </span>
                  )}
                </Form.Group>
              ) : selectedOffer === "percent" ? (
                <Form.Group as={Row} controlId="formPercent">
                  <Form.Label column sm={12}>
                    {t("offerList.percent")}
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type="text"
                      placeholder={t("offerList.enterPercent")}
                      value={percent}
                      onChange={(e) => handlePercent(e)}
                      required
                    />
                  </Col>
                  {errorss && (
                    <span style={styles.error}>
                      {errorss.percent}
                    </span>
                  )}
                </Form.Group>
              ) : null}

              <Form.Group as={Row} controlId="formValidFrom">
                <Form.Label column sm={12}>
                  {t("offerList.validFrom")}
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type="date"
                    {...register("validFrom")}
                  />
                </Col>
                {errors.validFrom && (
                  <span style={styles.error}>
                    {errors.validFrom.message}
                  </span>
                )}
              </Form.Group>

              <Form.Group as={Row} controlId="formValidTo">
                <Form.Label column sm={12}>
                  {t("offerList.validTo")}
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type="date"
                    {...register("validTo")}
                  />
                </Col>
                {errors.validTo && (
                  <span style={styles.error}>
                    {errors.validTo.message}
                  </span>
                )}
              </Form.Group>

              <Form.Group as={Row} controlId="formSendPer">
                <Form.Label column sm={12}>
                  {t("offerList.sendPer")}
                </Form.Label>
                <Col sm={12}>
                  <Controller
                    name="sendPer"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <Form.Check
                          inline
                          type="radio"
                          label={t("offerList.firstOrderOnly")}
                          name="sendPer"
                          value="first_order"
                          checked={field.value === "first_order"}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setSendPer(e.target.value);
                          }}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label={t("offerList.everyOrder")}
                          name="sendPer"
                          value="every_order"
                          checked={field.value === "every_order"}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setSendPer(e.target.value);
                          }}
                        />
                      </>
                    )}
                  />
                </Col>
                {errors.sendPer && (
                  <span style={{ color: 'red' }}>
                    {errors.sendPer.message}
                  </span>
                )}
              </Form.Group>

              <Row>
                <Col xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  
                  <Button type="submit" className="mx-2" style={styles.btnBlue}>{t("commonFormLabels.submit")}</Button>

                  <Button variant="primary" style={styles.btnCancel}>
                    <Link to="/offers" style={{ textDecoration: "none" ,width:"100%",color:"#333" ,display: "block" }}>{t("commonHeader.cancel")}</Link>
                    </Button>

                  </div>
                </Col>
              </Row>            </Form>
          </Container>
        </div>
      </Section>
    </>
  );
};

export default CreateOffer;
