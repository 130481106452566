import React, { useEffect, useState,lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { My_Store } from '../../Api';
import { STORAGE_CONSTANTS } from '../../shared/Constants/Constants';

const SwitchStore: React.FC = () => {
  const [number,setNumber] = useState<string | null>('')
  const [userToken, setUserToken] = useState<string | null>('');
  useEffect(() =>{
      setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN))
  },[])

  useEffect(() =>{
    if (userToken) {
      const handleAPI = async () => {
        try {
          const response = await My_Store(userToken);
          if (response && response.status === 200) {
            console.log(response.data)
            setNumber(response.data.my_stores[0].id)
          } else {
            console.log("Error fetching My Store details");
          }
        } catch (error) {
          console.log("Error fetching My Store details", error);
        }
      };
      handleAPI();
    }
  },[userToken])

  // console.log(number)
  return( <div>
    {number ? (
      <div>
        Store is already created{' '}
        <Suspense fallback={<div>Loading...</div>}>
          <Link to="/products"> Product List</Link>
        </Suspense>
      </div>
    ) : (
      <div>
        <Link to="/register"> Store Register</Link>
      </div>
    )}
  </div>)
};

export default SwitchStore;
