import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col, Button, Image } from "react-bootstrap";
import { getStyles } from "./ViewProduct.style";
import AddProductImage from "../../assets/images/addProductTitle.png";
import TitleBar from "../../Components/TitleBar/TitleBar";
import Section from "../../Components/Section/Section";
import { Product_Delete, Product_Details } from "../../Api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";
import { base_url } from "../../Api/ApiConst";
import Carousel from "react-multi-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 1901 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1900, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const ViewProduct: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const styles = getStyles(isMobile);
  const breadcrumbs = [<Link to="/" key="home">
  {t("footer.home")}
</Link>, "Store"];
  const title: string = t("product.viewProduct");
  const imageUrl: string = AddProductImage;

  const [productType, setProductType] = useState<"single" | "bulk" | "system">(
    "single"
  );
  const [productName, setProductName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [unitOfMeasure, setUnitOfMeasure] = useState("");
  const [variants, setVariants] = useState("");
  const [minQuantity, setMinQuantity] = useState("");
  const [maxQuantity,setMaxQuantity] = useState("")
  const [inventory, setInventory] = useState("");
  const [price, setPrice] = useState("");
  const [productImage, setProductImage] = useState("");
  const [additional_image_1, setAdditional_image_1] = useState("");
  const [additional_image_2, setAdditional_image_2] = useState("");
  const [productQuantity, setProductQuantity] = useState("");
  const [systemProductID, setSystemProductID] = useState("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [storeId, setStoreId] = useState<string | null>("");
  const [productNameInNepali, setProductNameInNepali] = useState("");
  const [descriptionInNepali, setDescriptionInNepali] = useState("");
  const [priceInNepali, setPriceInNepali] = useState<any>("");
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const { product_id } = state;

  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );

  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );
  const userPincode = useSelector((state: RootState) => state.Location.pincode);


  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    setStoreId(localStorage.getItem(STORAGE_CONSTANTS.STORE_ID));
    localStorage.setItem(STORAGE_CONSTANTS.PRODUCT_ID, product_id);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (product_id) {
      fetchStoreList();
    }
  }, [product_id]);

  const fetchStoreList = async () => {
    try {
      const response = await Product_Details(product_id,userLatitude,userLongitude,userPincode);
      if (response && response.status === 200) {
        let data = response.data.product_details;
        console.log(data.PRDPrice, "data---------->");
        setProductName(data.product_name);
        setDescription(data.product_description);
        setUnitOfMeasure(data.unit_measure);
        setMinQuantity(data.minimum_qty);
        setPrice(data.USDPrice);
        setInventory(data.available);
        setCategory(data.product_subcategory.name);
        setProductImage(data.product_image);
        setAdditional_image_1(data.additional_image_1);
        setAdditional_image_2(data.additional_image_2);
        setPriceInNepali(data.PRDPrice);
        setProductNameInNepali(data?.language_data?.length > 0 ? data.language_data[0]?.product_name:"");
        setDescriptionInNepali(data?.language_data?.length > 0 ? data.language_data[0]?.product_description : "");
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    }
  };

  const handleProductTypeChange = (type: "single" | "bulk" | "system") => {
    setProductType(type);
    // Reset related fields when switching product types
    setProductName("");
    setCategory("");
    setDescription("");
    setUnitOfMeasure("");
    setVariants("");
    setMinQuantity("");
    setInventory("");
    setPrice("");
    setProductQuantity("");
    setSystemProductID("");
    setProductNameInNepali("");
    setDescriptionInNepali("");
    // setPriceInNepali("");
  };

  // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   // Implement your logic to handle form submission
  //   console.log("Product Type:", productType);
  //   console.log("Product Name:", productName);
  //   console.log("Category:", category);
  //   console.log("Description:", description);
  //   console.log("Unit of Measure:", unitOfMeasure);
  //   console.log("Variants:", variants);
  //   console.log("Minimum Quantity:", minQuantity);
  //   console.log("Inventory:", inventory);

  //   console.log("Price:", price);
  //   console.log("Product Quantity:", productQuantity);
  //   console.log("System Product ID:", systemProductID);
  //   // Reset form fields after submission
  //   setProductName("");
  //   setCategory("");
  //   setDescription("");
  //   setUnitOfMeasure("");
  //   setVariants("");
  //   setMinQuantity("");
  //   setPrice("");
  //   setProductQuantity("");
  //   setSystemProductID("");
  //   setInventory("");
  //   setProductNameInNepali("");
  //   setDescriptionInNepali("");
  //   // setPriceInNepali("");
  // };

  const handleDelete = async () => {
    try {
      const response = await Product_Delete(product_id, userToken);
      if (response && response.status === 200) {
        // console.log(response.data)
        toast.success(response["data"]["message"]);
        navigate("/products");
      } else {
        console.log("Error Deleting the product");
      }
    } catch (error) {
      console.log("Error Deleting the product", error);
    }
  };

  const renderCarouselItems = () => {
    const images = [
      productImage,
      additional_image_1,
      additional_image_2,
    ].filter((image) => image); // Filter out empty image URLs

    return images.map((image, index) => (
      <div key={index} style={{ width: 300, height: 300, margin: "auto" }}>
        <Image
          src={base_url.concat(image)}
          alt={`Product Image ${index + 1}`}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    ));
  };
  const renderThumbnails = () => {
    const images = [
      productImage,
      additional_image_1,
      additional_image_2,
    ].filter((image) => image); // Filter out empty image URLs

    return images.map((image, index) => (
      <div key={index} className="thumbnail">
        <Image
          src={base_url.concat(image)}
          alt={`Thumbnail Image ${index + 1}`}
          style={{
            cursor: "pointer",
            border: activeIndex === index ? "2px solid #808080" : "none",
          }}
          onClick={() => handleThumbnailClick(index)}
        />
      </div>
    ));
  };

  const handleThumbnailClick = (index: number) => {
    setActiveIndex(index);
  };

  const [activeIndex, setActiveIndex] = useState(0);

  console.log(priceInNepali, "priceInNepali==========>");

  return (
    <div className="viewProduct">
      <TitleBar breadcrumbs={breadcrumbs} title={title} imageUrl={imageUrl} />
      <Section className="addProduct" style={{ marginTop: -60 }}>
        <div style={styles.whiteWrap}>
          <Container>
            <Form>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <Form.Group >
                  <Form.Check
                    inline
                    type="radio"
                    id="single"
                    label="Single"
                    checked={productType === 'single'}
                    onChange={() => handleProductTypeChange('single')}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    id="bulk"
                    label="Bulk"
                    checked={productType === 'bulk'}
                    onChange={() => handleProductTypeChange('bulk')}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    id="system"
                    label="From System"
                    checked={productType === 'system'}
                    onChange={() => handleProductTypeChange('system')}
                  />

                </Form.Group> */}
              </div>

              {productType === "single" && (
                <>
                  <Form.Group controlId="productName">
                    <Form.Label>{t("product.productName")}</Form.Label>
                    <Form.Control type="text" value={productName} readOnly />
                  </Form.Group>
                  <Form.Group controlId="category">
                    <Form.Label>{t("product.category")}</Form.Label>
                    <Form.Control type="text" value={category} readOnly />
                  </Form.Group>
                  <Form.Group controlId="description">
                    <Form.Label>{t("product.description")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={description}
                      readOnly
                    />
                  </Form.Group>

                  <Row className="sm-product-image-row">
                    <Col sm={3}>
                      {/*<Form.Group controlId="productImage">
                        <Form.Label>{t("product.productImage")}</Form.Label>
                        <Form.Control
                      type="text"
                      value={productImage}
                      readOnly
                    /> 

                      </Form.Group>*/}
                      <div style={styles.productImageWrap} className="viewProductImageSlider">
                        {/* <Image src={`${base_url}${productImage}`} style={styles.productImage}/> */}

                        <Carousel
                          responsive={responsive}
                          ssr={true}
                          itemClass="image-item"
                          customTransition="all .5"
                          transitionDuration={500}
                          containerClass="carousel-container"
                          additionalTransfrom={0}
                          showDots={false}
                          renderDotsOutside={false}
                          arrows={true}
                          infinite={false}
                          draggable={false}
                          swipeable={true}
                          autoPlay={false}
                          centerMode={false}
                          dotListClass=""
                          sliderClass=""
                          slidesToSlide={1}
                          keyBoardControl={true}
                          minimumTouchDrag={80}
                          partialVisible={false}
                          beforeChange={(nextSlide) =>
                            setActiveIndex(nextSlide)
                          }
                          customLeftArrow={
                            <div className="custom-arrow left">
                              <FontAwesomeIcon icon={faAngleLeft} />
                            </div>
                          }
                          customRightArrow={
                            <div className="custom-arrow right">
                              <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                          }
                        >
                          {renderCarouselItems()}
                        </Carousel>
                        <div
                          className="thumbnail-container mt-3"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {renderThumbnails()}
                        </div>
                      </div>
                    </Col>
                    <Col sm={9}>
                      <Form.Group as={Col} controlId="unitOfMeasure">
                        <Form.Label>{t("product.unitOfMeasure")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={unitOfMeasure}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="minQuantity">
                        <Form.Label>{t("product.minimumQuantity")}</Form.Label>
                        <Form.Control
                          type="text"
                          value={minQuantity}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="price">
                        <Form.Label>{t("product.priceUsd")}</Form.Label>
                        <Form.Control type="text" value={price} readOnly />
                      </Form.Group>
                      <Form.Group as={Col} controlId="inventory">
                        <Form.Label>{t("product.inventory")}</Form.Label>
                        <Form.Control type="text" value={inventory} readOnly />
                      </Form.Group>
                    </Col>
                  </Row>

                  <p style={styles.heading}>{t("product.otherLanguage")}</p>
                  <Form.Group as={Col} controlId="productNameInNepali">
                    <Form.Label>{t("product.productNameInNepali")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("product.productNameInNepali")}
                      value={productNameInNepali}
                      onChange={(e) => setProductNameInNepali(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="descriptionInNepali">
                    <Form.Label>{t("product.descriptionInNepali")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("product.descriptionInNepali")}
                      value={descriptionInNepali}
                      onChange={(e) => setDescriptionInNepali(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="priceInNepali">
                    <Form.Label>{t("product.priceInNepali")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("product.priceInNepali")}
                      value={priceInNepali}
                      onChange={(e) => setPriceInNepali(e.target.value)}
                      readOnly
                    />
                  </Form.Group>
                </>
              )}

              {productType === "bulk" && <></>}

              {productType === "system" && (
                <Form.Group controlId="systemProductID">
                  <Form.Label>System Product ID</Form.Label>
                  <Form.Control
                    type="text"
                    value={systemProductID}
                    onChange={(e) => setSystemProductID(e.target.value)}
                    required
                  />
                </Form.Group>
              )}
              <Row>
                <Col xs={12}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="primary"
                      onClick={handleDelete}
                      style={styles.btnRed}
                    >
                      {t("commonHeader.delete")}
                    </Button>
                    <Button variant="primary" style={styles.btnBlue}>
                      <Link
                        to="/edit-product"
                        style={{
                          textDecoration: "none",
                          color: "white",
                          width: "100%",
                          display: "block",
                        }}
                      >
                        {t("commonHeader.edit")}
                      </Link>
                    </Button>
                    <Button variant="primary" style={styles.btnCancel}>
                      <Link
                        to="/products"
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          color: "#333",
                          display: "block",
                        }}
                      >
                        {t("commonHeader.cancel")}
                      </Link>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </Section>
    </div>
  );
};

export default ViewProduct;
