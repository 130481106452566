import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Table,
  Spinner,
} from "react-bootstrap";
import { getStyles } from "./ViewOrder.style";
import Section from "../../Components/Section/Section";
import {
  FaAngleRight,
  FaArrowLeft,
  FaArrowRight,
  FaRegClock,
  FaSort,
} from "react-icons/fa";
import Search from "../../Components/Search/Search";
import shopImage from "../../assets/images/shop-icon.png";
import Sort from "../../assets/images/sort.png";
import ProductImage from "../../assets/images/onion.png";
import { Link, useNavigate } from "react-router-dom";
import { Search_Store_Orders } from "../../Api";
import { base_url } from "../../Api/ApiConst";
import noProduct from "../../assets/images/no-product.png";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";

const ViewOrder: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const breadcrumbs = [
    <Link to="/" key="home">
      {t("footer.home")}
    </Link>,
    "Order",
  ];
  const handleSearch = (query: string) => {
    // Implement your search logic here
    console.log("Searching for:", query);
  };
  // interface Category {
  //   id: string;
  //   quantity: string;
  //   price: string;
  //   product?:{
  //     product_image: number;
  //   };
  //   order_details:any
  // }

  interface Category {
    message: string;
    status: boolean;
    user_order_list?: {
      amount: string;
      order_details?: [];
    };
  }

  const [sortBy, setSortBy] = useState<string>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [storeName, setStoreName] = useState<string | null>("");
  const [storeId, setStoreId] = useState<string | null>("");
  const [product, setProduct] = useState<any>({});
  const [products, setProducts] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const [store, setStore] = useState<string>("");
  const [orderId, setOrderId] = useState<string | null>("");
  const navigate = useNavigate();

  const handleSort = (criteria: string) => {
    // Implement your sorting logic here
    setSortBy(criteria);
  };

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    setOrderId(localStorage.getItem(STORAGE_CONSTANTS.ORDER_ID));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userToken) {
      fetchOrderDetails();
    }
  }, [userToken]);

  const fetchOrderDetails = async () => {
    try {
      const payload = {
        id: storeId,
        product_name: orderId,
        store_based: true,
      };
      setLoading(true);
      const response = await Search_Store_Orders(payload, currentPage);
      if (response && response.status === 200) {
        console.log(response.data, "orderlist---------->");
        // const retrieveProductDetails=getProductDetails(response.data)
        setProduct(response.data.user_order_list[0]);
        setProducts(response.data.user_order_list[0].order_details);
        // setProduct(response.data.store_product_list)
      } else {
        console.log("Error fetching Order Details");
      }
    } catch (error) {
      console.log("Error fetching Order Details", error);
    } finally{
      setLoading(false);
    }
  };

  const getProductDetails = (productObject: any) => {
    const getExtraProductInfo = {};
    const getRequiredFields =
      productObject?.user_order_list?.order_details.find((item: any) => {});
  };

  // const handleClick = (id: any) => {
  //   const state = {
  //     product_id: id
  //   };
  //   navigate('/view-product', { state: state });
  // }

  // console.log(userToken)
  return (
    <>
      <Section className="viewOrder" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={1}></Col>
            <Col sm={11} className="px-5">
              <h2 style={styles.orderId}>
                {t("viewOrder.orderId")} : #{orderId}
              </h2>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className="view-order" style={{ marginTop: -30 }}>
        <div style={styles.whiteWrap}>
        {loading && (
                  <Row>
                    <Col className="d-flex align-items-center justify-content-center">
                      <Spinner animation="grow" variant="primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Col>
                  </Row>
                )}
          { !loading && products.length > 0 && <Container>
            <div className="d-flex align-items-center justify-content-between">
              <div className="">
                <Link to="/orders">
                  <FaArrowLeft className="arrow-left" />{" "}
                </Link>
              </div>
              <div>
                <h3 style={styles.viewOrderInfo}>
                  {t("viewOrder.orderDate")} :{" "}
                  <span style={styles.info}>
                    {String(product?.order_date).split("T")[0]}
                  </span>
                </h3>
                <h3 style={styles.viewOrderInfo}>
                  {t("orderList.status")} :{" "}
                  <span style={styles.info}>{product?.status}</span>
                </h3>
              </div>
            </div>
            <div style={styles.orderTableWrap}>
              <Table style={styles.orderTable} className="productTable">
                <thead>
                  <tr>
                    <th className="hide-xs"></th>
                    <th></th>
                    <th></th>
                    <th style={{ ...styles.th, textAlign: "center" }}>
                      {t("userProfile.qty")}
                    </th>
                    <th style={{ ...styles.th, textAlign: "center" }}>
                      {t("viewOrder.amount")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* order_details */}
                  {products &&
                    products.map((item: any) => {
                      return (
                        <tr>
                          <td className="hide-xs">
                            <div style={styles.dot}></div>
                          </td>
                          <td>
                            <div style={styles.orderImageWrap}>
                              <Image
                                src={base_url + item?.product?.product_image}
                                style={styles.orderImage}
                              />
                            </div>
                          </td>
                          <td style={styles.orderInfo}>
                            <p
                              className="mb-0"
                              style={{
                                fontFamily: "var(--primary-font-medium)",
                              }}
                            >
                              {item?.product?.product_name}
                            </p>
                            <p
                              className="mb-0"
                              style={{
                                fontFamily: "var(--primary-font-regular)",
                                fontSize: 16,
                              }}
                            >
                              {item?.product?.PRDWeight}
                            </p>
                          </td>
                          <td
                            style={{ ...styles.orderInfo, textAlign: "center" }}
                          >
                            {item.quantity}
                          </td>
                          <td
                            style={{
                              ...styles.orderInfo,
                              textAlign: "center",
                              fontFamily: "var(--primary-font-semibold)",
                            }}
                          >
                            {item?.price}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
            <hr style={{ borderTopColor: "#000" }} />
            <div>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <span style={styles.value500}>{t("viewOrder.mrpTotal")}</span>
                <span style={styles.value600}>₹ {product?.sub_total}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span style={styles.value500}>{t("viewOrder.tax")}</span>
                <span style={styles.value400}>0</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span style={styles.value500}>{t("viewOrder.shipping")}</span>
                <span style={styles.value400}>0</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span style={styles.value500}>
                  Discount ({product?.coupon})
                </span>
                <span style={styles.value400}>
                  ₹ {product?.discount ? parseFloat(product?.discount) : 0}
                </span>
              </div>
            </div>
            <hr style={{ borderTopColor: "#000" }} />
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span style={styles.value600}>{t("viewOrder.amount")}</span>
              <span style={styles.value600}>₹ {product?.amount}</span>
            </div>
            <Row className="mt-5">
              <Col>
                <h2 style={styles.label}>{"Delivery Address"}</h2>
               {product?.shipping_data?.street_address ? <div style={styles.value}>
                  <span>{product?.shipping_data?.street_address}</span>,
                  <span>{product?.shipping_data?.state}</span>,
                  <span>{product?.shipping_data?.post_code}</span>
                </div> : <div style={styles.value}>{"Delivery Address"}</div>}
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <h2 style={styles.label}>{t("viewOrder.deliveryZipCode")}</h2>
               <div style={styles.value}>
                  {product?.shipping_data?.post_code ? product?.shipping_data?.post_code : "Delivery pincode"}
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <h2 style={styles.label}>
                  {t("viewOrder.productDeliveredNote")}
                </h2>
                <div style={styles.value}>
                  {product?.shipping_data?.order_note ? product?.shipping_data?.order_note : t("viewOrder.deliveryNote")}
                </div>
              </Col>
            </Row>
          </Container> }
        </div>
      </Section>
    </>
  );
};

export default ViewOrder;
