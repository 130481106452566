import React, { useEffect, useRef, useState } from "react";
import { getStyles } from "./Wishlist.style";
import { Col, Image, Row, Button, Overlay, Popover } from "react-bootstrap";
import Section from "../Section/Section";
import {
  Add_To_Cart,
  Delete_Wishlist,
  View_Cart,
  View_Wishlist,
} from "../../Api";
import { base_url } from "../../Api/ApiConst";
import { toast } from "react-toastify";
import { VIEW_CART } from "../../Redux/Cart";
import { useDispatch, useSelector } from "react-redux";
import noProduct from "../../assets/images/no-product.png";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";
import { RootState } from "../../Redux/Store/Store";
import NoDataFound from "../NoDataFound/NoDataFound";
import Sort from "../../assets/images/sort.png";

const Wishlist: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const [products, setProducts] = useState<any[]>([]);
  const [userToken, setUserToken] = useState<string | null>("");
  const [state, setState] = useState<boolean>();
  const [stores, setStores] = React.useState<any>([]);
  const [currency, setCurrency] = useState("NPR");
  const outOfstock = useSelector((state: RootState) => state.offer.OutOfStock);
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState<string>("");
  const [showSortPopover, setShowSortPopover] = useState(false);
  const target = useRef(null);
  const sortData = [
    {
      id: 1,
      Sorttype: "product_name",
      name: t("storeSortModal.productNameAsc"),
      Ascending: true,
    },
    {
      id: 2,
      Sorttype: "product_name",
      name: t("storeSortModal.productNameDesc"),
      Ascending: false,
    },
    {
      id: 3,
      Sorttype: "PRDPrice",
      name: t("storeSortModal.priceAsc"),
      Ascending: true,
    },
    {
      id: 4,
      Sorttype: "PRDPrice",
      name: t("storeSortModal.priceDesc"),
      Ascending: false,
    },
  ];

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
  }, []);

  useEffect(() => {
    if (userToken) {
      WishList();
    }
  }, [userToken, state]);

  const WishList = async () => {
    try {
      const response = await View_Wishlist(userToken);
      if (response && response.status === 200) {
        // console.log(response.data.store_list.language_data[0].store_name);
        console.log(response.data);
        setProducts(response.data.wishlist_details.product_list);
        // setStores(response.data.store_list);
      } else {
        console.log("Error fetching Cart details");
      }
    } catch (error) {
      console.log("Error fetching Cart details", error);
    }
  };

  const handleDelete = async (id: any) => {
    try {
      const response = await Delete_Wishlist(userToken, id);
      if (response && response.status === 200) {
        toast.success(response["data"]["message"]);
        await WishList();
      } else {
        toast.error(response["data"]["message"]);
      }
    } catch (error: any) {
      console.log("Error occurred:", error);
    }
  };

  const handleCart = (
    id: any,
    storeId: any,
    store_name: any,
    language_data: any,
    store_logo: any
  ) => {
    const addToCart = async () => {
      try {
        const response = await Add_To_Cart(id, currency);
        if (response && response.status === 200) {
          console.log(response.data, "jhhhhhhhhhhhhhhh");

          // localStorage.setItem(STORAGE_CONSTANTS.SHOP_ID, storeId);
          toast.success(response["data"]["message"]);
          // localStorage.setItem(
          //   STORAGE_CONSTANTS.ORDER_ID,
          //   response?.data?.order_id
          // );
          // const state = {
          //   store_name: store_name,
          //   language_data: language_data,
          //   store_logo: store_logo,
          // };
          // navigate("/store", { state: state });
          await Delete_Wishlist(userToken, id);
          await WishList();
          const Cartresponse = await View_Cart();
          if (Cartresponse && Cartresponse.status === 200) {
            console.log(Cartresponse.data, "kkkkkkkkkkkk");
            dispatch(VIEW_CART(Cartresponse.data));
            // console.log(Cartresponse.data,"99999999999999")
          }
        } else {
          toast.error(response["data"]["message"]);
        }
      } catch (error: any) {
        console.log("Error occurred:", error);
      }
    };
    addToCart();
  };

  const handleSort = (item: any) => {
    setSortBy(item);
    if (item?.name) {
      const sortedProducts: any = products.sort((a: any, b: any) => {
        const itemA = item?.name?.includes("Product Name")
          ? a[item?.Sorttype].toLowerCase()
          : a[item?.Sorttype];
        const itemB = item?.name?.includes("Product Name")
          ? b[item?.Sorttype].toLowerCase()
          : b[item?.Sorttype];

        if (item?.Ascending) {
          if (itemA < itemB) return -1;
          if (itemA > itemB) return 1;
        } else {
          if (itemA > itemB) return -1;
          if (itemA < itemB) return 1;
        }
        return 0;
      });
      setProducts(() => sortedProducts);
    } else {
      setProducts(products);
    }
  };

  const handleOverlayClose = () => {
    setShowSortPopover(false);
  };
  return (
    <>
      <Row className="align-items-center justify-content-between past-order-wrap mb-3">
        <Col>
          <h2 style={styles.title} className="mb-0">
            {t("userProfile.wishlist")}
          </h2>
        </Col>
        {products.length !== 0 && (
          <Col style={{ position: "relative" }} className="wishlistSort">
            <div ref={target} style={{ float: "right" }}>
              <Button
                title="Sort"
                style={styles.btnSort}
                variant="outline-primary"
                onClick={() => setShowSortPopover(!showSortPopover)}
              >
                <Image src={Sort} className="sortImage" />
              </Button>
              <Overlay
                show={showSortPopover}
                target={target.current}
                placement="left"
                containerPadding={20}
                container={document.body}
                onHide={handleOverlayClose}
                rootClose
              >
                <Popover id="popover-contained">
                  {/* <Popover.Header as="h3">Sort By</Popover.Header> */}
                  <Popover.Body>
                    <ul
                      style={{ listStyleType: "none", padding: 0 }}
                      className="sortPopover"
                    >
                      {sortData &&
                        sortData.map((ele: any) => {
                          return (
                            <li>
                              <Button
                                variant="link"
                                onClick={() => handleSort(ele)}
                              >
                                {ele?.name}
                              </Button>
                            </li>
                          );
                        })}
                    </ul>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>
          </Col>
        )}
        {/* <Col>
            <p style={styles.viewAll} className='mb-0'>View All</p>
          </Col> */}
      </Row>
      <Section className="wishlist" style={{ paddingTop: 0 }}>
        {products.length === 0 ? (
          // <Row>
          //   <Col className="d-flex justify-content-center align-items-center ">
          //     <div
          //       style={{ display: "flex", alignItems: "center", width: "100%",justifyContent:"center" }}
          //     >
          //       <h3 style={styles.noProduct}>
          //         <Image
          //           src={noProduct}
          //           className="mx-2"
          //           style={{ width: 32, height: 32 }}
          //         />
          //         {t("userProfile.addProductsToWishList")}
          //       </h3>
          //     </div>
          //   </Col>
          // </Row>
          <NoDataFound title={t("userProfile.addProductsToWishList")} />
        ) : (
          products &&
          products.map((item) => {
            return (
              <div className="order-wrap">
                <Row className="align-items-center " style={styles.productRow}>
                  <Col sm={2} className="imageCol">
                    <div style={styles.productImageWrap}>
                      <Image
                        src={`${base_url}/${item?.product_image}`}
                        style={styles.productImage}
                      />
                    </div>
                  </Col>
                  <Col sm={4} className="infoCol">
                    <div className="px-2">
                      <h2 style={styles.productName}>{item?.product_name}</h2>
                      {/* <p style={styles.qty}>{t("userProfile.qty")}: 1</p> */}
                      <p style={styles.wt}>{item?.PRDWeight} kg</p>
                    </div>
                  </Col>
                  <Col sm={3} className="priceCol">
                    <div style={styles.priceWrap}>
                      {item.current_price ? (
                        <span style={styles.offerPrice} className="offerPrice">
                          ₹{" "}
                          {parseFloat(
                            item?.current_price?.current_price
                          ).toFixed(2)}
                        </span>
                      ) : (
                        <span style={styles.offerPrice} className="price">
                          ₹{parseFloat(item?.PRDPrice).toFixed(2)}
                        </span>
                      )}
                      {item.current_price && (
                        <span style={styles.price} className="price">
                          ₹ {parseFloat(item?.PRDPrice).toFixed(2)}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col sm={3} className="hide-xs hide-sm">
                    {outOfstock.length > 0 && outOfstock.includes(item?.id) ? (
                      <Button
                        style={styles.btnAddToCart}
                        size="lg"
                        disabled
                        className="btn-outOfStock"
                      >
                        {t("commonHeader.outOfStock")}
                      </Button>
                    ) : (
                      <Button
                        style={styles.btnAddToCart}
                        size="lg"
                        onClick={() => {
                          const storeName =
                            item.language_data && item?.language_data.length > 0
                              ? item?.language_data[0]?.store_name
                              : undefined;
                          handleCart(
                            item.id,
                            item?.product_vendor_store?.id,
                            item.product_vendor_store?.store_name,
                            storeName,
                            item?.product_vendor_store?.logo
                          );
                        }}
                      >
                        {t("commonHeader.add")}
                      </Button>
                    )}
                    <Button
                      style={styles.btnRemove}
                      onClick={() => handleDelete(item.id)}
                    >
                      {t("userProfile.remove")}
                    </Button>
                  </Col>
                  <div className="hide-lg pt-2 show-sm">
                    {outOfstock.length > 0 && outOfstock.includes(item?.id) ? (
                      <Button
                        style={styles.btnAddToCart}
                        size="lg"
                        disabled
                        className="btn-outOfStock"
                      >
                        {t("commonHeader.outOfStock")}
                      </Button>
                    ) : (
                      <Button
                        style={styles.btnAddToCart}
                        size="lg"
                        onClick={() => {
                          const storeName =
                            item?.language_data &&
                            item?.language_data?.length > 0
                              ? item?.language_data[0]?.store_name
                              : undefined;
                          handleCart(
                            item.id,
                            item?.product_vendor_store?.id,
                            item.product_vendor_store?.store_name,
                            storeName,
                            item?.product_vendor_store?.logo
                          );
                        }}
                      >
                        {t("commonHeader.add")}
                      </Button>
                    )}
                    <Button
                      style={styles.btnRemove}
                      onClick={() => handleDelete(item.id)}
                    >
                      {t("userProfile.remove")}
                    </Button>
                  </div>
                </Row>
              </div>
            );
          })
        )}
      </Section>
    </>
  );
};

export default Wishlist;
