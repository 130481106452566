import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Player } from "@lottiefiles/react-lottie-player";
import lottieNearByShop from "../../assets/lotties/verifying.json";
import { base_url } from '../../Api/ApiConst';

const VerifyAccount: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyAccount = async () => {
            try {
                const queryParams = new URLSearchParams(location.search);
                const token = queryParams.get('token');  
                const storeId = queryParams.get('store_id');

                if (token && storeId) {
                    const response = await axios.get(`${base_url}/api/v0/store/store/verify-store/?token=${token}&store_id=${storeId}`);
    
                    if (response.status === 200) {
                        // Redirect to the thank you page upon successful verification
                        navigate('/verification-thank-you');
                    } else {
                        // Handle errors or failed verification
                        console.error('Verification failed:', response.data);
                    }
                } else {
                    console.error('Invalid verification data.');
                }
            } catch (error) {
                console.error('Error during account verification:', error);
            }
        };

        verifyAccount();
    }, [location, navigate]);

    return (
        <div className="d-flex justify-content-center align-items-center h-100">
        <Player
          src={lottieNearByShop}
          loop
          autoplay
          className="player"
          style={{ height: "300px", width: "300px" }}
        />
      </div>
    );
};

export default VerifyAccount;
