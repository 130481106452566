import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  Image,
  Spinner,
  Overlay,
} from "react-bootstrap";
import { getStyles } from "./StoreReviews.style";
import TitleBar from "../../Components/TitleBar/TitleBar";
import NearByShop from "../../assets/images/shop-near-you.png";
import Section from "../../Components/Section/Section";
import shop1 from "../../assets/images/default-shop.png";
import locationIcon from "../../assets/images/location.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bookmark from "../../assets/images/supply.png";
import Dot from "../../assets/images/dot.png";
import { FaArrowRight, FaSearch, FaStar } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import {
  Get_Store_Ratings,
  Nearest_Shops,
  Store_Ratings,
  User_Store_Rating,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import noProduct from "../../assets/images/no-product.png";
import _ from "lodash";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { Link } from "react-router-dom";
import Sort from "../../assets/images/sort.png";
import Popover from "react-bootstrap/esm/Popover";
import { useTranslation } from "react-i18next";
import { base_url } from "../../Api/ApiConst";
import InputGroup from "react-bootstrap/esm/InputGroup";
import FormControl from "react-bootstrap/esm/FormControl";
import { SET_SELECTED_STORE_SUBCATEGORY } from "../../Redux/User";
import lottieNearByShop from "../../assets/lotties/nearByShops.json";
import { Player } from "@lottiefiles/react-lottie-player";
import NoDataFound from "../../Components/NoDataFound/NoDataFound";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/esm/Modal";

const StoreReviews: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const mainName = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );
  const languageId: any = useSelector((state: RootState) => state.lan);
  const [currentPage, setCurrentPage] = useState<any>(1);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const title = t("commonHeader.shopNearToYou");
  const styles = getStyles(isMobile);
  const breadcrumbs = [
    <Link to="/" key="home">
      {t("footer.home")}
    </Link>,
    "Store Review",
  ];
  const info = "Lorem ipsum";
  const imageUrl = NearByShop; // Corrected imageUrl

  interface Category {
    id: string;
    store_name: string;
    store_type: string;
    distance_in_km: string;
    language_data: any;
    logo: any;
    store_rating: any;
  }

  const [showSortPopover, setShowSortPopover] = useState(false);
  const target = useRef(null);
  const [latitude, setLatitude] = React.useState<string | null>("");
  const [longitude, setLongitude] = React.useState<string | null>("");
  const [initialStores, setInitialStores] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const selectedCatgeory: any = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );
  const [stores, setStores] = React.useState<Category[]>([]);
  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );
  const [sortBy, setSortBy] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [selectedStore, setSelectedStore] = useState<any>(null);
  // const [userRating, setUserRating] = useState(0);
  const [comment, setComment] = useState("");
  const handleShowModal = () => setShowModal(true);
  // const handleRatingClick = (rating: number) => setUserRating(rating);
  const [item, setItem] = useState<any>("");
  const [rating, setRating] = useState<number>(0);
  const [limit, setLimit] = useState<any>(9);
  const [pages, setPages] = useState<any>(""); // Track if the current view is search results
  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setLatitude(localStorage.getItem(STORAGE_CONSTANTS.LATITUDE));
    setLongitude(localStorage.getItem(STORAGE_CONSTANTS.LONGITUDE));
    window.scrollTo(0, 0);
  }, []);

  const handleSort = (item: any) => {
    setSortBy(item);
    if (item?.sortType) {
      const sortedStores: any = [...stores].sort((a: any, b: any) => {
        const itemA =
          item?.sortType === "store_name"
            ? a[item?.sortType].toLowerCase()
            : a[item?.sortType];
        const itemB =
          item?.sortType === "store_name"
            ? b[item?.sortType].toLowerCase()
            : b[item?.sortType];

        if (item?.Ascending) {
          if (itemA < itemB) return -1;
          if (itemA > itemB) return 1;
        } else {
          if (itemA > itemB) return -1;
          if (itemA < itemB) return 1;
        }
        return 0;
      });
      setStores(sortedStores);
    } else {
      setStores([...stores]?.sort((a: any, b: any) => b.id - a.id));
    }
  };

  const sortData = [
    {
      id: 1,
      sortType: "store_name",
      name: t("storeSortModal.shopNameAsc"),
      Ascending: true,
    },
    {
      id: 2,
      sortType: "store_name",
      name: t("storeSortModal.shopNameDesc"),
      Ascending: false,
    },
    // {
    //   id: 3,
    //   sortType: "distance_in_km",
    //   name: t("storeSortModal.distanceAsc"),
    //   Ascending: true,
    // },
    // {
    //   id: 4,
    //   sortType: "distance_in_km",
    //   name: t("storeSortModal.distanceDesc"),
    //   Ascending: false,
    // },
  ];

  const fetchShops = async (currentPage: any, limit: any) => {
    try {
      setLoading(true);
      const response = await Store_Ratings(currentPage, limit);
      if (response && response.status === 200) {
        // console.log(response.data.store_list.language_data[0].store_name);
        // console.log(response.data, "stores=====>");
        setPages(response.data.count);
        setStores(response.data.store_list);
        setInitialStores(response.data.store_list)
      } else {
        console.log("Error fetching Nearest Shops");
      }
    } catch (error) {
      console.log("Error fetching Nearest Shops", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShops(currentPage, limit);
  }, [currentPage]);

  const handleSubmit = () => {
    if (rating) {
      const RateProduct = async () => {
        try {
          const payload = {
            rate: rating,
            client_comment: comment,
          };
          const response = await User_Store_Rating(payload, item?.id);
          if (response && response.status === 200) {
            // console.log(response.data.store_list.language_data[0].store_name);
            console.log(response.data, "buyyyy---------->");
            // setProducts(response.data.product_list);
            // setSelectedStore(response?.data?.store_rating_list);
            toast.success(response["data"]["message"]);
            fetchShops(currentPage, limit);
            setComment("");
            handleCloseModal();
          } else {
            console.log("Error fetching Past orders");
          }
        } catch (error: any) {
          console.log("Error fetching Past orders", error);
          toast.error(error?.response?.data?.error);
        }
      };
      RateProduct();
    } else {
      toast.error("Please rate the store");
    }
  };

  const handleRate = (rate: number) => {
    console.log("rate", rate);
    setRating(rate);
  };

  const handleOpenRatingModal = (product: any) => {
    // setSelectedProduct(product);
    setShowModal(true);
    setItem(product);

    if (product?.id) {
      const OrderRating = async () => {
        try {
          const response = await Get_Store_Ratings(product?.id);
          if (response && response.status === 200) {
            // console.log(response.data.store_list.language_data[0].store_name);
            console.log(response.data, "buyyyy---------->");
            // setProducts(response.data.product_list);
            setSelectedStore(response?.data?.store_rating_list);
            setComment(response?.data?.store_rating_list[0]?.client_comment);
          } else {
            console.log("Error fetching Past orders");
            toast.error(response?.data?.error);
          }
        } catch (error) {
          console.log("Error fetching Past orders", error);
        }
      };
      OrderRating();
    }
  };

  const handleSearch = () => {
    // const searchResponse = [...products].filter((item:any) => {
    //   return item
    // })
    console.log(searchText, "searchText");
    if (searchText?.trim() !== "") {
      const filteredData = [...initialStores].filter(
        (item: Category) =>
          item.store_name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.store_type.toLowerCase().includes(searchText.toLowerCase())
        //  (item.distance_in_km).includes()(searchText)
      );
      console.log(filteredData, "filteredData=========>");
      setStores(filteredData);
    } else {
      setStores(initialStores);
      // console.log(filteredData,"filteredData=========>")
    }
  };

  const handleOverlayClose = () => {
    setShowSortPopover(false);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setComment("");
  };

  return (
    <>
      {/* <TitleBar
        breadcrumbs={breadcrumbs}
        title={title}
        imageUrl={imageUrl}
      /> */}

      <Section className="storeView" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>

          {/* <Row style={styles.searchRow}>
            <InputGroup>
              <FormControl
                type="text"
                placeholder={t("commonHeader.globalSearch")}
                className="mr-sm-2 search-input"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              // onKeyDown={handleKeyDown}
              />
              <Button
                variant="outline-primary search-btn"
                onClick={handleSearch}
              >
                <FaSearch />
              </Button>
            </InputGroup>
          </Row> */}
        </Container>
      </Section>

      <Section
        className="nearByShop"
        style={{ paddingTop: 50, paddingBottom: 50 }}
      >
        <Container>
          {/* {!loading && stores.length !== 0 && (
            <div className="d-flex align-items-center justify-content-end flex-row mb-3">
              <div ref={target}>
                <Button
                  title="Sort"
                  style={styles.btnSort}
                  variant="outline-primary"
                  onClick={() => setShowSortPopover(!showSortPopover)}
                >
                  <Image
                    src={Sort}
                    style={styles.btnSortIcon}
                    className="sortImage"
                  />
                </Button>
                <Overlay
                  show={showSortPopover}
                  target={target.current}
                  placement="bottom"
                  containerPadding={20}
                  container={document.body}
                  onHide={handleOverlayClose}
                  rootClose
                >
                  <Popover id="popover-contained">
                    <Popover.Body>
                      <ul
                        style={{ listStyleType: "none", padding: 0 }}
                        className="sortPopover"
                      >
                        {sortData &&
                          sortData.map((ele) => {
                            return (
                              <li>
                                <Button
                                  variant="link"
                                  onClick={() => handleSort(ele)}
                                >
                                  {ele?.name}
                                </Button>
                              </li>
                            );
                          })}
                      </ul>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </div>
          )} */}
          {loading && stores.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <Player
                src={lottieNearByShop}
                loop
                autoplay
                className="player"
                style={{ height: "150px", width: "150px" }}
              />
            </div>
          ) : !loading && stores.length === 0 ? (
            // <Row className="noData">
            //   <Col className="d-flex align-items-center justify-content-center">
            //     <h3 style={styles.noProduct}>
            //       <Image src={noProduct} className="mx-2" style={{ width: 32, height: 32 }} />{" "}
            //       {t("commonHeader.noShopsFoundInYourArea")}

            //     </h3>
            //   </Col>
            // </Row>
            <NoDataFound title={t("commonHeader.noShopsFoundInYourArea")} />
          ) : (
            <Row className="shopRow">
              {stores.map((item) => (
                <Col sm={4} key={item.id} style={styles.shopCol}>
                  <Row style={styles.innerRow}>
                    <div
                      className="d-flex align-items-center singleShop"
                      style={styles.singleShopWrap}
                      onClick={() => handleOpenRatingModal(item)}
                    >
                      <div
                        style={styles.shopImageWrap}
                        className="sm-shopImageWrap"
                        onClick={() => handleOpenRatingModal(item)}
                      >
                        <Image
                          src={item?.logo ? `${base_url}/${item?.logo}` : shop1}
                          style={styles.shopImage}
                        />
                      </div>

                      <div
                        className="px-2 shopInfo"
                        style={styles.left}
                        onClick={() => handleOpenRatingModal(item)}
                      >
                        <h2 style={styles.shopName} className="mb-2 shopName">
                          {item?.language_data?.length > 0
                            ? languageId === "ne"
                              ? item.language_data[0]?.store_name
                              : item.store_name
                            : item?.store_name}
                        </h2>
                        <div className="d-flex align-items-center mb-3 shopLocWrap">
                          {/* <Image
                            src={Dot}
                            style={{ width: 5, height: 5 }}
                            className="mx-2 hide-xs"
                          /> */}
                          <div>
                            <Rating
                              initialValue={item?.store_rating}
                              allowHover={false}
                              readonly={true}
                              size={22}
                              style={{ marginBottom: "7rem" }}
                            />
                          </div>
                        </div>

                        {/* <div>
                          <Rating
                            initialValue={item?.store_rating}
                            allowHover={false}
                            readonly={true}
                            size={22}
                            style={{ marginBottom: "7rem" }}
                          />
                        </div> */}

                        <div className="d-flex align-items-center mb-1">
                          <div
                            style={{
                              ...styles.bookmarkBg,
                              backgroundImage: `url(${bookmark})`,
                            }}
                            className="supply-method"
                          >
                            <p className="mb-0" style={styles.supplyMethod}>
                              {item?.language_data?.length > 0
                                ? languageId === "ne"
                                  ? item.language_data[0]?.store_type
                                  : item?.store_type
                                : item?.store_type}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </Col>
              ))}
            </Row>
          )}
        </Container>
        {
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            className="ratingModal "
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("userProfile.shareReview")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center mb-3">
                {/* <Image
                    src={
                      item?.product_image
                        ? `${base_url}/${item?.logo}`
                        : shop1
                    }
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 10,
                      borderRadius: 10,
                    }}
                  /> */}
                {item?.logo ? (
                  <Image
                    src={`${base_url}/${item?.logo}`}
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 10,
                      borderRadius: 10,
                    }}
                  />
                ) : (
                  <Image
                    src={shop1}
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 10,
                      borderRadius: 10,
                    }}
                  />
                )}
                <h5 className="mb-0">
                  {item?.language_data?.length > 0
                    ? languageId === "ne"
                      ? item.language_data[0]?.store_name
                      : item?.store_name
                    : item?.store_name}
                </h5>
              </div>
              <div className="mb-3">
                <Rating
                  initialValue={selectedStore && selectedStore[0]?.rate}
                  allowHover={false}
                  readonly={
                    selectedStore && selectedStore[0]?.rate ? true : false
                  }
                  size={33}
                  style={{ marginBottom: "7rem" }}
                  onClick={handleRate}
                />
              </div>
              <Form.Group controlId="reviewComment">

                {selectedStore && selectedStore[0]?.rate ? (
                   comment ? (
                    <p className="mb-2" style={{ background: "#fff", padding: 10, border: "1px solid #dee2e6", borderRadius: 10 }}>{comment}</p>
                  ): null
                ) : (
                  <> <Form.Label>{t("userProfile.comment")}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </>
                )}
              </Form.Group>
              {selectedStore && selectedStore[0]?.rate ? (
                <h6 style={{ padding: 5 }}>{t("userProfile.ratingThankYouMessage")}</h6>
              ) : (
                ""
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="btnCancel"
                onClick={handleCloseModal}
              >
                {t("deleteModal.cancel")}
              </Button>
              {selectedStore && selectedStore[0]?.rate ? (
                ""
              ) : (
                <Button
                  variant="primary"
                  className="btnBlue"
                  onClick={() => handleSubmit()}
                  disabled={
                    selectedStore && selectedStore[0]?.rate ? true : false
                  }
                >
                  {t("commonFormLabels.submit")}
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        }

        {!loading && stores.length !== 0 && (
          <div
            // style={{
            //   display: "flex",
            //   justifyContent: "space-between",
            //   float: "right",
            // }}
            className="container mt-4"
          >
            {(isSearchResult ? pages <= 10 && stores.length >= 10 : true) && (
              <nav
                aria-label="Page navigation example"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ul className="pagination">
                  <li
                    className={`page-item ${currentPage === 1 ? "disabled" : ""
                      }`}
                  >
                    <a
                      className="page-link"
                      onClick={() =>
                        setCurrentPage(Math.max(currentPage - 1, 1))
                      }
                    >
                      {t("userProfile.previous")}
                    </a>
                  </li>
                  {Array.from({ length: pages }).map((_, index) => (
                    <li
                      className={`page-item ${currentPage === index + 1 ? "active" : ""
                        }`}
                      key={index}
                    >
                      <a
                        className="page-link"
                        onClick={() => setCurrentPage(index + 1)}
                      >
                        {index + 1}
                      </a>
                    </li>
                  ))}
                  <li
                    className={`page-item ${currentPage === pages ? "disabled" : ""
                      }`}
                  >
                    <a
                      className="page-link"
                      onClick={() =>
                        setCurrentPage(Math.min(currentPage + 1, pages))
                      }
                    >
                      {t("userProfile.next")}
                    </a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        )}
      </Section>
    </>
  );
};

export default StoreReviews;
