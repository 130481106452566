import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { setLoginStatus } from '../../Redux/loginSlice';
import { CLEAR_CART } from '../../Redux/Cart';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CLEAR_IMAGE } from '../../Redux/productId';
// import { CLEAR_LATITUDE, CLEAR_LONGITUDE } from '../../Redux/Location'; 
import { STORAGE_CONSTANTS } from '../../shared/Constants/Constants';
import { CLEAR_ACTIVE_ROLE } from '../../Redux/User';
import { RestrictButton } from '../../Utils/RestrictBackButton';
import { useTranslation } from 'react-i18next';

interface LogoutModalProps {
  show: boolean;
  onClose: () => void;
  onLogout: () => void;
}

const LogoutModal: React.FC<LogoutModalProps> = ({ show, onClose, onLogout }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

 

    const handleClick = () => {
        toast.success(t("responseMessage.userLoggedOut")) 
        localStorage.clear()
        localStorage.setItem(STORAGE_CONSTANTS.IS_LOGGED_IN, 'false')
        dispatch(setLoginStatus(false));
        dispatch(CLEAR_CART())
        dispatch(CLEAR_IMAGE())
        // dispatch(CLEAR_LATITUDE())
        // dispatch(CLEAR_LONGITUDE())
        dispatch(CLEAR_ACTIVE_ROLE())
        navigate('/', { replace: true });
      }
      
  return (
    <Modal show={show} onHide={onClose} centered className="logoutModal">
      <Modal.Header closeButton>
        <Modal.Title>{t("userProfile.logout")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("userProfile.logoutInfo")}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} className='btnCancel'>
        {t("userProfile.cancel")}
        </Button>
        <Button variant="primary" onClick={handleClick} className='btnBlue'>
        {t("userProfile.logout")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutModal;
