import React, { useEffect, useState } from "react";
import styles from "./StoreReviews.style";
import Section from "../Section/Section";
import {
  Col,
  Container,
  Row,
  Card,
  Image,
  Spinner,
  Modal,
  Form,
  Button,
} from "react-bootstrap";
import Heading from "../Heading/Heading";
import bookmark from "../../assets/images/bookmark.png";
import shop1 from "../../assets/images/default-shop.png";
// import CIcon from "@coreui/icons-react";
// import * as icon from "@coreui/icons";
import locationIcon from "../../assets/images/location.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Get_Store_Ratings,
  Nearest_Shops,
  Store_Ratings,
  User_Store_Rating,
} from "../../Api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaStar } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import noProduct from "../../assets/images/no-product.png";
import { base_url } from "../../Api/ApiConst";
import { useTranslation } from "react-i18next";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { SET_SELECTED_STORE_SUBCATEGORY } from "../../Redux/User";
import lottieNearByShop from "../../assets/lotties/nearByShops.json";
import { Player } from "@lottiefiles/react-lottie-player";
import NoDataFound from "../NoDataFound/NoDataFound";
import { Item } from "react-bootstrap/lib/Breadcrumb";
import storeImage from "../../assets/images/default-shop.png";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 1901 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1900, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};

interface Category {
  id: string;
  store_name: string;
  store_type: string;
  distance_in_km: string;
  language_data: any;
  store_rating: any;
  logo: string;
}

const StoreReviews: React.FC = () => {
  const { t } = useTranslation();

  const [latitude, setLatitude] = React.useState<string | null>("");
  const [longitude, setLongitude] = React.useState<string | null>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [rating, setRating] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(8);
  // const [mainId,setMainId] = React.useState<string | null>("");
  const selectedCatgeory: any = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );
  const [stores, setStores] = React.useState<Category[]>([]);
  const languageId: any = useSelector((state: RootState) => state.lan);
  const navigate = useNavigate();
  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedStore, setSelectedStore] = useState<any>(null);
  // const [userRating, setUserRating] = useState(0);
  const [comment, setComment] = useState("");
  const handleShowModal = () => setShowModal(true);
  // const handleRatingClick = (rating: number) => setUserRating(rating);
  const [item, setItem] = useState<any>("");

  const fetchShops = async (currentPage: any, limit: any) => {
    try {
      setLoading(true);
      const response = await Store_Ratings(currentPage, limit);
      if (response && response.status === 200) {
        // console.log(response.data.store_list.language_data[0].store_name);
        // console.log(response.data, "stores=====>");
        setStores(response.data.store_list);
      } else {
        console.log("Error fetching Nearest Shops");
      }
    } catch (error) {
      console.log("Error fetching Nearest Shops", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShops(currentPage, limit);
  }, [currentPage]);

  const handleSubmit = () => {
    if (rating) {
      const RateProduct = async () => {
        try {
          const payload = {
            rate: rating,
            client_comment: comment,
          };
          const response = await User_Store_Rating(payload, item?.id);
          if (response && response.status === 200) {
            // console.log(response.data.store_list.language_data[0].store_name);
            console.log(response.data, "buyyyy---------->");
            // setProducts(response.data.product_list);
            // setSelectedStore(response?.data?.store_rating_list);
            toast.success(response["data"]["message"]);
            fetchShops(currentPage, limit);
            handleCloseModal();
          } else {
            console.log("Error fetching Past orders");
          }
        } catch (error: any) {
          console.log("Error fetching Past orders", error);
          toast.error(error?.response?.data?.error);
        }
      };
      RateProduct();
    } else {
      toast.error("Please rate the store");
    }
  };

  const handleRate = (rate: number) => {
    console.log("rate", rate);
    setRating(rate);
  };

  const handleOpenRatingModal = (product: any) => {
    // setSelectedProduct(product);
    setShowModal(true);
    setItem(product);

    if (product?.id) {
      const OrderRating = async () => {
        try {
          const response = await Get_Store_Ratings(product?.id);
          if (response && response.status === 200) {
            // console.log(response.data.store_list.language_data[0].store_name);
            console.log(response.data, "buyyyy---------->");
            // setProducts(response.data.product_list);
            setSelectedStore(response?.data?.store_rating_list);
            setComment(response?.data?.store_rating_list[0]?.client_comment);
          } else {
            console.log("Error fetching Past orders");
            toast.error(response?.data?.error);
          }
        } catch (error) {
          console.log("Error fetching Past orders", error);
        }
      };
      OrderRating();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setComment("");
  };

  console.log(item, "item============>");

  return (
    <>
      {stores.length > 0 && (
        <Section className="near mb-5">
          <Container>
            <Row className="inner-wrap">
              <Col className="align-items-center justify-content-between d-flex">
                <Heading
                  text={t("userProfile.storeReviews")}
                  alignment="left"
                />
                {!loading && stores.length >= 3 && (
                  <Link style={styles.viewAll} to="/store-reviews">
                    {t("commonHeader.viewAll")}
                    {/* View All  */}
                  </Link>
                )}
              </Col>
            </Row>
            {loading && (
              <Row>
                <Col className="d-flex align-items-center justify-content-center">
                  <Player
                    src={lottieNearByShop}
                    loop
                    autoplay
                    className="player"
                    style={{ height: "150px", width: "150px" }}
                  />
                </Col>
              </Row>
            )}
            {!loading && stores.length === 0 && (
              <NoDataFound title="No Shops found in your area" />
            )}
            {!loading && stores.length !== 0 && (
              <Row>
                <Col>
                  <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true}
                    infinite={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {stores &&
                      stores.map((item) => (
                        <div
                          className="d-flex align-items-center singleShop"
                          style={styles.singleShopWrap}
                          key={item.id}
                          onClick={() => handleOpenRatingModal(item)}
                        >
                          <div
                            style={styles.shopImageWrap}
                            className="shopImgWrap"
                            onClick={() => handleOpenRatingModal(item)}
                          >
                            {item?.logo ? (
                              <Image
                                src={`${base_url}/${item?.logo}`}
                                style={styles.shopImage}
                              />
                            ) : (
                              <Image src={shop1} style={styles.shopImage} />
                            )}
                          </div>
                          <div className="px-2 shopInfo">
                            <h2
                              style={styles.shopName}
                              className="mb-1 shopName"
                              onClick={() => handleOpenRatingModal(item)}
                            >
                              {item?.language_data?.length > 0
                                ? languageId === "ne"
                                  ? item.language_data[0]?.store_name
                                  : item.store_name
                                : item?.store_name}
                            </h2>
                            <div className="d-flex align-items-center mb-3 loc-wrap">
                              {/* <h3 style={styles.shopLocation} className="mb-0 d-flex align-items-center">
                          <Image src={locationIcon} style={styles.locationIcon} className="mx-1" />
                          Location
                        </h3> */}
                              <div>
                                <Rating
                                  initialValue={item?.store_rating}
                                  allowHover={false}
                                  readonly={true}
                                  size={22}
                                  style={{ marginBottom: "7rem" }}
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center mb-1 dist-supply">
                              {/* <h3 style={styles.shopDistance} className="mb-0">
                                {item.distance_in_km}Km
                              </h3> */}
                              <div
                                className="supply-wrap"
                                style={{
                                  ...styles.bookmarkBg,
                                  backgroundImage: `url(${bookmark})`,
                                }}
                              >
                                <p className="mb-0" style={styles.supplyMethod}>
                                  {item?.language_data?.length > 0
                                    ? languageId === "ne"
                                      ? item.language_data[0]?.store_type
                                      : item?.store_type
                                    : item?.store_type}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Carousel>
                </Col>
              </Row>
            )}
          </Container>
        </Section>
      )}
      {
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          className="ratingModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("userProfile.shareReview")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center mb-3">
              {/* <Image
                    src={
                      item?.product_image
                        ? `${base_url}/${item?.logo}`
                        : shop1
                    }
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 10,
                      borderRadius: 10,
                    }}
                  /> */}
              {item?.logo ? (
                <Image
                  src={`${base_url}/${item?.logo}`}
                  style={{
                    width: 50,
                    height: 50,
                    marginRight: 10,
                    borderRadius: 10,
                  }}
                />
              ) : (
                <Image
                  src={shop1}
                  style={{
                    width: 50,
                    height: 50,
                    marginRight: 10,
                    borderRadius: 10,
                  }}
                />
              )}
              <h5 className="mb-0">
                {item?.language_data?.length > 0
                  ? languageId === "ne"
                    ? item.language_data[0]?.store_name
                    : item.store_name
                  : item?.store_name}
              </h5>
            </div>
            <div className="mb-3">
              <Rating
                initialValue={selectedStore && selectedStore[0]?.rate}
                allowHover={false}
                readonly={
                  selectedStore && selectedStore[0]?.rate ? true : false
                }
                size={33}
                style={{ marginBottom: "7rem" }}
                onClick={handleRate}
              />
            </div>
            <Form.Group controlId="reviewComment">

            {selectedStore && selectedStore[0]?.rate ? (
               comment ? (
                <p className="mb-2" style={{ background: "#fff", padding: 10, border: "1px solid #dee2e6", borderRadius: 10 }}>{comment}</p>
              ): null
                ) : (
                  <> 
                   <Form.Label>{t("userProfile.comment")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
                  </>
                )}


             
            </Form.Group>
            {selectedStore && selectedStore[0]?.rate ? (
              <h6 style={{ padding: 5 }}>{t("userProfile.ratingThankYouMessage")}</h6>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="btnCancel"
              onClick={handleCloseModal}
            >
              {t("deleteModal.cancel")}
            </Button>
            {selectedStore && selectedStore[0]?.rate ? (
              ""
            ) : (
              <Button
                variant="primary"
                className="btnBlue"
                onClick={() => handleSubmit()}
                disabled={
                  selectedStore && selectedStore[0]?.rate ? true : false
                }
              >
                {t("commonFormLabels.submit")}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      }
    </>
  );
};

export default StoreReviews;
