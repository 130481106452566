import { createSlice } from '@reduxjs/toolkit';



const dataSlice = createSlice({
  name: 'data',
  initialState: [],
  reducers: {
    mainId:(state, action)=>{
      return action.payload;
    },
  },
});

export const { mainId } = dataSlice.actions;

export default dataSlice.reducer;