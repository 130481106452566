import { CSSProperties } from "react";
import { Right } from "react-bootstrap/lib/Media";
import TitleBg from "../../assets/images/title-bg.png";

export interface Styles {
  singleShopWrap: CSSProperties;
  shopName: CSSProperties;
  shopLocation: CSSProperties;
  shopDistance: CSSProperties;
  shopRating: CSSProperties;
  supplyMethod: CSSProperties;
  shopImage: CSSProperties;
  shopImageWrap: CSSProperties;
  bookmarkBg: CSSProperties;
  locationIcon: CSSProperties;
  viewAll:CSSProperties;
  noProduct:CSSProperties;
  left:CSSProperties;
  shopCol:CSSProperties;
  innerRow : CSSProperties;
  btnSort: CSSProperties;
  btnSortIcon: CSSProperties;
  productRadio: CSSProperties;
  searchRow:CSSProperties;
  bg : CSSProperties;
}

const baseStyles: Styles = {
  searchRow: {
    marginBottom: 30,
    marginTop:30,
    maxWidth: 1142,
    marginLeft: "auto",
    marginRight: "auto",
  },
  bg: {
    backgroundImage: `url(${TitleBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: 40,
    paddingBottom: 40,
    marginBottom: 20,
  },
  singleShopWrap: {
    backgroundColor: "var(--white-color)",
    borderRadius: 15,
    padding: 0,
    position: "relative",
    marginBottom:20,
    cursor:"pointer"
  },
  noProduct:{
    fontSize: 18,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    fontFamily: "var(--primary-font-semibold)",
    backgroundColor: "var(--primary-color)",
    color:"#fff",
    padding:20,
    borderRadius:16
  },
  left:{
    marginLeft:20,
  },
  shopName: {
    fontFamily: "var(--primary-font-bold)",
    color: "var(--subheading-color)",
    fontSize: 18,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 235,
    textTransform: "capitalize"
  },
  shopLocation: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--light-text-color)",
    fontSize: 16,
  },
  shopDistance: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--light-text-color)",
    fontSize: 16,
  },
  shopRating: {
    alignItems: "center",
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--white-color)",
    fontSize: 13,
    backgroundColor: "var(--rating)",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 2,
    marginBottom:10,
    width:60,
    borderRadius: 5,
  },
  shopImage: { 
    width: "100%",
    borderRadius:13,
    objectFit:"contain",
    height:"100%"
   },
  supplyMethod: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    fontSize: 16,
    lineHeight: 1.7,
  },
  shopImageWrap: {
    overflow:"hidden",
    backgroundColor: "var(--white-color)",
    borderRadius: 15,
    width: "140px",
    height: "140px",
    borderStyle:"solid",
    borderWidth:1,
    borderColor:"#d9d9d9",
    lineHeight: 1.2,
    padding: 5,
  },
  bookmarkBg: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: 184,
    height: 28,
    textAlign: "center"
  },
  locationIcon: {
    width: 20,
    height: 20,
  },
  viewAll:{
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--primary-color)",
    fontSize: 18,
    textDecoration:"none",
    lineHeight: 2.2,
  },
  btnSort: {
    borderWidth: 0,
    backgroundColor: "var(--white-color)",
    float:"right"
  },
 
  shopCol:{
    
  },
  innerRow :{},
  productRadio:{},
  btnSortIcon:{}
};

const mobileStyles: Partial<Styles> = {
 
  shopCol :{
    width:"33.33%",
    maxWidth:"33.33%",
    paddingInline:5
  },
  singleShopWrap: {
    backgroundColor: "var(--white-color)",
    borderRadius: 15,
    position: "relative",
    marginBottom:20,
    cursor:"pointer",
    padding:0,
    display:"flex",
    flexDirection:"column"
  },
  shopName: {
    fontFamily: "var(--primary-font-bold)",
    color: "var(--subheading-color)",
    fontSize: 14,
    marginTop:10
  },
  left:{
    marginLeft:0,
    width:"100%"
  },
  shopLocation: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--light-text-color)",
    fontSize: 12,
  },
  shopDistance: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--light-text-color)",
    fontSize: 12,
  },
  supplyMethod: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    fontSize: 11,
    lineHeight: 1.5
  },
  innerRow : {
    padding:0
  },
  shopImageWrap: {
    overflow:"hidden",
    backgroundColor: "var(--white-color)",
    borderRadius: 15,
    width: "100px",
    height: "100px",
    borderStyle:"solid",
    borderWidth:1,
    borderColor:"#d9d9d9",
    lineHeight: 1.2,
    padding: 5,
  },

};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};