// import { getDetailedLocationWithLatandLong } from "api/apiService";

import { getDetailedLocationWithLatandLong } from "../Api";

export const reverseGeocode = async (
  latitude: any,
  longitude: any,
  apiKey: any
) => {
  try {
    const response: any = await getDetailedLocationWithLatandLong(
      latitude,
      longitude,
      apiKey
    );
    if (response?.data?.status === "OK") {
      const postalCode = response?.data?.results[0]?.address_components.find(
        (element: any) => element.types.includes("postal_code")
      )?.long_name;

      const localityName = response?.data?.results[0]?.address_components.find(
        (element: any) => element.types.includes("sublocality_level_1")
      )?.short_name;

      const cityName = response?.data?.results[0]?.address_components.find(
        (element: any) => element.types.includes("locality")
      )?.long_name;

      const stateName = response?.data?.results[0]?.address_components.find(
        (element: any) => element.types.includes("administrative_area_level_1")
      )?.long_name;

      const localAddress = response?.data?.results[0]?.formatted_address

      // console.log(
      //   response?.data?.results[0].formatted_address,
      //   "address==========>"
      // );

      const location = {
        lat: latitude,
        long: longitude,
        locationName: localityName ?? "",
        pincode: postalCode ?? "",
        cityName: cityName ?? "",
        state: stateName ?? "",
        address: localAddress ?? ""
      };

      return location;
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
};
