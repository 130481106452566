import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { getStyles } from "./ViewOffer.style";
import Section from "../../Components/Section/Section";
import { FaAngleRight, FaArrowRight, FaRegClock, FaSort } from "react-icons/fa";
import { All_Product_List, Create_Offers, Edit_Offer, My_Store, getOfferDetails } from "../../Api";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";


const ViewOffer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const breadcrumbs: string[] = ["Home", "Offers"];
  interface Category {
    id: string;
    product_name: string;
  }

  const [offer, setOffer] = useState("");
  const [couponType, setCouponType] = useState("");
  const [product, setProduct] = useState<any>([]);
  const [productType, setProductType] = useState("");
  const [limit, setLimit] = useState("");
  const [amount, setAmount] = useState("");
  const [percent, setPercent] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  const [sendPer, setSendPer] = useState("");
  const [storeId, setStoreId] = useState<string | null>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [errorss, setErrorss] = useState({
    limit: "",
    amount: "",
    percent: "",
  });
  const navigate = useNavigate();
  const selectedOffer = useSelector((state:RootState) => state.offer.selectedOffer);
  const languageId: any = useSelector((state: RootState) => state.lan);


  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userToken) {
      const handleAPI = async () => {
        try {
          const response = await My_Store(userToken);
          if (response && response.status === 200) {
            setStoreId(response.data.my_stores[0].id);
          } else {
            console.log("Error fetching My Store details");
          }
        } catch (error) {
          console.log("Error fetching My Store details", error);
        }
      };
      handleAPI();
    }
  }, [userToken]);

  useEffect(() => {
    if (storeId) {
      fetchStoreList();
    }
  }, [storeId]);

  const fetchStoreList = async () => {
    try {
      const response = await All_Product_List(storeId);
      if (response && response.status === 200) {
        setProduct(response.data.store_product_list);
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    }
  };

  useEffect(() => {
    if (userToken) {
      handleGetOfferDetails(userToken, selectedOffer?.id)
    }
  }, [userToken]);

  useEffect(() => {
    if (storeId) {
      fetchStoreList();
    }
  }, [storeId]);

  const handleGetOfferDetails = async (userToken:any, offerID:any) => {
    try {
      const response = await getOfferDetails(userToken,offerID);
      if (response && response.status === 200) {
        console.log(response.data.offer_details);
        const ofer = response.data.offer_details
        setOffer(ofer?.offer_type)
        setProductType(ofer?.product.id)
        setPercent(ofer?.percent)
        setAmount(ofer?.amount)
        setLimit(ofer?.limited)
        setValidFrom(ofer?.valid_from)
        setValidTo(ofer?.valid_to)
        setSendPer(ofer?.send_per)
        
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    }
  }

  

  // const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   // Handle form submission here
  //   const CreateOffer = async () => {
  //     try {
  //       const response = await Edit_Offer(
  //         userToken,
  //         storeId,
  //         offer,
  //         productType,
  //         amount,
  //         limit,
  //         percent,
  //         validFrom,
  //         validTo,
  //         sendPer
  //       );
  //       if (response && response.status === 200) {
  //         // console.log(response.data.bought_together[0]);
  //         toast.success(response["data"]["message"]);
  //         navigate("/offers");
  //       } else {
  //         console.log("Error Editing Store Product Details");
  //       }
  //     } catch (error) {
  //       console.log("Error Editing Store Product Details", error);
  //     }
  //   };
  //   CreateOffer();
  // };

  console.log(validFrom);

  return (
    <>
      <Section className="createOffer" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className="createOffer" style={{ marginTop: -30 }}>
        <div style={styles.whiteWrap}>
          <Container>
            <Form>
              <Form.Group as={Row} controlId="formOffer">
                <Form.Label column sm={12}>
                {t("offerList.offer")}
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    as="select"
                    value={offer}
                    onChange={(e) => setOffer(e.target.value)}
                  >
                    <option disabled value="">
                    {t("offerList.selectAnOffer")}
                    </option>
                    <option value="amount">{t("offerList.amount")}</option>
                    <option value="limited">{t("offerList.limited")}</option>
                    <option value="percent">{t("offerList.percent")}</option>
                    {/* Add options dynamically */}
                  </Form.Control>
                </Col>
              </Form.Group>

              {/* <Form.Group as={Row} controlId="formCouponType">
                                <Form.Label column sm={12}>
                                    Coupon Type
                                </Form.Label>
                                <Col sm={12}>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Reusable"
                                        name="couponType"
                                        value="reusable"
                                        checked={couponType === 'reusable'}
                                        onChange={(e) => setCouponType(e.target.value)}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unique"
                                        name="couponType"
                                        value="unique"
                                        checked={couponType === 'unique'}
                                        onChange={(e) => setCouponType(e.target.value)}
                                    />
                                </Col>
                            </Form.Group> */}

              <Form.Group as={Row} controlId="formProduct">
                <Form.Label column sm={12}>
                {t("offerList.selectProduct")}
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    as="select"
                    value={productType}
                    onChange={(e) => setProductType(e.target.value)}
                  >
                    <option value="">{t("offerList.selectProduct")}</option>
                    {product &&
                      product.map((item:any) => {
                        return (
                          <option value={item?.id}>{item?.language_data.length === 0
                            ? item?.product_name
                            : languageId === "ne"
                            ? item?.language_data[0]?.product_name
                            : item?.product_name}</option>
                        );
                      })}
                    {/* Add options dynamically */}
                  </Form.Control>
                </Col>
              </Form.Group>

              {offer === "limited" ? (
                <Form.Group as={Row} controlId="formLimit">
                  <Form.Label column sm={12}>
                  {t("offerList.limit")}
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type="number"
                      placeholder={t("offerList.enterLimit")}
                      value={limit}
                      onChange={(e) => setLimit(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              ) : offer === "amount" ? (
                <Form.Group as={Row} controlId="formAmount">
                  <Form.Label column sm={12}>
                  {t("offerList.amount")}
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type="number"
                      placeholder={t("offerList.enterAmount")}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              ) : offer === "percent" ? (
                <Form.Group as={Row} controlId="formPercent">
                  <Form.Label column sm={12}>
                  {t("offerList.percent")}
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type="number"
                      placeholder={t("offerList.enterPercent")}
                      value={percent}
                      onChange={(e) => setPercent(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              ) : null}
              <Form.Group as={Row} controlId="formValidFrom">
                <Form.Label column sm={12}>
                {t("offerList.validFrom")}
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type="date"
                    value={validFrom}
                    onChange={(e) => setValidFrom(e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formValidTo">
                <Form.Label column sm={12}>
                {t("offerList.validTo")}
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type="date"
                    value={validTo}
                    onChange={(e) => setValidTo(e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formSendPer">
                <Form.Label column sm={12}>
                {t("offerList.sendPer")}
                </Form.Label>
                <Col sm={12}>
                  <Form.Check
                    inline
                    type="radio"
                    label={t("offerList.firstOrderOnly")}
                    name="sendPer"
                    value="first_order"
                    checked={sendPer === "first_order"}
                    onChange={(e) => setSendPer(e.target.value)}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label={t("offerList.everyOrder")}
                    name="sendPer"
                    value="every_order"
                    checked={sendPer === "every_order"}
                    onChange={(e) => setSendPer(e.target.value)}
                  />
                </Col>
              </Form.Group>

              <Row>
                <Col xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <Button variant="primary" onClick={handleDelete} style={styles.btnRed}>
                      Delete
                    </Button> */}
                    <Button variant="primary" style={styles.btnBlue}>
                      <Link to="/edit-offer" style={{ textDecoration: "none", color: "white" ,width:"100%" ,display: "block" }}>{t("commonHeader.edit")}</Link>
                    </Button>
                    <Button variant="primary" style={styles.btnCancel}>
                    <Link to="/offers" style={{ textDecoration: "none" ,width:"100%",color:"#333" ,display: "block" }}>{t("commonHeader.cancel")}</Link>
                    </Button>

                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </Section>
    </>
  );
};

export default ViewOffer;
