import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import {
  Container,
  Form,
  Button,
  ProgressBar,
  Image,
  Modal,
} from "react-bootstrap";
import Section from "../../Components/Section/Section";
import { getStyles } from "./StoreRegister.style";
import { toast } from "react-toastify";
import { Store_BankDetails, Store_Registration, categories } from "../../Api";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FaStore } from "react-icons/fa";
import StepAccount from "../../assets/images/stepAccount.png";
import StepStore from "../../assets/images/stepStore.png";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ACTIVE_USER } from "../../Redux/User";
import { RootState } from "../../Redux/Store/Store";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { reverseGeocode } from "../../Utils/LocationFinder";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface FormData {
  businessName: string;
  ownerName: string;
  businessType: string;
  // address: string;
  // city: string;
  // pinCode: string;
  registrationNumber: string;
  email: string;
  nepaliName: string;
  nepaliAddress: string;
  nepaliCity: string;
  // contactPersonName: string;
  contactPersonNumber: any;
  // idNumber: string;
  // idNumber2: string;
  // areaPinCode: string;
  // accountHolderName: string;
  // bank: string;
  // accountNumber: string;
  // ifscCode: string;
  landMark: any;
  storeOpeningTime?: string;
  storeClosingTime?: string;
}

const StoreRegister: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const [step, setStep] = useState(1);
  interface Category {
    id: string;
    name: string;
    store_name: string;
    email: string;
    contact_number: any;
    nepaliCity: any;
  }
  const [showModal, setShowModal] = useState(false);
  const languageId: any = useSelector((state: RootState) => state.lan);

  const [formData, setFormData] = useState<FormData>({
    businessName: "",
    nepaliName: "",
    ownerName: "",
    businessType: "",
    // address: "",
    nepaliAddress: "",
    // city: "",
    // pinCode: "",
    registrationNumber: "",
    email: "",
    nepaliCity: "",
    // contactPersonName: "",
    contactPersonNumber: "",
    landMark: "",
    // storeOpeningTime: "",
    // storeClosingTime: "",
  });
  const [errorss, setErrorss] = useState<Partial<FormData>>({});
  // const [contactError,setContactError] = useState<string | number>({
  //    contactNumber : ""
  // })
  const [storeError, setStoreError] = useState<{
    store_name?: string;
    email?: string;
    contact_number?: string;
    store_type?: string;
  }>({});

  const [invalid, setInvalid] = useState<Category[]>([]);
  const [contactNumber, setContactNumber] = useState<any>("");
  const [contactPersonName, setContactPersonName] = useState<any>("");
  // const [contactPersonNumber, setContactPersonNumber] = useState<any>("");
  const [mainCategory, setMainCategory] = React.useState<any>([]);
  const [userToken, setUserToken] = useState<string | null>("");
  const [storeLat, setStoreLat] = useState<any>("");
  const [storeLong, setStoreLong] = useState<any>("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [address, setAddress] = useState<any>("");
  const libraries: any = ["places"];
  const googleMapsApiKey: any = "AIzaSyAL6MDhf-GojlHCzk-bijzrI5i4Sb99uNg";
  const [idNumber, setIdNumber] = useState<any>("");
  const [idNumber2, setIdNumber2] = useState<any>("");
  const [accountHolderName, setAccountHolderName] = useState<any>("");
  const [bank, setBank] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [pincode, setPincode] = useState<any>("");
  const [accountNumber, setAccountNumber] = useState<any>("");
  // const [errors, setErrors] = useState<any>("");
  const [idNumber1File, setIdNumber1File] = useState<File | null>(null);
  const [idNumber2File, setIdNumber2File] = useState<File | null>(null);
  const [autocomplete, setAutocomplete] = useState<
    google.maps.places.Autocomplete | any
  >(null);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [storeErrors, setStoreErrors] = useState({
    city: "",
    pincode: "",
    openingTime: "",
    closingTime: "",
    address: "",
  });
  const [openingTime, setOpeningTime] = useState<any>(null);
  const [closingTime, setClosingTime] = useState<any>(null);

  const schema = yup.object().shape({
    branch: yup.string().required("* Branch name is required"),
    // idNumber2: yup.string().required("* lastname is required"),
    // email: yup
    //   .string()
    //   .required("* Enter your email")
    //   .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, " Email id is invalid"),
    idNumber: yup.string().required("* Enter your Id"),

    ifscCode: yup
      .string()
      .required("* Enter your ifscCode")
      .matches(/^[a-zA-Z0-9\s]+$/, "Invalid code"),

    areaPinCode: yup
      .string()
      .required("* Enter your pincode")
      .matches(/^[0-9\s]{6}$/, "Invalid pincode"),

    idNumber2: yup.string().required("* Enter your Id"),

    accountNumber: yup
      .string()
      .required("* Enter your account number")
      .matches(/^[0-9]+$/, "* Account number must contain only digits"),

    bank: yup.string().required("* Please select an option"),
    accountHolderName: yup.string().required("* Enter Account Holder Name"),

    // date: yup
    //   .string()
    //   .required("* Enter your dob")
    //   .matches(
    //     /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
    //     "Date of Birth must be a valid date in the format YYYY-MM-DD"
    //   ),
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchCategories = async () => {
    try {
      const response = await categories();
      if (response && response.status === 200) {
        // console.log(response.data, "cate================>");
        setMainCategory(response.data);
      } else {
        console.log("Error fetching categories");
      }
    } catch (error) {
      console.log("Error fetching categories", error);
    }
  };

  const handleLoad = (autocompleteInstance: any) => {
    setAutocomplete(autocompleteInstance);
  };

  const handleSearchInputChange = (event: any) => {
    const newData = event.target.value;
    setAddress(newData);
    if (newData === "") {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        address: `* This field is required`,
      }));
    } else {
      setStoreErrors((storeErrors) => ({ ...storeErrors, address: "" }));
    }
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      { input: event.target.value },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
          // console.log("predictions--->", predictions);
        }
      }
    );
  };

  useEffect(() => {
    setContactNumber(localStorage.getItem(STORAGE_CONSTANTS.USER_NUMBER));
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    fetchCategories();
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let trimval = value.trimStart();
    setFormData({
      ...formData,
      [name]: trimval,
    });
    setErrorss({
      ...errorss,
      [name]: "",
    });
  };

  const handleSubmits = (e: any) => {
    e.preventDefault();
    let formIsValid = true;

    if (city === "") {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        city: `* This field is required`,
      }));
      formIsValid = false;
    }

    if (pincode === "") {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        pincode: `* This field is required`,
      }));
      formIsValid = false;
    }

    if (address === "") {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        address: `* This field is required`,
      }));
      formIsValid = false;
    }

    if (openingTime === null || isNaN(openingTime.getTime())) {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        openingTime: `* This field is required`,
      }));
      formIsValid = false;
    }

    if (
      openingTime !== null &&
      openingTime.toString() === closingTime.toString()
    ) {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        openingTime: `* Open and close timing's can not be same`,
      }));
      formIsValid = false;
    }

    if (closingTime === null || isNaN(closingTime.getTime())) {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        closingTime: `* This field is required`,
      }));
      formIsValid = false;
    }

    if (validateForm() && formIsValid) {
      const handleAPIcall = async () => {
        try {
          const response: any = await Store_Registration(
            formData,
            userToken,
            contactNumber,
            contactPersonName,
            selectedOption,
            address,
            city,
            pincode,
            storeLat,
            storeLong,
            openingTime,
            closingTime
          );
          if (response && response.status === 201) {
            dispatch(ACTIVE_USER("store"));
            toast.success(response["data"]["message"]);
            localStorage.setItem(STORAGE_CONSTANTS.USER, contactPersonName);
            localStorage.setItem(
              STORAGE_CONSTANTS.STORE_ID,
              response.data.store_id
            );
            localStorage.setItem(
              STORAGE_CONSTANTS.STORE_NAME,
              response.data.store_name
            );
            navigate("/store-dashboard");
            // console.log(response.data);
            // setStep(2);
            // navigate('/login')
          } else {
            console.log(response.data);
          }
        } catch (error: any) {
          console.log("Error occurred:", error.response.data);
          setStoreError(error.response.data.error_message);
        }
      };
      handleAPIcall();
      // console.log(formData);
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors: Partial<FormData> = {};

    // Required fields validation
    for (const key in formData) {
      if (!formData[key as keyof FormData]) {
        newErrors[key as keyof FormData] = t("responseMessage.required");
        valid = false;
      }
    }

    // Email format validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      newErrors.email = t("responseMessage.validEmail");
      valid = false;
    }

    if (!formData.businessType) {
      newErrors.businessType = t("responseMessage.selectOption");
      valid = false;
    }

    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.contactPersonNumber)) {
      newErrors.contactPersonNumber = "* Enter a valid phone number";
      valid = false;
    }

    setErrorss(newErrors);
    return valid;
  };

  const handleCity = (e: any) => {
    const newData = e.target.value;
    setCity(newData);
    if (newData === "") {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        city: `* This field is required`,
      }));
    } else {
      setStoreErrors((storeErrors) => ({ ...storeErrors, city: "" }));
    }
  };

  const handleOpening = (time: Date | null) => {
    const newData = time;
    setOpeningTime(newData);
    if (newData === null || isNaN(newData.getTime())) {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        openingTime: `* This field is required`,
      }));
    } else {
      setStoreErrors((storeErrors) => ({ ...storeErrors, openingTime: "" }));
    }
  };

  const handleClosing = (time: Date | null) => {
    const newData = time;
    setClosingTime(newData);
    if (newData === null || isNaN(newData.getTime())) {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        closingTime: `* This field is required`,
      }));
    } else {
      setStoreErrors((storeErrors) => ({ ...storeErrors, closingTime: "" }));
    }
  };

  const handlePincode = (e: any) => {
    const newData = e.target.value;
    const pincodeRegex = /^\d{5,6}$/;
    setPincode(newData);
    if (newData === "") {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        pincode: `* This field is required`,
      }));
    } else if (!pincodeRegex.test(newData)) {
      setStoreErrors((storeErrors) => ({
        ...storeErrors,
        pincode: `* Invalid Pincode`,
      }));
    } else {
      setStoreErrors((storeErrors) => ({ ...storeErrors, pincode: "" }));
    }
  };

  // const handleAddress = (e: any) => {
  //   const newData = e.target.value;
  //   const pincodeRegex = /^\d{5,6}$/;
  //   setPincode(newData);
  //   if (newData === "") {
  //     setStoreErrors((storeErrors) => ({
  //       ...storeErrors,
  //       pincode: `* This field is required`,
  //     }));
  //   } else if (!pincodeRegex.test(newData)) {
  //     setStoreErrors((storeErrors) => ({
  //       ...storeErrors,
  //       pincode: `* Invalid Pincode`,
  //     }));
  //   } else {
  //     setStoreErrors((storeErrors) => ({ ...storeErrors, pincode: "" }));
  //   }
  // };

  // const validationforBank = () => {
  //   let valid = true;
  //   const newErrors: Partial<FormData> = {};

  //   // Required fields validation
  //   for (const key in formData) {
  //     if (!formData[key as keyof FormData]) {
  //       newErrors[key as keyof FormData] = "Required";
  //       valid = false;
  //     }
  //   }

  //   // Email format validation
  //   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailPattern.test(formData.email)) {
  //     newErrors.email = "Invalid email format";
  //     valid = false;
  //   }

  //   // Phone number format validation (assuming 10 digits without formatting)
  //   const phonePattern = /^\d{10}$/;
  //   if (!phonePattern.test(formData.contactPersonNumber)) {
  //     newErrors.contactPersonNumber = "Invalid phone number format";
  //     valid = false;
  //   }

  //   // const contactNumberPattern = /^\d{10}$/;
  //   // if (!contactNumberPattern.test(contactNumber)) {
  //   //   contactNumber = "Invalid phone number format";
  //   //   valid = false;
  //   // }

  //   // Pincode format validation (assuming 6 digits without formatting)
  //   const pincodePattern = /^\d{6}$/;
  //   if (!pincodePattern.test(formData.pinCode)) {
  //     newErrors.pinCode = "Invalid pincode format";
  //     valid = false;
  //   }

  //   setErrors(newErrors);
  //   return valid;
  // };
  // console.log("Error>>>>>",handleError.contact_number)

  // const handleNextClick = (e: React.FormEvent) => {
  //   e.preventDefault();
  //     console.log("clicked")
  //   handleSubmit(e);

  // };
  const [selectedOption, setSelectedOption] = useState<string>("");

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
  };

  // Function to open modal
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Function to close modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // const handleFile1Change = (e: ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files && e.target.files[0];
  //   if (file) {
  //     setIdNumber1File(file);
  //   }
  // };

  // const handleFile2Change = (e: ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files && e.target.files[0];
  //   if (file) {
  //     setIdNumber2File(file);
  //   }
  // };

  // const handleClick = async (data: any) => {
  //   console.log(data, "data------>");
  //   const handleStore = async () => {
  //     try {
  //       const response = await Store_BankDetails(data, userToken);
  //       if (response && response.status === 200) {
  //         toast.success(response["data"]["message"]);
  //         // console.log(response);
  //         // localStorage.setItem("user", formData.contactPersonName)
  //         navigate("/set-store");
  //       } else {
  //         toast.error(response["data"]["message"]);
  //       }
  //     } catch (error: any) {
  //       // const errorObject = JSON.parse(error.message);
  //       // const storeNameError = errorObject;
  //       console.log(error);
  //     }
  //   };
  //   handleStore();
  // };

  const handlePlaceChanged = async () => {
    const details = await autocomplete.getPlace();
    console.log(details, "details=============>");
    setAddress(details.formatted_address);

    try {
      const { lat, lng } = details.geometry.location;
      const latitude = typeof lat === "function" ? lat() : null;
      const longitude = typeof lng === "function" ? lng() : null;
      // console.log(latitude, "lat----------->");

      if (details?.geometry && details?.geometry?.location) {
        handleStartReverseGeocoding(latitude, longitude, googleMapsApiKey);
      }
    } catch (error) {
      console.log("error in selectLocation--->", error);
    }
  };

  const handleStartReverseGeocoding = async (
    latitude: any,
    longitude: any,
    apiKey: any
  ) => {
    // console.log("latitude-->", latitude);
    // console.log("kkkkkkkkkk-->", apiKey);

    try {
      const location: any = await reverseGeocode(latitude, longitude, apiKey);
      // console.log(location, "loac=======>");
      if (Object.keys(location)?.length > 0) {
        setCity(location.cityName);
        setPincode(location.pincode);
        setStoreLat(location.lat);
        setStoreLong(location.long);
      }
      // setLoader(() => false);
      // navigation.goBack();
    } catch (error) {
      console.error("Error during reverse geocoding:", error);
      // setLoader(() => false);
      throw error;
    }
  };

  // console.log(address,"address========>")
  return (
    <Section
      className="storeRegistration"
      style={{ backgroundColor: "#F0F6FF", paddingTop: 50 }}
    >
      <Container className="mb-5">
        <h2 style={styles.mainTitle}>{t("storeRegister.pageTitle")}</h2>
        <ProgressBar now={(step / 2) * 100} label={`Step ${step} of 2`} />
        <div style={styles.step}>
          <div style={styles.stepWrap}>
            <div
              style={{
                backgroundColor: step === 1 ? "#075CF6" : "#075CF6",
                ...styles.stepBgWrap,
              }}
            >
              <Image src={StepStore} style={styles.stepIcon} />
            </div>
            <h3 style={styles.stepTitle}>{t("storeRegister.stepOne")}</h3>
          </div>
          <div style={styles.stepWrap}>
            <div
              style={{
                backgroundColor: step === 2 ? "#075CF6" : "#AED5FF",
                ...styles.stepBgWrap,
              }}
            >
              <Image src={StepAccount} style={styles.stepIcon} />
            </div>
            <h3 style={styles.stepTitle}>{t("storeRegister.stepTwo")}</h3>
          </div>
        </div>
      </Container>
      <div style={styles.whiteWrap}>
        <Container>
          {step === 1 && (
            <>
              <Form onSubmit={(e) => handleSubmits(e)}>
                <Form.Group controlId="businessName">
                  <Form.Label>
                    {t("storeRegister.nameOfTheBusiness")}:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="businessName"
                    value={formData.businessName}
                    onChange={handleChange}
                  />
                  {errorss.businessName && (
                    <span style={styles.error}>{errorss.businessName}</span>
                  )}
                  {storeError.store_name && (
                    <span style={styles.error}>{storeError.store_name}</span>
                  )}{" "}
                  {/* {Object.keys(storeError || {}).length > 0 && (
                    <span style={styles.error}>{storeError?.store_name?.[0]}</span>
                  )} */}
                </Form.Group>

                <Form.Group controlId="ownerName">
                  <Form.Label>{t("storeRegister.ownerName")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="ownerName"
                    value={formData.ownerName}
                    onChange={handleChange}
                  />
                  {errorss.ownerName && (
                    <span style={styles.error}>{errorss.ownerName}</span>
                  )}
                </Form.Group>
                <Form.Group controlId="contactNumber">
                  <Form.Label>{t("storeRegister.contactNumber")}</Form.Label>
                  <Form.Control
                    type="number"
                    name="contactNumber"
                    readOnly
                    defaultValue={contactNumber}
                    disabled
                  />
                  {Object.keys(storeError || {}).length > 0 && (
                    <span style={styles.error}>
                      {storeError?.contact_number?.[0]}
                    </span>
                  )}
                </Form.Group>
                <Form.Group controlId="businessType">
                  <Form.Label>{t("storeRegister.businessType")}:</Form.Label>
                  <Form.Control
                    as="select"
                    name="businessType"
                    value={formData.businessType}
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      {t("storeRegister.selectBusinessType")}
                    </option>
                    {mainCategory &&
                      mainCategory.map((item: any) => {
                        return (
                          <>
                            <option value={item.name}>{`${
                              item.language_data.length === 0
                                ? item.name
                                : languageId === "ne"
                                ? item?.language_data[0]?.name
                                : item?.name
                            } Shop`}</option>
                          </>
                        );
                      })}
                    {/* Add options for different business types */}
                  </Form.Control>
                  {errorss.businessType && (
                    <span style={styles.error}>{errorss.businessType}</span>
                  )}
                </Form.Group>

                <Form.Group controlId="supplyMethod">
                  <Form.Label>{t("storeRegister.supplyMethod")}</Form.Label>
                  <div style={styles.supplyMethod} onClick={handleOpenModal}>
                    <p style={styles.supplyMethodText}>
                      {selectedOption === "Only Pick Up"
                        ? t("storeRegister.onlyPickup")
                        : selectedOption === "Pick and Delivery"
                        ? t("storeRegister.pickAndDelivery")
                        : selectedOption === "Only Delivery"
                        ? t("storeRegister.onlyDelivery")
                        : t("storeRegister.selectSupplyMethod")}
                    </p>
                  </div>
                  {storeError.store_type && (
                    <span style={styles.error}>{storeError.store_type}</span>
                  )}
                  {/* {errorss.businessType && (
                    <span style={styles.error}>{errorss.businessType}</span>
                  )} */}
                </Form.Group>
                <Modal
                  show={showModal}
                  onHide={handleCloseModal}
                  centered
                  className="supplyMethodModal"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {t("storeRegister.supplyModalTitle")}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Check
                      type="radio"
                      id="pickup"
                      name="deliveryOption"
                      label={t("storeRegister.onlyPickup")}
                      value={"Only Pick Up"}
                      checked={selectedOption === "Only Pick Up"}
                      onChange={handleOptionChange}
                      onClick={handleCloseModal}
                    />
                    <Form.Check
                      type="radio"
                      id="pickupAndDelivery"
                      name="deliveryOption"
                      label={t("storeRegister.pickAndDelivery")}
                      value={"Pick and Delivery"}
                      checked={selectedOption === "Pick and Delivery"}
                      onChange={handleOptionChange}
                      onClick={handleCloseModal}
                    />
                    <Form.Check
                      type="radio"
                      id="delivery"
                      name="deliveryOption"
                      label={t("storeRegister.onlyDelivery")}
                      value={"Only Delivery"}
                      checked={selectedOption === "Only Delivery"}
                      onChange={handleOptionChange}
                      onClick={handleCloseModal}
                    />
                  </Modal.Body>
                </Modal>

                <Form.Group controlId="">
                  <Form.Label>{"Address"}</Form.Label>
                  <div className="locationAutoComplete">
                    <LoadScript
                      googleMapsApiKey={googleMapsApiKey}
                      libraries={libraries}
                    >
                      <Autocomplete
                        onLoad={handleLoad}
                        onPlaceChanged={handlePlaceChanged}
                      >
                        <div id="location-popup">
                          <Form.Control
                            id="location-search"
                            type="text"
                            placeholder="Search for places..."
                            className="btnWhite"
                            value={address}
                            onChange={handleSearchInputChange}
                          />
                          {storeErrors.address && (
                            <span style={styles.error}>
                              {storeErrors.address}
                            </span>
                          )}
                        </div>
                      </Autocomplete>
                    </LoadScript>
                  </div>
                </Form.Group>

                <Form.Group controlId="landMark">
                  <Form.Label>{"Landmark"}</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="landMark"
                    value={formData.landMark}
                    onChange={handleChange}
                  />
                  {errorss.landMark && (
                    <span style={styles.error}>{errorss.landMark}</span>
                  )}
                </Form.Group>

                <Form.Group controlId="cityPinCode" className="row">
                  <div className="col-md-6">
                    <Form.Label>{t("storeRegister.city")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      value={city}
                      onChange={(e) => handleCity(e)}
                      disabled
                    />
                    {storeErrors.city && (
                      <span style={styles.error}>{storeErrors.city}</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <Form.Label>{t("storeRegister.pinCode")}:</Form.Label>
                    <Form.Control
                      type="text"
                      name="pinCode"
                      value={pincode}
                      onChange={(e) => handlePincode(e)}
                      disabled
                    />
                    {storeErrors.pincode && (
                      <span style={styles.error}>{storeErrors.pincode}</span>
                    )}
                  </div>
                </Form.Group>
                <Form.Group controlId="registrationNumber">
                  <Form.Label>
                    {t("storeRegister.businessRegistrationNumber")}:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="registrationNumber"
                    value={formData.registrationNumber}
                    onChange={handleChange}
                  />
                  {errorss.registrationNumber && (
                    <span style={styles.error}>
                      {errorss.registrationNumber}
                    </span>
                  )}
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>{t("storeRegister.emailId")}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errorss.email && (
                    <span style={styles.error}>{errorss.email}</span>
                  )}
                  {storeError.email && (
                    <span style={styles.error}>{storeError.email}</span>
                  )}
                </Form.Group>
                <div className="row">
                  <Form.Group className="col-md-6" controlId="storeOpeningTime">
                    <Form.Label>
                      {t("storeRegister.storeOpeningTime")}
                    </Form.Label>
                    <DatePicker
                      selected={openingTime}
                      onChange={handleOpening}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className="form-control mx-3"
                    />
                    <br />
                    {storeErrors.openingTime && (
                      <span style={styles.error}>
                        {storeErrors.openingTime}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group className="col-md-6" controlId="storeClosingTime">
                    <Form.Label>
                      {t("storeRegister.storeClosingTime")}
                    </Form.Label>
                    <DatePicker
                      selected={closingTime}
                      onChange={handleClosing}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className="form-control  mx-3"
                    />
                    <br />
                    {storeErrors.closingTime && (
                      <span style={styles.error}>
                        {storeErrors.closingTime}
                      </span>
                    )}
                  </Form.Group>
                </div>
                <Form.Group controlId="contactPersonName">
                  <Form.Label>
                    {t("storeRegister.contactPersonName")}:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="contactPersonName"
                    value={contactPersonName}
                    onChange={(e) => setContactPersonName(e.target.value)}
                  />
                  {/* {errorss.contactPersonName && (
                    <span style={styles.error}>{errorss.contactPersonName}</span>
                  )} */}
                </Form.Group>
                <Form.Group controlId="contactPersonNumber">
                  <Form.Label>
                    {t("storeRegister.contactPersonNumber")}:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="contactPersonNumber"
                    value={formData.contactPersonNumber}
                    onChange={handleChange}
                  />
                  {errorss.contactPersonNumber && (
                    <span style={styles.error}>
                      {errorss.contactPersonNumber}
                    </span>
                  )}
                </Form.Group>
                <p style={styles.heading}>{t("product.otherLanguage")}</p>
                <Form.Group controlId="nepaliName">
                  <Form.Label>{"Nepali Name"}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="nepaliName"
                    value={formData.nepaliName}
                    onChange={handleChange}
                  />
                  {errorss.nepaliName && (
                    <span style={styles.error}>{errorss.nepaliName}</span>
                  )}
                </Form.Group>
                <Form.Group controlId="nepaliAddress">
                  <Form.Label>{"Nepali Address"}:</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="nepaliAddress"
                    value={formData.nepaliAddress}
                    onChange={handleChange}
                  />
                  {errorss.nepaliAddress && (
                    <span style={styles.error}>{errorss.nepaliAddress}</span>
                  )}
                </Form.Group>

                <Form.Group controlId="nepaliCity">
                  <Form.Label>{"Nepali City"}:</Form.Label>
                  <Form.Control
                    type="text"
                    name="nepaliCity"
                    value={formData.nepaliCity}
                    onChange={handleChange}
                  />
                  {errorss.nepaliCity && (
                    <span style={styles.error}>{errorss.nepaliCity}</span>
                  )}
                </Form.Group>
                <div className="align-items-center justify-content-center d-flex">
                  <Button
                    variant="primary"
                    className="btnBlue submit"
                    type="submit"
                  >
                    {t("commonFormLabels.submit")}
                  </Button>
                </div>
              </Form>
            </>
          )}
          {/* {step === 2 && (
            <>
              <Form>
                <Form.Group controlId="idNumber">
                  <Form.Label>{t("storeRegister.idNumber")} 1</Form.Label>
                  <Form.Control
                    type="number"
                    // name="idNumber"
                    {...register("idNumber")}
                    // value={idNumber}
                    // onChange={handleChange}
                  />
                 
                </Form.Group>
                <Form.Group controlId="idNumber1File">
                  <Form.Label>{t("storeRegister.idNumber")} 1 File</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFile1Change}
                  />
                </Form.Group>

                <Form.Group controlId="idNumber2">
                  <Form.Label>{t("storeRegister.idNumberTwo")}</Form.Label>
                  <Form.Control
                    type="number"
                    
                    {...register("idNumber2")}

                    
                  />
                 
                </Form.Group>
                <Form.Group controlId="idNumber2File">
                  <Form.Label>{t("storeRegister.idNumberTwo")} File</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFile2Change}
                  />
                </Form.Group>
                <Form.Group controlId="areaPinCode">
                  <Form.Label>{t("storeRegister.pinCodeService")}</Form.Label>
                  <Form.Control
                    type="number"
                    {...register("areaPinCode")}
                   
                  />
                  {errors.areaPinCode && (
                    <span style={styles.error}>
                      {errors.areaPinCode.message}
                    </span>
                  )}
                </Form.Group>
                <Form.Group controlId="accountHolderName">
                  <Form.Label>
                    {t("storeRegister.accountHolderName")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register("accountHolderName")}

                    
                  />
                  {errors.accountHolderName && (
                    <span style={styles.error}>
                      {errors.accountHolderName.message}
                    </span>
                  )}
                </Form.Group>
                <Form.Group controlId="bank">
                  <Form.Label>{t("storeRegister.selectBank")}</Form.Label>
                  <Form.Control
                    as="select"
                   
                    {...register("bank")}

                  >
                    <option value="">{t("storeRegister.selectBank")}</option>
                    <option value="bank1">Bank 1</option>
                    <option value="bank2">Bank 2</option>
                    <option value="bank3">Bank 3</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="accountNumber">
                  <Form.Label>{t("storeRegister.accountNumber")}</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("accountNumber")}
                   
                  />
                  {errors.accountNumber && (
                    <span style={styles.error}>
                      {errors.accountNumber.message}
                    </span>
                  )}
                </Form.Group>
                <Form.Group controlId="ifscCode">
                  <Form.Label>{t("storeRegister.ifscCode")}</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("ifscCode")}
                  />
                  {errors.ifscCode && (
                    <span style={styles.error}>{errors.ifscCode.message}</span>
                  )}
                </Form.Group>
                <Form.Group controlId="branch">
                  <Form.Label>{t("storeRegister.branch")}</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("branch")}

                   
                  />
                  {errors.branch && (
                    <span style={styles.error}>{errors.branch.message}</span>
                  )}
                </Form.Group>
                <div className="align-items-center justify-content-center d-flex">
                  <Button
                    variant="primary"
                    className="btnBlue submit"
                    onClick={handleSubmit(handleClick)}
                  >
                    {t("commonFormLabels.submit")}
                  </Button>
                </div>
              </Form>
            </>
          )} */}
        </Container>
      </div>
    </Section>
  );
};

export default StoreRegister;
