import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  // mainCategory : [],
 View_Cart : [],
 PickupAddress:[],
 SelectedPickupOptions:[]
 
};

const Cart = createSlice({
  name: 'Cart',
  initialState,
  reducers: {
    VIEW_CART:(state, action)=>{
      state.View_Cart = action.payload;
    },
    Pickup_Address:(state, action)=>{
      state.PickupAddress = action.payload;
    },
    selected_pickup:(state, action)=>{
      state.SelectedPickupOptions = action.payload;
    },
    CLEAR_CART:(state)=>{
      state.View_Cart = "";
      state.SelectedPickupOptions = []
    },
  },
});

export const { VIEW_CART,CLEAR_CART,Pickup_Address,selected_pickup } = Cart.actions;

export default Cart.reducer;
