import React, { FC, useEffect, useState } from "react";
import Section from "../Section/Section";
import styles from "./Offers.style";
import { Button, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../Heading/Heading";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import ShopImage from "../../assets/images/default-shop.png";

import {
  Add_To_Cart,
  Add_To_Wishlist,
  Top_Deals,
  User_Last_Viewed,
  View_Cart,
} from "../../Api";
import { base_url } from "../../Api/ApiConst";
import noProduct from "../../assets/images/no-product.png";
import { SET_SELECTED_PRODUCT } from "../../Redux/productId";
import { toast } from "react-toastify";
import Carousel from "react-multi-carousel";
import { VIEW_CART } from "../../Redux/Cart";
import {
  AddToWishList,
  fetchWishlist,
  handleRemoveFromWishlist,
} from "../../Utils/wishListUtils";
import { useTranslation } from "react-i18next";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import lottieOffers from "../../assets/lotties/offers.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { SET_SELECTED_STORE_SUBCATEGORY } from "../../Redux/User";
import { languageId } from "../../Redux/languageSlice";

// interface Category {
//   product?: {
//     id: string;
//     product_name: string;
//     product_image: string;
//     PRDPrice: number;
//     PRDWeight: string;
//     current_price?: {
//       discount_type: string;
//       discount_val: string;
//       current_price: string;
//     };
//     brand:string;
//   };
//   store?: {
//     id: string;
//     store_name: string;
//   };
//   language_data?: {};
// }
interface LanguageData {
  id: number;
  language_code: string;
  product_name: string;
  product_description: string;
  language: number;
  product: number;
}

interface ProductVendorStore {
  id: number;
  store_rating: number;
  language_data: {
    store_name: string;
    store_type: string;
    address: string;
    city: string;
    language: number;
  }[];
  store_name: string;
  owner_name: string;
  email: string;
  contact_number: string;
  business_registration_number: string;
  service_area_pin_code: string;
  logo: string | null;
  store_type: string;
  date_created: string;
  address: string;
  landmark: string | null;
  city: string;
  pincode: string;
  latitude: number;
  longitude: number;
  contact_person_name: string;
  contact_person_number: string;
  admission: string;
  buisness_type: string;
  opening_time: string | null;
  closing_time: string | null;
  note: string | null;
  is_verified: boolean;
  vendor_profile: number;
}

interface ProductSubcategory {
  id: number;
  language_data: {
    id: number;
    concat_name: string;
    main_category: string;
    language_code: string;
    name: string;
    language: number;
    sub_category: number;
  }[];
  name: string;
  category_image: string;
  slug: string;
  date: string;
  date_update: string;
  is_activated: boolean;
  quantity_threshold: number;
  main_category: number;
}

interface Product {
  id: string;
  product_name: string;
  product_image: string;
  PRDPrice: number;
  PRDWeight: string;
  current_price?: {
    discount_type: string;
    discount_val: number;
    current_price: number;
    current_usd_price: number;
  };
  brand: string;
  product_vendor_store?: ProductVendorStore;
  product_subcategory?: ProductSubcategory;
  variants?: any[];
  product_rating: number;
  language_data?: LanguageData[];
  product_description: string;
  minimum_qty: number;
  maximum_qty?: number;
  USDPrice: number;
  sku?: string;
  width?: number;
  height?: number;
  unit_measure: string;
  pieces: number;
  available: number;
  PRDISactive: boolean;
  PRDSlug: string;
  date: string;
  date_update: string;
}

interface Category {
  product?: Product;
  store?: {
    id: string;
    store_name: string;
  };
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 1901 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1900, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
};
const Offers: FC = () => {
  const { t, i18n } = useTranslation();
  const [latitude, setLatitude] = React.useState<string | null>("");
  const [longitude, setLongitude] = React.useState<string | null>("");
  const [userToken, setUserToken] = React.useState<string | null>("");
  const [currency, setCurrency] = useState("NPR");
  const [loading, setLoading] = useState<boolean>(true);
  const selectedCatgeory: any = useSelector(
    (state: RootState) => state.mainCategory.selectedMainCategory
  );
  const languageId: any = useSelector((state: RootState) => state.lan);

  const [products, setProducts] = useState<Category[]>([]);
  const [storeError, setStoreError] = useState<any>("");
  const wishlist: any = useSelector(
    (state: RootState) => state.User.productIdInWishList
  );
  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );
  const outOfstock = useSelector((state: RootState) => state.offer.OutOfStock);
  const userPincode = useSelector((state: RootState) => state.Location.pincode);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setLatitude(localStorage.getItem(STORAGE_CONSTANTS.LATITUDE));
    setLongitude(localStorage.getItem(STORAGE_CONSTANTS.LONGITUDE));
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    // setCategory(localStorage.getItem("mainName"))
  }, []);

  const fetchShops = async () => {
    try {
      setLoading(true);
      const payload = {
        lat: userLatitude,
        long: userLongitude,
        category: selectedCatgeory?.name,
        pincode: userPincode
      };
      const response = await Top_Deals(payload);
      if (response && response.status === 200) {
        // console.log(response.data.store_list.language_data[0].store_name);
        // console.log(response.data, "top========>");
        setProducts(response.data.data);
      } else {
        console.log("Error fetching the top picks");
      }
    } catch (error: any) {
      console.log("Error occurred:", error.response.data.error_message);
      setProducts([]);
      setStoreError(error.response.data.error_message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCatgeory?.name && userLatitude && userLongitude) {
      fetchShops();
    }
  }, [selectedCatgeory?.name, userLatitude, userLongitude]);

  const handleClick = (id: any) => {
    dispatch(SET_SELECTED_PRODUCT(id));
    User_Last_Viewed(id);
    navigate("/product-details");
  };

  const handleCart = (item: any) => {
    console.log(item, "items=====================>");
    // const handleCart = (id: any, storeId: any, store_name: any) => {
    //product?.id, item.store?.id, item.store?.store_name
    const addToCart = async () => {
      try {
        const response = await Add_To_Cart(item?.product?.id, currency, item?.product?.minimum_qty);
        if (response && response.status === 200) {
          console.log(response.data, "jhhhhhhhhhhhhhhh");
          localStorage.setItem(STORAGE_CONSTANTS.SHOP_ID, item?.store?.id);
          localStorage.setItem(
            STORAGE_CONSTANTS.ORDER_ID,
            response.data?.order_id
          );
          toast.success(response["data"]["message"]);
          dispatch(
            SET_SELECTED_STORE_SUBCATEGORY(item?.product?.product_subcategory)
          );
          const state = {
            store_name: item?.store?.store_name,
            store_rating: item?.product?.product_vendor_store?.store_rating
          };
          const Cartresponse = await View_Cart();
          if (Cartresponse && Cartresponse.status === 200) {
            // console.log(Cartresponse.data.cart_details.cart_items, "kkkkkkkkkkkk");
            dispatch(VIEW_CART(Cartresponse.data));
            // console.log(Cartresponse.data,"99999999999999")
          }
          navigate("/store", { state: state });
          // toast.success(response["data"]["message"]);
        } else {
          toast.error(response["data"]["message"]);
        }
      } catch (error: any) {
        console.log("Error occurred:", error.response.data.error_message);
      }
    };
    addToCart();
  };

  const handleWishlist = (id: any) => {
    const AddWishList = async () => {
      try {
        const response = await Add_To_Wishlist(userToken, id);
        if (response && response.status === 200) {
          console.log(response.data, "uuuuuuuuuuu");
          toast.success(response["data"]["message"]);
        } else {
          toast.error(response["data"]["message"]);
        }
      } catch (error: any) {
        console.log("Error occurred:", error.response.data.error_message);
      }
    };
    AddWishList();
  };

  useEffect(() => {
    fetchWishlist();
  }, [userToken]);

  // console.log(selectedCatgeory?.name, ">>>>>>>>>>");
  // console.log(userLatitude);
  const manageTextLength = (text: string): string => {
    if (text.length > 50) {
      let newText = text.slice(0, 45) + "...";
      return newText;
    } else {
      return text;
    }
  };
  const manageBrandTextLength = (text: string): string => {
    if (text.length > 30) {
      let newText = text.slice(0, 15) + "...";
      return newText;
    } else {
      return text;
    }
  };

  // console.log("storeError:", storeError);

  return (
    <>
      {products.length > 0 && (
        <Section className="offers mb-5">
          <Container>
            <Row
              className="inner-wrap"
              style={{ marginTop: 30, marginBottom: 20 }}
            >
              <Col className="align-items-center justify-content-between d-flex">
                <Heading
                  text={t("homeScreen.titleSectionOffers")}
                  alignment="left"
                />
                {!loading && products.length > 4 && (
                  <Link style={styles.viewAll} to="/near-by-products">
                    {t("commonHeader.viewAll")}
                  </Link>
                )}
              </Col>
            </Row>
            {loading ? (
              <Row>
                <Col className="d-flex justify-content-center align-items-center">
                  <Player
                    src={lottieOffers}
                    loop
                    autoplay
                    className="player"
                    style={{ height: "100px", width: "100px" }}
                  />
                </Col>
              </Row>
            ) : (
              <Row className="product-row">
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={"tablet"}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                  {products &&
                    products.map((item, index) => (
                      <div
                        style={styles.product}
                        className="product-card"
                        key={item.product?.id}
                      >
                        <div
                          style={styles.imageWrap}
                          className="product-image-wrap"
                        >
                          {item.product?.current_price &&
                            item?.product?.current_price?.discount_type && (
                              <div
                                style={styles.offerBadgeWrap}
                                className="offer-badge"
                              >
                                <div style={styles.arrowRight}></div>
                                <p style={styles.text} className="mb-0">
                                  {item.product.current_price.discount_type ===
                                    "amount"
                                    ? `${item?.product?.current_price?.discount_val} Rs`
                                    : `${item?.product?.current_price?.discount_val} %`}
                                  <br /> OFF
                                </p>
                              </div>
                            )}
                          <Image
                            src={`${base_url}/${item?.product?.product_image}`}
                            style={styles.productImage}
                            className="product-image"
                            onClick={() => handleClick(item?.product?.id)}
                          />
                          <div style={styles.heartImageWrap}>
                            <FontAwesomeIcon
                              icon={
                                wishlist.includes(item?.product?.id)
                                  ? faHeart
                                  : faHeartOutline
                              }
                              onClick={() => {
                                {
                                  userToken
                                    ? wishlist.includes(item?.product?.id)
                                      ? handleRemoveFromWishlist(
                                        item?.product?.id
                                      )
                                      : AddToWishList(item?.product?.id)
                                    : toast.error(
                                      "Login to add products to wishlist"
                                    );
                                }
                              }}
                            />
                          </div>
                        </div>

                        {/* <div
                          style={styles.productContent}
                          className="productContent"
                        >
                          <h2
                            className="product-name"
                            style={styles.productName}
                            onClick={() => handleClick(item.product?.id)}
                          >
                            {manageTextLength(item.product?.product_name || "")}
                          </h2>
                          {item.product?.PRDWeight ? (
                            <h3 style={styles.quantity}>
                              {item.product.PRDWeight} kg
                            </h3>
                          ) : (
                            <h3 style={{ minHeight: 20 }}></h3>
                          )}
                          <div style={styles.priceWrap}>
                            {item.product?.current_price ? (
                              <span
                                style={styles.offerPrice}
                                className="offerPrice"
                              >
                                ₹
                                {parseFloat(
                                  item.product?.current_price.current_price
                                ).toFixed(2)}
                              </span>
                            ) : (
                              <span style={styles.offerPrice} className="price">
                                ₹{item.product?.PRDPrice}
                              </span>
                            )}
                            {item.product?.current_price && (
                              <span style={styles.price} className="price">
                                ₹
                                {
                                  parseFloat(
                                    item?.product?.PRDPrice.toFixed(2)
                                  ) as unknown as string
                                }
                              </span>
                            )}
                          </div>
                          <div className="d-grid gap-2">
                            {outOfstock.length > 0 &&
                            outOfstock.includes(item?.product?.id) ? (
                              <Button
                                style={styles.btnAdd}
                                size="lg"
                                disabled
                                className="btn-outOfStock"
                              >
                                {t("commonHeader.outOfStock")}
                              </Button>
                            ) : (
                              <Button
                                style={styles.btnAdd}
                                size="lg"
                                onClick={() => handleCart(item)}
                              >
                                {t("commonHeader.add")}
                              </Button>
                            )}
                          </div>
                        </div> */}
                        <div
                          style={styles.productContent}
                          className="productContent"
                        >
                           <div className="d-flex align-items-center  shopInfo">
                        <div
                          className="shopImageWrap"
                          style={styles.shopIconWrap}
                        >
                          <Image
                            src={
                              item.product?.product_vendor_store?.logo
                                ? `${base_url}/${item.product?.product_vendor_store?.logo}`
                                : ShopImage
                            }
                            style={styles.shopIcon}
                          />
                        </div>
                        <h2 style={styles.shopName} className="mb-0 shopName">
                          {item.product?.product_vendor_store?.language_data
                            ?.length === 0
                            ? item.product?.product_vendor_store?.store_name
                            : languageId === "ne"
                            ? item.product?.product_vendor_store
                                ?.language_data[0]?.store_name
                            : item.product?.product_vendor_store?.store_name ||
                              "Unknown Store"}
                        </h2>
                      </div>
                          <h2
                            className="product-name"
                            style={styles.productName}
                            onClick={() => handleClick(item.product?.id)}
                          >
                          {manageTextLength(
  item?.product?.language_data?.length === 0
    ? item?.product?.product_name ?? ""
    : languageId === "ne"
    ? item?.product?.language_data?.[0]?.product_name ?? ""
    : item?.product?.product_name ?? ""
)}

                          </h2>
                          <div className="d-flex align-items-center justify-content-between wt-brand">

                          {item.product?.PRDWeight ? (
                            <h3 style={styles.quantity}>
                              {item.product.PRDWeight} kg
                            </h3>
                          ) : (
                            <h3 style={{ minHeight: 20 }}></h3>
                          )}

<div>
                          {item.product?.brand && (
                            <h3 className="brandName">
                              {manageBrandTextLength(item.product.brand)}
                            </h3>
                          )}
                        </div>
                          </div>
                          <div style={styles.priceWrap}>
                            {item.product?.current_price ? (
                              <span
                                style={styles.offerPrice}
                                className="offerPrice"
                              >
                                 ₹
  {parseFloat(
    item.product?.current_price?.current_price.toString() ?? "0"
  ).toFixed(2)}
                              </span>
                            ) : (
                              <span style={styles.offerPrice} className="price">
                                ₹
                                {parseFloat(
                                  (item.product?.PRDPrice || 0).toString()
                                ).toFixed(2)}
                              </span>
                            )}
                            {item.product?.current_price && (
                              <span style={styles.price} className="price">
                                ₹
                                {
                                  parseFloat(
                                    item?.product?.PRDPrice.toFixed(2)
                                  ) as unknown as string
                                }
                              </span>
                            )}
                          </div>
                          <div className="d-grid gap-2">
                            {outOfstock.length > 0 &&
                              outOfstock.includes(item?.product?.id) ? (
                              <Button
                                style={styles.btnAdd}
                                size="lg"
                                disabled
                                className="btn-outOfStock"
                              >
                                {t("commonHeader.outOfStock")}
                              </Button>
                            ) : (
                              <Button
                                style={styles.btnAdd}
                                size="lg"
                                onClick={() => handleCart(item)}
                              >
                                {t("commonHeader.add")}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </Carousel>
              </Row>
            )}
          </Container>
        </Section>
      )}
    </>
  );
};

export default Offers;
