
import { CSSProperties } from "react";

export interface Styles {
    heading:CSSProperties;
    leftAlign:CSSProperties;
    centerAlign:CSSProperties;
    rightAlign:CSSProperties;
}

const styles: Styles = {
    heading: {
        fontFamily:'var(--primary-font-bold)',
        fontSize:24,
        color:'var(--heading-color)'
    },
    leftAlign:{textAlign:"left"},
    centerAlign:{textAlign:"center"},
    rightAlign:{textAlign:"right"}
};

export default styles;
