import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   categoryName : "Grocery",
   storesId:""
}

const storeSlice = createSlice({
  name: 'Store',
  initialState,
  reducers: {
    categoryName:(state, action)=>{
      state.categoryName = action.payload;
    },
    SET_SELECTED_STORE_ID:(state,action)=>{
      state.storesId = action.payload;
    }
  },
});

export const { categoryName,SET_SELECTED_STORE_ID } = storeSlice.actions;

export default storeSlice.reducer;