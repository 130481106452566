import { CSSProperties } from "react";
import BG from '../../assets/images/background.jpg';
import { url } from "inspector";

export interface Styles {
  leftColumnStyle: CSSProperties;
  rightColumnStyle: CSSProperties;
  imageStyle: CSSProperties;
  title: CSSProperties;
  para: CSSProperties;
  formTitle: CSSProperties;
  formLabel: CSSProperties;
  input: CSSProperties;
  terms: CSSProperties;
  bottomText: CSSProperties;
  btnBlue: CSSProperties;
  closeIcon: CSSProperties;
  error: CSSProperties;
}

const baseStyles: Styles = {
  leftColumnStyle: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    padding: "20px",
  },
  rightColumnStyle: {
    backgroundColor: "var(--primary-color)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: "20px",
  },
  imageStyle: {
    width: 274,
    height: 368,
  },
  title: {
    fontFamily: "var(--primary-font-bold)",
    fontSize: 52,
    color: "var(--white-color)",
    lineHeight: 1.2,
    marginTop: 40,
    textAlign: "center",
  },
  para: {
    fontFamily: "var(--primary-font-regular)",
    fontSize: 24,
    color: "var(--white-color)",
    lineHeight: 1.2,
    marginTop: 10,
    maxWidth: 400,
    textAlign: "center",
  },
  formTitle: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 42,
    color: "var(--black-color)",
    textAlign: "left",
    marginBottom: 50,
    marginTop: 50,
  },
  formLabel: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 24,
    color: "var(--black-color)",
    marginBottom: 20,
  },
  input: {
    backgroundColor: "var(--gray-light)",
    borderRadius: 6,
    padding: 10,
    border: "none",
    fontFamily: "var(--primary-font-medium)",
    fontSize: 18,
    marginBottom: 10,
    color: "var(--black-color)",
    width: "100%",
    maxWidth: "560px",
  },
  terms: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 12,
    lineHeight: 1.2,
    color: "var(--text-color)",
    marginTop: 10,
  },
  bottomText: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 12,
    lineHeight: 1.2,
    color: "var(--text-color)",
    marginTop: 10,
    position: "absolute",
    left: "auto",
    right: "auto",
    bottom: 10,
  },
  btnBlue: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "var(--primary-color)",
    padding: "10px 0",
    borderRadius: 16,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--white-color)",
    fontSize: 20,
    marginTop: 50,
  },
  error: {
    color: "#ea4335",
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
  },
  closeIcon: {
    marginRight: 20,
    fontSize: 30,
    color: "#fff",
    right: 20,
    top: 20,
    position: "absolute",
  },
};

const mobileStyles: Partial<Styles> = {
  formTitle: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 24,
    color: "var(--black-color)",
    textAlign: "left",
    marginBottom: 20,
    marginTop: 20,
  },
  formLabel: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 20,
    color: "var(--black-color)",
    marginBottom: 20,
  },
  leftColumnStyle: {
    backgroundImage:`url(${BG})`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    padding: "20px",
  },
  closeIcon: {
    marginRight: 20,
    fontSize: 30,
    color: "#000",
    right: 0,
    top: 20,
    position: "absolute",
  },
  btnBlue: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "var(--primary-color)",
    padding: "7px 0",
    borderRadius: 16,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--white-color)",
    fontSize: 16,
    marginTop: 30,
  },
};

export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};
