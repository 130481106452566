import { CSSProperties } from "react";
import { Right } from "react-bootstrap/lib/Media";

export interface Styles {
  productImage: CSSProperties;
  priceWrap: CSSProperties;
  offerPrice: CSSProperties;
  price: CSSProperties;
  qty: CSSProperties;
  wt: CSSProperties;
  productName: CSSProperties;
  productImageWrap: CSSProperties;
  btnAddToCart: CSSProperties;
  btnRemove: CSSProperties;
  productRow:CSSProperties;
  noProduct:CSSProperties;
  title:CSSProperties;
  btnSort :CSSProperties;
}

const baseStyles: Styles = {
  btnSort: {
    borderWidth: 0,
    backgroundColor: "var(--white-color)",
    float:"right",
    padding:0
  },
  title:{
    fontSize: 24,
    fontFamily: "var(--primary-font-bold)",
    color: "var(--text-color)",
    marginBottom:30
  },
  productRow: {
    marginBottom: 25,
    paddingBottom: 25,
    borderBottomWidth: 1,
    borderBottomColor: "#D9D9D9",
    borderBottomStyle: "solid",
  },
  productImageWrap: {
    width: 146,
    height: 146,
    borderRadius: 6,
    backgroundColor: "#F5F5F5",
    padding: 10,
    marginRight:20
  },
  noProduct:{
    fontSize: 18,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    fontFamily: "var(--primary-font-semibold)",
    backgroundColor: "var(--primary-color)",
    color:"#fff",
    padding:20,
    borderRadius:16
  },
  productImage: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  priceWrap: { marginBottom: 15 },
  price: {
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
    color: "#808080",
    textDecoration: "line-through",
    marginLeft: 5,
  },
  offerPrice: {
    fontSize: 20,
    fontFamily: "var(--primary-font-bold)",
    color: "var(--text-color)",
  },
  qty: {
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
    color: "#808080",
  },
  wt: {
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
  },
  productName: {
    fontSize: 18,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
  btnAddToCart: {
    fontSize: 16,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--white-color)",
    backgroundColor: "var(--primary-color)",
    minWidth: 180,
    textAlign: "center",
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 16,
    border: 0,
  },
  btnRemove: {
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    minWidth: 180,
    textAlign: "center",
    paddingTop: 15,
    backgroundColor: "transparent",
    border: 0,
  },
 
};

const mobileStyles: Partial<Styles> = {
  btnAddToCart: {
    fontSize: 12,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    backgroundColor: "var(--white-color)",
    width:"90%",
    textAlign: "center",
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 16,
    borderColor: "var(--primary-color)",
    marginBottom:5,
  },
  productRow: {
    paddingBottom: 25,
  },
  btnRemove: {
    fontSize: 12,
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    width:"90%",
    borderWidth:1,
    borderRadius: 16,
    borderColor: "var(--primary-color)",
    textAlign: "center",
    paddingTop: 5,
    backgroundColor: "transparent",
  },
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};
