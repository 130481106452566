import { CSSProperties } from "react";
import TitleBg from "../../assets/images/title-bg.png";

export interface Styles {
  bg: CSSProperties;
  label: CSSProperties;
  whiteWrap: CSSProperties;
  error: CSSProperties;
  stepTitle: CSSProperties;
  stepWrap: CSSProperties;
  step: CSSProperties;
  stepBgWrap: CSSProperties;
  mainTitle: CSSProperties;
  stepIcon: CSSProperties;
  btnBlue: CSSProperties;
  supplyMethod: CSSProperties;
  supplyMethodText: CSSProperties;
  storeWrap: CSSProperties;
  storeImageWrap: CSSProperties;
  storeImage: CSSProperties;
  storeNameWrap: CSSProperties;
  storeName: CSSProperties;
  storeHeader: CSSProperties;
  welcome: CSSProperties;
  storeBottomHeader: CSSProperties;
  storeTopHeader: CSSProperties;
  set: CSSProperties;
  titleImage: CSSProperties;
  heading: CSSProperties;
}

const baseStyles: Styles = {
  bg: {
    backgroundImage: `url(${TitleBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: 40,
    paddingBottom: 0,
  },
  heading: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "var(--text-color)",
  },
  titleImage: {
    maxWidth: 350,
    position: "absolute",
    bottom: -20,
    right: 25,
  },
  storeTopHeader: { marginTop: 50 },
  storeBottomHeader: { marginBottom: 50 },
  welcome: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    marginBottom:0,
    marginLeft: 60,
    color: "var(--text-color)",
  },
  storeWrap: {},
  storeImageWrap: { width: 40, height: 40 },
  storeImage: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    borderRadius: 15,
  },
  storeNameWrap: {},
  storeName: {
    marginLeft: 20,
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 30,
    color: "var(--text-color)",
    marginBottom: 0,
  },
  set: {
    marginLeft: 60,
    fontFamily: "var(--primary-font-medium)",
    fontSize: 18,
    color: "var(--text-color)",
  },
  storeHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    // marginBottom: 15,
  },
  mainTitle: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 40,
    color: "var(--text-color)",
    textAlign: "center",
    marginBottom: 60,
  },
  stepBgWrap: {
    padding: 5,
    borderRadius: 100,
    width: 60,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  stepIcon: {
    width: 30,
    height: 30,
  },
  label: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 28,
    color: "var(--text-color)",
    lineHeight: 1.2,
    display: "block",
  },
  step: {
    marginTop: -40,
    display: "flex",
    justifyContent: "space-around",
  },
  stepWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  stepTitle: {
    fontSize: 18,
    fontFamily: "var(--primary-font-semibold)",
    marginTop: 20,
  },
  error: {
    color: "#EA4335",
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
  },
  whiteWrap: {
    paddingLeft: 50,
    paddingRight: 50,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingTop: 60,
    paddingBottom: 60,
  },
  btnBlue: {
    width: 700,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "var(--white-color)",
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 60,
  },
  supplyMethod: {
    background: "#F5F5F5",
    borderRadius: 6,
    padding: 7,
    paddingLeft: 16,
    paddingRight: 16,
  },
  supplyMethodText: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 18,
    color: "var(--text-color)",
    marginBottom: 0,
  },
};

const mobileStyles: Partial<Styles> = {
  bg: {
    backgroundImage: `url(${TitleBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: 10,
    paddingBottom: 0,
  },
  whiteWrap: {
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingTop: 10,
    paddingBottom: 10,
  },
 
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};