// Login.style.ts

import { CSSProperties } from "react";
import { Right } from "react-bootstrap/lib/Media";

export interface Styles {
    singleCategoryWrap:CSSProperties;
    categoryName:CSSProperties;
    categoryImgWrap: CSSProperties
    categoryImage:CSSProperties;
    noProduct:CSSProperties;
}

const styles: Styles = {
    singleCategoryWrap:{
        backgroundColor : 'var(--primary-color)',
        borderRadius:15,
        padding:20,
        // marginRight:15,
        // marginLeft:15,
        paddingTop:20,
        minHeight:271,
        paddingBottom:20
    },
    noProduct:{
        fontSize: 18,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontFamily: "var(--primary-font-semibold)",
        backgroundColor: "var(--primary-color)",
        color:"#fff",
        padding:20,
        borderRadius:16
      },
    categoryName:{
        fontFamily:'var(--primary-font-semibold)',
        color:'var(--white-color)',
        fontSize:20,
        marginBottom:20,
    },
    categoryImage:{
        width:"100%",
        height:"100%",
        overflow: "hidden",
        textAlign:"right"
    },
    
    categoryImgWrap :{
        textAlign:"right",
        // width:300,
        height:200
    }
};

export default styles;
