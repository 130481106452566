import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    Active_Tab : [],
};

export const Wishlist = createSlice({
    name: "Wishlist",
    initialState,
    reducers: {
        SELECTED_TAB: (state, action) => {
            state.Active_Tab = action.payload;
        },
        
    },
});

export const { SELECTED_TAB } = Wishlist.actions;
export default Wishlist.reducer;