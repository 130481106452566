// NoProductFoundCard.tsx
import React from 'react';
// import './NoProductFoundCard.css';
import DogImage from '../../assets/images/dog.jpg';

interface NoDataFoundProps {
  title?: string;
  message?: string;
}

const NoDataFound: React.FC<NoDataFoundProps> = ({
  title = "No Products Found",
}) => {
  return (
    <div className="no-product-card">
      <img src={DogImage} alt="No Product" className="no-product-image" />
      <h2>{title}</h2>
    </div>
  );
};

export default NoDataFound;
