import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Table,
  Overlay,
} from "react-bootstrap";
import { getStyles } from "./Offers.style";
import Section from "../../Components/Section/Section";
import { FaAngleRight, FaArrowRight, FaRegClock, FaSearch, FaSort } from "react-icons/fa";
import Search from "../../Components/Search/Search";
import shopImage from "../../assets/images/default-shop.png";
import Sort from "../../assets/images/sort.png";
import ProductImage from "../../assets/images/onion.png";
import { Link, useNavigate } from "react-router-dom";
import { My_Store, Offers_List, Store_Product_Details } from "../../Api";
import { base_url } from "../../Api/ApiConst";
import noProduct from "../../assets/images/no-product.png";
import { useDispatch, useSelector } from "react-redux";
import { SELECTED_OFFER } from "../../Redux/offer";
import Home from "../Home/Home";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Popover from "react-bootstrap/esm/Popover";
import InputGroup from "react-bootstrap/esm/InputGroup";
import FormControl from "react-bootstrap/esm/FormControl";
import { RootState } from "../../Redux/Store/Store";

const Offers: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const { t, i18n } = useTranslation();

  const breadcrumbs = [<Link to="/store-dashboard" key="home">Home</Link>, "Products"];
  const languageId: any = useSelector((state: RootState) => state.lan);


 

  interface Category {
    id: string;
    offer_type: string;
    amount: string;
    valid_to: number;
    limited: string;
    percent: string;
    product?: {
      product_name: string;
    };
    product_vendor_store?: {
      store_name: string;
    };
  }

  const [sortBy, setSortBy] = useState<string>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [storeName, setStoreName] = useState<string | null>("");
  const [storeId, setStoreId] = useState<string | null>("");
  const [product, setProduct] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [pages, setPages] = useState<any>({});
  const [businessNumber, setBusinessNumber] = useState<string | null>("");
  const [storeLogo, setStoreLogo] = useState<any>("");
  const [productName, setProductName] = useState<any>("");
  const [showSortPopover, setShowSortPopover] = useState(false);
  const [searchText, setSearchText] = useState("");
  const target = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handleSort = (criteria: string) => {
  //   // Implement your sorting logic here
  //   setSortBy(criteria);
  // };

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    setStoreName(localStorage.getItem(STORAGE_CONSTANTS.STORE_NAME));
    setStoreLogo(localStorage.getItem(STORAGE_CONSTANTS.STORE_LOGO));
    window.scrollTo(0, 0);
  }, []);

  const sortData = [
    {
      id: 1,
      Sorttype: "product_name",
      name: t("storeSortModal.productNameAsc"),
      Ascending: true,
    },
    {
      id: 2,
      Sorttype: "product_name",
      name: t("storeSortModal.productNameDesc"),
      Ascending: false,
    },
    {
      id: 3,
      Sorttype: "valid_to",
      name:t("offerSortModal.validToDateAsc"),
      Ascending: true,
    },
    {
      id: 4,
      Sorttype: "valid_to",
      name:t("offerSortModal.validToDateDesc"),
      Ascending: false,
    },
  ];

  const handleSort = (item: any) => {
    setSortBy(item);
    console.log(item);
    if (item?.name) {
      const sortedProducts: any = product.sort((a: any, b: any) => {
        const itemA =
          item.Sorttype === "valid_to"
            ? moment(a.valid_to)
            : (a?.product?.product_name).toLowerCase();
        const itemB =
          item.Sorttype === "valid_to"
            ? moment(b.valid_to)
            : (b?.product?.product_name).toLowerCase();

        if (item?.Ascending) {
          if (itemA < itemB) return -1;
          if (itemA > itemB) return 1;
        } else {
          if (itemA > itemB) return -1;
          if (itemA < itemB) return 1;
        }
        return 0;
      });

      console.log(sortedProducts[0]);

      setProduct(sortedProducts);
    } else {
      // Fallback to sorting by id if no specific key is provided
      setProduct((prev: any) => prev?.sort((a: any, b: any) => b.id - a.id));
    }
  };

  useEffect(() => {
    if (userToken) {
      fetchStoreList();
    }
  }, [userToken]);

  const fetchStoreList = async () => {
    try {
      const params={
        "page":currentPage
      }
      const response = await Offers_List(userToken,params);
      if (response && response.status === 200) {
        // console.log(response.data, "offer---------------");
        setProduct(response.data.list_of_offers);
        setPages(response.data);
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    }
  };

  const handleClick = (item: any) => {
    dispatch(SELECTED_OFFER(item));
    navigate("/view-offer");
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleBack = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleOverlayClose = () => {
    setShowSortPopover(false);
  };
  const handleSearch = () => {
    const fetchStoreList = async (params:any) => {
      try {
        const response = await Offers_List(userToken, params);
        if (response && response.status === 200) {
          // console.log(response.data, "offer---------------");
          setProduct(response.data.list_of_offers);
          setPages(response.data);
        } else {
          console.log("Error fetching Store Product Details");
        }
      } catch (error) {
        console.log("Error fetching Store Product Details", error);
      }
    };
  
    const params = {
      page: currentPage,
      ...(searchText && { product_name: searchText })
    };
  
    fetchStoreList(params);
  };
  
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  // console.log(userToken)
  return (
    <>
      <Section className="offersList" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <Row style={styles.searchRow}>
            <InputGroup>
              <FormControl
                type="text"
                placeholder={t("commonHeader.globalSearch")}
                className="mr-sm-2 search-input"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={handleKeyDown}

              />
              <Button
                variant="outline-primary search-btn"
                onClick={handleSearch}
              >
                <FaSearch />
              </Button>
            </InputGroup>
          </Row>
        </Container>
      </Section>
      <Section className="products" style={{ marginTop: -30 }}>
        <div style={styles.whiteWrap}>
          <Container>
            <div className="d-flex align-items-center justify-content-center flex-row">
              <div style={styles.shopImageWrap}>
                <Image
                  src={storeLogo ? `${base_url}${storeLogo}` : shopImage}
                  style={styles.shopImage}
                />
              </div>
              <div style={styles.shopNameWrap}>
                <h3 style={styles.shopName}>{storeName}</h3>
              </div>
            </div>
            <div className="d-flex align-items-start justify-content-between flex-row mt-3" style={{position:"relative"}}>
              <div>
                <Link to="/create-offer">
                  <Button style={styles.addBtn}>
                    {t("offerList.createOffer")}
                  </Button>
                </Link>
              </div>
              <div ref={target}>
                <Button
                  style={styles.btnSort}
                  variant="outline-primary"
                  onClick={() => setShowSortPopover(!showSortPopover)}
                >
                  <Image
                    src={Sort}
                    className="sortImage"
                    style={styles.btnSortIcon}
                  />
                </Button>
                <Overlay
                  show={showSortPopover}
                  target={target.current}
                  placement="bottom"
                  containerPadding={20}
                  container={document.body}
          onHide={handleOverlayClose}
          rootClose
                >
                  <Popover id="popover-contained">
                    {/* <Popover.Header as="h3">Sort By</Popover.Header> */}
                    <Popover.Body>
                      <ul
                        style={{ listStyleType: "none", padding: 0 }}
                        className="sortPopover"
                      >
                        {sortData &&
                          sortData.map((ele) => {
                            return (
                              <li>
                                <Button
                                  variant="link"
                                  onClick={() => handleSort(ele)}
                                >
                                  {ele?.name}
                                </Button>
                              </li>
                            );
                          })}
                      </ul>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
              {/* <Image src={Sort} className="sortImage"  style={styles.btnSortIcon}/> */}
            </div>

            <div style={styles.productTableWrap}>
              {product.length !== 0 ? (
                <Table
                  hover
                  style={styles.productTable}
                  className="productTable"
                >
                  <tbody>
                    <tr>
                      <th className="hide-xs"></th>
                      <th>{t("offerList.offerType")}</th>
                      <th>{t("offerList.product")}</th>
                      <th>{t("offerList.validTo")}</th>
                      <th>
                        {t("offerList.percent")} / <br className="hide-xs"></br>{" "}
                        {t("offerList.amount")} / <br className="hide-xs"></br>{" "}
                        {t("offerList.limited")}
                      </th>
                      <th></th>
                    </tr>
                    {product &&
                      product.map((item:any) => {
                        return (
                          <tr
                            key={item.id}
                            onClick={() => handleClick(item)}
                            style={{ cursor: "pointer" }}
                          >
                            <td className="hide-xs">
                              {" "}
                              <input
                                type="radio"
                                name="productSelect"
                                style={styles.productRadio}
                                disabled
                              />
                            </td>
                            <td>
                              <div style={styles.productInfo}>
                                {item.offer_type}
                              </div>
                            </td>
                            <td style={styles.productInfo}>
                              {item?.product?.language_data.length === 0
                                ? item?.product?.product_name
                                : languageId === "ne"
                                ? item?.product?.language_data[0]?.product_name
                                : item?.product?.product_name}
                            </td>
                            <td style={styles.productInfo}>{item.valid_to}</td>
                            <td style={styles.productInfo}>
                              {item.amount
                                ? item?.amount
                                : item?.limited
                                ? item?.limited
                                : item?.percent
                                ? item?.percent
                                : null}
                            </td>

                            <td style={styles.productInfo}>
                              <Link to="/view-offer">
                                <FaAngleRight
                                  onClick={() => handleClick(item)}
                                  style={{ cursor: "pointer", fontSize: 24 }}
                                />
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3 style={styles.noProduct}>
                    <Image
                      src={noProduct}
                      className="mx-2"
                      style={{ width: 32, height: 32 }}
                    />{" "}
                    {t("offerList.noOffersFound")}
                  </h3>
                </div>
              )}
            </div>
          </Container>
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {pages?.next !== null && (
                <Button
                  value={currentPage}
                  // disabled={pages?.next === null}
                  onClick={handleNext}
                >
                  {t("userProfile.next")}
                </Button>
              )}
            </div>
            <div>
              {pages?.prev !== null && (
                <Button
                  value={currentPage}
                  // disabled={pages?.prev === null}
                  onClick={handleBack}
                >
                  {t("userProfile.previous")}
                </Button>
              )}
            </div>
          </div> */}
        </div>
      </Section>
    </>
  );
};

export default Offers;
