// Login.style.ts

import { CSSProperties } from "react";
import BG from '../../assets/images/background.jpg';

export interface Styles {
  leftColumnStyle: CSSProperties;
  rightColumnStyle: CSSProperties;
  imageStyle: CSSProperties;
  title: CSSProperties;
  para: CSSProperties;
  formTitle: CSSProperties;
  formLabel: CSSProperties;
  input: CSSProperties;
  terms: CSSProperties;
  bottomText : CSSProperties;
  formSubtitle:CSSProperties;
  btnBlue : CSSProperties;
  error:CSSProperties;
  requestAgain:CSSProperties;
  closeIcon :CSSProperties;
}

const baseStyles: Styles = {
  leftColumnStyle: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position:"relative"
  },
  rightColumnStyle: {
    backgroundColor: "var(--primary-color)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position:"relative"
  },
  imageStyle: {
    width: 274,
    height: 368,
    // borderRadius: '50%',
  },
  closeIcon:{
    marginRight:20,
    fontSize:30,
    color:"#fff",
    right:20,
    top:20,
    position:"absolute"
  },
  title: {
    fontFamily: "var(--primary-font-bold)",
    fontSize: 52,
    color: "var(--white-color)",
    lineHeight: 1.2,
    marginTop: 40,
  },
  para: {
    fontFamily: "var(--primary-font-regular)",
    fontSize: 24,
    color: "var(--white-color)",
    lineHeight: 1.2,
    marginTop: 10,
    maxWidth: 400,
    textAlign: "center",
  },
  formTitle: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 42,
    color: "var(--black-color)",
    textAlign: "left",
    marginBottom: 50,
  },
  requestAgain:{color:"#EA4335",textDecoration:"none",cursor:"pointer"},
  formSubtitle:{
    fontFamily: "var(--primary-font-medium)",
    fontSize: 24,
    color: "var(--black-color)",
    textAlign: "left",
    marginBottom: 15,
  },
  formLabel: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 24,
    justifyContent: "center",
    color: "var(--black-color)",
    marginBottom: 20,
  },
  input: {
    backgroundColor: "var(--gray-light)",
    borderRadius: 6,
    padding: 10,
    borderWidth: 0,
    fontFamily: "var(--primary-font-medium)",
    fontSize: 18,
    color: "var(--black-color)",
    width:60,
    height:60,
    marginLeft:5,
    marginRight:5,
    textAlign:"center"
  },
  terms: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 12,
    lineHeight: 1.2,
    color : "var(--text-color)",
    marginTop:10
  },
  bottomText :{
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 12,
    lineHeight: 1.2,
    color : "var(--text-color)",
    marginTop:10,
    // position:"absolute",
    // left:"auto",
    // right:"auto",
    // bottom:10,

  },
  btnBlue: {
    width:"100%",
    textAlign:"center",
    backgroundColor: "var(--primary-color)",
    paddingTop:10,
    paddingBottom:10,
    borderRadius:16,
    fontFamily:"var(--primary-font-semibold)",
    color:"var(--white-color)",
    fontSize:20,
    marginTop:50
  },
  error :{
    color: "#EA4335",
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
  }
};

const mobileStyles: Partial<Styles> = {
  formTitle: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 24,
    color: "var(--black-color)",
    textAlign: "left",
    marginBottom: 20,
    marginTop: 20,
  },
  formLabel: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--black-color)",
    marginBottom: 20,
  },
  formSubtitle:{
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--black-color)",
    textAlign: "left",
    marginBottom: 15,
  },
  leftColumnStyle: {
    backgroundImage:`url(${BG})`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    padding: "20px",
  },
  closeIcon: {
    marginRight: 20,
    fontSize: 30,
    color: "#000",
    right: 0,
    top: 20,
    position: "absolute",
  },
  input: {
    backgroundColor: "var(--gray-light)",
    borderRadius: 6,
    padding: 10,
    borderWidth: 0,
    fontFamily: "var(--primary-font-medium)",
    fontSize: 18,
    color: "var(--black-color)",
    width:45,
    height:45,
    marginLeft:5,
    marginRight:5,
    textAlign:"center"
  },
  btnBlue: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "var(--primary-color)",
    padding: "7px 0",
    borderRadius: 16,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--white-color)",
    fontSize: 16,
    marginTop: 30,
  },
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};
