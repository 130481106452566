// Login.style.ts

import { CssStyleClass } from "@fortawesome/fontawesome-svg-core";
import { CSSProperties } from "react";

export interface Styles {
  basicInfo: CSSProperties;
  profileImageWrap: CSSProperties;
  profileImage: CSSProperties;
  info: CSSProperties;
  name: CSSProperties;
  phone: CSSProperties;
  email: CSSProperties;
  label:CSSProperties;
  value:CSSProperties;
  radioTitle:CSSProperties;
  radioInfo:CSSProperties;
  radioContent:CSSProperties;
  submitBtn:CSSProperties;
  editIconWrap:CSSProperties;
  editIcon:CSSProperties;
  deleteWrap:CSSProperties;
  deleteInfo:CSSProperties;
  deleteTitle:CSSProperties;
}

const baseStyles: Styles = {
  basicInfo: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#D9D9D9",
    borderRadius: 16,
    padding: 25,
    position:"relative"
  },
  profileImageWrap: {
    width: 120,
    height: 120,
    borderRadius: "50%", // Use '50%' to make it a circle
    overflow: "hidden", // Hide any overflow to maintain the circle shape
    marginRight: 30,
  },
  profileImage: {
    width: "100%",
    objectFit: "cover",
    height:"100%"
  },
  info: {},
  name: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "var(--text-color)",
  },
  phone: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 18,
    color: "var(--light-text-color)",
  },
  email: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 18,
    color: "var(--light-text-color)",
  },
  label:{
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--light-text-color)",
    marginBottom:8,
  },
  value:{
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--text-color)",
    marginBottom:20
  },
  radioTitle:{
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--light-text-color)",
    marginBottom:8
  },
  radioInfo:{
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--text-color)",
    marginBottom:20,
    maxWidth:600
  },
  radioContent:{
    position:"relative",
    marginLeft:15
  },
  submitBtn:{
    borderWidth:1,
    borderStyle:"solid",
    borderColor:"var(--primary-color)",
    borderRadius:16,
    paddingLeft:80,
    paddingRight:80,
    paddingTop:18,
    paddingBottom:18,
    color:"#B0B0B0",
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    marginTop:30,
    backgroundColor:"var(--white-color)"
  },
  deleteWrap:{
   borderTopWidth:1,
   borderTopColor:"#D9D9D9",
   borderTopStyle:"solid",
   marginTop:40,
   paddingTop:25
  },
  deleteInfo:{
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--text-color)",
  },
  deleteTitle:{
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "#EA4335",
    marginBottom:12
  },
  editIconWrap:{
    background:"none",
    border:0
  },
  editIcon:{
    position:"absolute",
    right:30,
    top:70
  }

}
const mobileStyles: Partial<Styles> = {
  profileImageWrap: {
    width: 80,
    height: 80,
    position:"relative",
    borderRadius: "50%", // Use '50%' to make it a circle
    overflow: "hidden", // Hide any overflow to maintain the circle shape
    margin:"auto",
    marginBottom:20
  },
  name: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 14,
    color: "var(--text-color)",
  },
  phone: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 14,
    color: "var(--light-text-color)",
  },
  email: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 14,
    color: "var(--light-text-color)",
  },
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};