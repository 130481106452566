// SubcategoryWiseProduct.tsx

import React, { useEffect, useState } from 'react';
import Subcategories from '../Subcategories/Subcategories';
import ProductList, { ProductListProps } from '../ProductList/ProductList';
import Section from '../Section/Section';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import Heading from '../Heading/Heading';
import { Product_list } from '../../Api';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/Store/Store';
import { useTranslation } from "react-i18next";
import { STORAGE_CONSTANTS } from '../../shared/Constants/Constants';
import lottieProductList from '../../assets/lotties/productList.json';
import { Player } from "@lottiefiles/react-lottie-player";

const SubcategoryWiseProduct: React.FC = (props) => {
  // Sample raw data for subcategories and products
  // const rawSubcategoryData: Record<string, string[]> = {
  //   Flour: ['Rice Flour', 'Maize Flour', 'Bengal Flour', 'Wheet Flour','Milet Flour'],
  //   Milk: ['Amul', 'Chitale', 'Katraj'],
  //   Oats: ['Suffola', 'Plain', 'Masala'],
  //   CondensedMilk: ['Smoothy', 'Milky'],
  // };

  interface Category {
    id: string;
    product_name: string;
    product_image: string;
    PRDPrice: number;
    PRDWeight: string;
    language_data: any
    product_vendor_store: any;
    store_name: string
  }

  const { t } = useTranslation();

  // State to track the selected subcategory
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>('');
  const [latitude, setLatitude] = React.useState<string | null>("");
  const [longitude, setLongitude] = React.useState<string | null>("");
  const [productData, setProductData] = React.useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userLatitude = useSelector((state:RootState) => state.Location.selected_latitude)
  const userLongitude = useSelector((state:RootState) => state.Location.selected_longitude)
  const userPincode = useSelector((state: RootState) => state.Location.pincode);
 
  useEffect(() => {
    setLatitude(localStorage.getItem(STORAGE_CONSTANTS.LATITUDE))
    setLongitude(localStorage.getItem(STORAGE_CONSTANTS.LONGITUDE))
  }, []);

  // Handler function to update the selected subcategory
  const handleSubcategorySelect = (subcateId: string) => {
    setSelectedSubcategory(subcateId);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Set loading to true when fetching starts

      try {
        const response = await Product_list(selectedSubcategory, userLatitude, userLongitude,userPincode);
        if (response && response.status === 200) {
          // console.log(response.data,"hiiiiiiiiiiiii");
          setProductData(response.data.product_list)

        } else {
          console.log("Error while fetching Products");
        }
      } catch (error) {
        console.log("Error while fetching Products", error);
      } finally {
        setLoading(false); // Set loading to false when fetching finishes
      }
    };

    if (selectedSubcategory) {
      fetchProducts();
    }
  }, [selectedSubcategory, userLatitude, userLongitude]);

  // Define products based on the selected subcategory
  const products: Category[] = selectedSubcategory ? productData : []



  return (
    <Section className="subcateoryWiseProduct">
      <Container>
        <Row style={{ marginTop: 30, marginBottom: 20 }}>
          <Col><Heading text={t("homeScreen.titleSectionProducts")} alignment="left" /></Col>
        </Row>
        <Row className="row-subcateoryWiseProduct">
          <Col sm={3} style={{
            borderRightWidth: 1,
            borderRightStyle: "solid", paddingRight: 0,
            borderRightColor: "var(--gray-light)"
          }}>
            <Subcategories onSubcategorySelect={handleSubcategorySelect} />
          </Col>
          <Col sm={9} >
            {loading ? (
              <div className="d-flex justify-content-center align-items-center h-100">
             <Player
                  src={lottieProductList}
                  loop
                  autoplay
                  className="player"
                  style={{ height: '300px' }}
                />
              </div>
            ) : (
              <ProductList selectedSubcategory={selectedSubcategory} products={products} />
            )}
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default SubcategoryWiseProduct;
