import { CSSProperties } from "react";
import { Right } from "react-bootstrap/lib/Media";

export interface Styles {
  singleShopWrap: CSSProperties;
  shopName: CSSProperties;
  shopLocation: CSSProperties;
  shopDistance: CSSProperties;
  shopRating: CSSProperties;
  supplyMethod: CSSProperties;
  shopImage: CSSProperties;
  shopImageWrap: CSSProperties;
  bookmarkBg: CSSProperties;
  locationIcon: CSSProperties;
  viewAll:CSSProperties;
  noProduct:CSSProperties
}

const styles: Styles = {
  singleShopWrap: {
    backgroundColor: "var(--gray-light)",
    borderRadius: 15,
    padding: 20,
    marginRight: 40,
    position: "relative",
    cursor: "pointer"
  },
  noProduct:{
    fontSize: 18,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    fontFamily: "var(--primary-font-semibold)",
    backgroundColor: "var(--primary-color)",
    color:"#fff",
    padding:20,
    borderRadius:16
  },
  shopName: {
    fontFamily: "var(--primary-font-bold)",
    color: "var(--subheading-color)",
    fontSize: 18,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 235,
    textTransform:"capitalize"
  },
  shopLocation: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--light-text-color)",
    fontSize: 16,
  },
  shopDistance: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--light-text-color)",
    fontSize: 16,
  },
  shopRating: {
    alignItems: "center",
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--white-color)",
    fontSize: 13,
    backgroundColor: "var(--rating)",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 5,
    marginLeft: 10,
  },
  shopImage: { width: "100%",height:"100%", objectFit:"contain" },
  supplyMethod: {
    fontFamily: "var(--primary-font-medium)",
    color: "var(--text-color)",
    fontSize: 12,
    lineHeight: 2.2,
  },
  shopImageWrap: {
    backgroundColor: "var(--white-color)",
    borderRadius: 15,
    width: 118,
    height: 118,
    lineHeight: 1.2,
    padding: 5,
    overflow:"hidden"
  },
  bookmarkBg: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: 150,
    height: 27,
    textAlign: "center",
    marginLeft: 10,
    position: "absolute",
    right: -2,
  },
  locationIcon: {
    width: 20,
    height: 20,
  },
  viewAll:{
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--primary-color)",
    fontSize: 18,
    textDecoration:"none",
    lineHeight: 2.2,
  }
};

export default styles;
