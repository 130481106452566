import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    selectedOffer : {},
    OutOfStock:[]
   
};

export const offer = createSlice({
    name: "offer",
    initialState,
    reducers: {
        SELECTED_OFFER: (state, action) => {
            state.selectedOffer = action.payload;
        },
        PRODUCT_OUTOFSTOCK: (state, action) => {
            state.OutOfStock = action.payload;
        },
        
    },
});


export const { SELECTED_OFFER,PRODUCT_OUTOFSTOCK } = offer.actions;
export default offer.reducer;