import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Image } from "react-bootstrap";
import noProduct from "../../assets/images/no-product.png";
import ShopImage from "../../assets/images/default-shop.png";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./PeopleAlsoBought.style";
import { base_url } from "../../Api/ApiConst";
import { useDispatch, useSelector } from "react-redux";

import productId, { SET_SELECTED_PRODUCT } from "../../Redux/productId";
import { useNavigate } from "react-router-dom";
import {
  Add_To_Cart,
  Add_To_Wishlist,
  Delete_Wishlist,
  Product_Details,
  User_Last_Viewed,
  View_Cart,
  View_Wishlist,
} from "../../Api";
import { toast } from "react-toastify";
import { VIEW_CART } from "../../Redux/Cart";
import {
  SET_PRODUCT_ID_IN_WISHLIST,
  SET_SELECTED_STORE_SUBCATEGORY,
} from "../../Redux/User";
import {
  AddToWishList,
  fetchWishlist,
  handleRemoveFromWishlist,
} from "../../Utils/wishListUtils";
import { RootState } from "../../Redux/Store/Store";
import Heading from "../Heading/Heading";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  largeDesktop: {
    breakpoint: { max: 3000, min: 1901 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1900, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};
const PeopleAlsoBought: React.FC = () => {
  const { t, i18n } = useTranslation();

  const languageId: any = useSelector((state: RootState) => state.lan);
  const [userToken, setUserToken] = React.useState<string | null>("");
  const [currency, setCurrency] = useState("NPR");
  const [products, setProducts] = useState<any[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wishlist: any = useSelector(
    (state: RootState) => state.User.productIdInWishList
  );
  const userPincode = useSelector((state: RootState) => state.Location.pincode);

  const userLatitude = useSelector(
    (state: RootState) => state.Location.selected_latitude
  );
  const userLongitude = useSelector(
    (state: RootState) => state.Location.selected_longitude
  );
  const product_id = useSelector(
    (state: RootState) => state.productId.selected_product_id
  );
  const outOfstock = useSelector((state: RootState) => state.offer.OutOfStock);

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    fetchStoreList();
  }, []);

  useEffect(() => {
    fetchWishlist();
  }, [userToken]);

  const fetchStoreList = async () => {
    try {
      const response = await Product_Details(
        product_id,
        userLatitude,
        userLongitude,
        userPincode
      );
      if (response && response.status === 200) {
        console.log(response.data, "jjjjjjjjjjjjjjjjjjj");
        // let data = response.data.bought_together[0];
        setProducts(response.data.also_bought);
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    }
  };

  //   let products = [
  //     {
  //         "id": 129,
  //         "product_name": "tomato",
  //         "store_name": "Rushii",
  //         "product_image": "/media/products/imgs/tomato.png",
  //         "PRDPrice": 30.0,
  //         "USDPrice": 1.0,
  //         "unit_measure": "2kg",
  //         "PRDWeight": "10.000",
  //         "current_price": 20.00,
  //         "variants": [],
  //         "product_rating": 0,
  //         "language_data": []
  //     }
  // ];

  // if (products.length === 0) {
  //   return (
  //     <div className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
  //       <h3 style={styles.noProduct}>
  //         <Image src={noProduct} className="mx-2" style={{ width: 32, height: 32 }} /> No products found in your area</h3>
  //     </div>
  //   );
  // }

  const handleClick = (id: any) => {
    dispatch(SET_SELECTED_PRODUCT(id));
    {userToken && User_Last_Viewed(id)};
    navigate("/product-details");
  };

  const handleCart = (
    id: any,
    storeId: any,
    store_name: any,
    language_data: any,
    store_logo: any,
    subcategory: any,
    store_rating: any,
    minimum: any
  ) => {
    const addToCart = async () => {
      try {
        const response = await Add_To_Cart(id, currency, minimum);
        if (response && response.status === 200) {
          // console.log(response.data, "jhhhhhhhhhhhhhhh")
          localStorage.setItem(STORAGE_CONSTANTS.SHOP_ID, storeId);
          localStorage.setItem(
            STORAGE_CONSTANTS.ORDER_ID,
            response.data?.order_id
          );

          toast.success(response["data"]["message"]);
          dispatch(SET_SELECTED_STORE_SUBCATEGORY(subcategory));
          const state = {
            store_name: store_name,
            language_data: language_data,
            store_logo: store_logo,
            store_rating: store_rating,
            storeId: storeId,
          };
          const Cartresponse = await View_Cart();
          if (Cartresponse && Cartresponse.status === 200) {
            // console.log(Cartresponse.data.cart_details.cart_items, "kkkkkkkkkkkk");
            dispatch(VIEW_CART(Cartresponse.data));
            // console.log(Cartresponse.data,"99999999999999")
          }
          navigate("/store", { state: state });
        } else {
          toast.error(response["data"]["message"]);
        }
      } catch (error: any) {
        console.log("Error occurred:", error);
      }
    };
    addToCart();
  };
  const manageBrandTextLength = (text: string): string => {
    if (text.length > 30) {
      let newText = text.slice(0, 15) + "...";
      return newText;
    } else {
      return text;
    }
  };
  const manageTextLength = (text: string): string => {
    if (text.length > 40) {
      let newText = text.slice(0, 30) + "...";
      return newText;
    } else {
      return text;
    }
  };
  const isInWishlist = (id: any) => {
    // return wishlist.includes(id);
  };

  // const handleClick = (item: any) => {
  //   localStorage.setItem("shop_id", item.id)
  //   const state = {
  //     store_name: item.store_name,
  //     store_type: item.store_type,
  //     distance_in_km: item.distance_in_km,
  //     language_data: item.language_data,
  //     store_rating: item.store_rating,
  //     logo: item.logo,
  //   };
  //   navigate('/store', { state: state });
  // }

  return (
    <>
      {products.length > 0 && (
        <div className="global-products">
          <Heading text={t("commonHeader.peopleAlsoBought")} alignment="left" />
          <Row className="mt-3">
            <Col>
              <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true}
                infinite={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                // removeArrowOnDeviceType={"tablet"}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                {products.map((product, index) => (
                  <div key={index} className="global20">
                    <div style={styles.product} className="product-card">
                      <div
                        style={{ ...styles.imageWrap, backgroundColor: "#fff" }}
                        key={product.id}
                        className="product-image-wrap "
                      >
                        <Image
                          className="product-image"
                          src={`${base_url}/${product?.product_image}`}
                          style={styles.productImage}
                          onClick={() => handleClick(product.id)}
                        />
                        <div style={styles.heartImageWrap}>
                          <FontAwesomeIcon
                            icon={
                              wishlist.includes(product?.id)
                                ? faHeart
                                : faHeartOutline
                            }
                            onClick={() => {
                              userToken
                                ? wishlist.includes(product?.id)
                                  ? handleRemoveFromWishlist(product?.id)
                                  : AddToWishList(product?.id)
                                : toast.error(
                                    "Login to add products to wishlist"
                                  );
                            }}
                          />
                        </div>
                      </div>

                      <div style={styles.productContent}>
                        <div className="d-flex align-items-center mb-2">
                          <Image
                            src={
                              product?.product_vendor_store?.logo
                                ? `${base_url}/${product?.product_vendor_store?.logo}`
                                : ShopImage
                            }
                            style={styles.shopIcon}
                          />
                          <h2 style={styles.shopName} className="mb-0 mx-2">
                            {product?.product_vendor_store?.store_name ||
                              "Unknown Store"}
                          </h2>
                        </div>
                        <h2
                          style={styles.productName}
                          className="product-name"
                          onClick={() => handleClick(product.id)}
                        >
                          {product?.language_data.length === 0
                            ? product?.product_name
                            : languageId === "ne"
                            ? product?.language_data[0]?.product_name
                            : product?.product_name}
                        </h2>
                        <div className="d-flex align-items-center justify-content-between wt-brand">

                        <h3 style={styles.quantity}>{product?.PRDWeight}kg</h3>
                        <div>
                          {product?.brand && (
                            <h3 className="brandName">
                              {manageBrandTextLength(product.brand)}
                            </h3>
                          )}
                        </div>
                        </div>
                        <div style={styles.priceWrap}>
                          {product?.current_price ? (
                            <span
                              style={styles.offerPrice}
                              className="offerPrice"
                            >
                              ₹
                              {parseFloat(
                                product?.current_price?.current_price
                              ).toFixed(2)}
                            </span>
                          ) : (
                            <span style={styles.offerPrice} className="price">
                              ₹{parseFloat(product?.PRDPrice).toFixed(2)}
                            </span>
                          )}
                          {product?.current_price && (
                            <span style={styles.price} className="price">
                              ₹{product?.PRDPrice}
                            </span>
                          )}

                          <div className="d-grid gap-2">
                            {outOfstock.length > 0 &&
                            outOfstock.includes(product?.id) ? (
                              <Button
                                style={styles.btnAdd}
                                size="lg"
                                disabled
                                className="btn-outOfStock"
                              >
                                {t("commonHeader.outOfStock")}
                              </Button>
                            ) : (
                              <Button
                                style={styles.btnAdd}
                                size="lg"
                                onClick={() => {
                                  const storeName =
                                    product.language_data &&
                                    product.language_data.length > 0
                                      ? product.language_data[0].store_name
                                      : undefined;
                                  handleCart(
                                    product?.id,
                                    product?.product_vendor_store?.id,
                                    product?.product_vendor_store?.store_name,
                                    storeName,
                                    product?.product_vendor_store?.logo,
                                    product?.product_subcategory,
                                    product?.product_vendor_store?.store_rating,
                                    product?.minimum_qty
                                  );
                                }}
                              >
                                {t("commonHeader.add")}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default PeopleAlsoBought;
