// Login.style.ts

import { CssStyleClass } from "@fortawesome/fontawesome-svg-core";
import { CSSProperties } from "react";

export interface Styles {
  addressRadio:CSSProperties;
  basicInfo: CSSProperties;
  profileImageWrap: CSSProperties;
  profileImage: CSSProperties;
  info: CSSProperties;
  name: CSSProperties;
  phone: CSSProperties;
  email: CSSProperties;
  label: CSSProperties;
  value: CSSProperties;
  radioTitle: CSSProperties;
  radioInfo: CSSProperties;
  radioContent: CSSProperties;
  submitBtn: CSSProperties;
  editIconWrap: CSSProperties;
  editIcon: CSSProperties;
  deleteWrap: CSSProperties;
  deleteInfo: CSSProperties;
  addressEditIconWrap: CSSProperties;
  deleteTitle: CSSProperties;
  imageProfileEditIcon:CSSProperties;
  addressDescriptionText:CSSProperties;
  verifyBtn: CSSProperties;
  error: CSSProperties;
}

const baseStyles: Styles = {
  addressRadio:{
    backgroundColor:"#f5f5f5",
    borderRadius:12,
    padding:10
  },
  error: {
    color: "#EA4335",
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
  },
  basicInfo: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#D9D9D9",
    borderRadius: 16,
    padding: 25,
    position: "relative",
  },
  profileImageWrap: {
    width: 120,
    height: 120,
    position:"relative",
    borderRadius: "50%", // Use '50%' to make it a circle
    overflow: "hidden", // Hide any overflow to maintain the circle shape
    marginRight: 30,
  },
  addressDescriptionText: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--text-color)",
    marginBottom: 0,
  },
  profileImage: {
    width: "100%",
    objectFit: "contain",
    height: "100%",
    cursor:"pointer"
  },
  info: {},
  name: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "var(--text-color)",
  },
  phone: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 18,
    color: "var(--light-text-color)",
  },
  email: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 18,
    color: "var(--light-text-color)",
  },
  label: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--light-text-color)",
    marginBottom: 8,
  },
  value: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--text-color)",
    marginBottom: 20,
  },
  radioTitle: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--light-text-color)",
    textTransform:"capitalize",
    marginBottom: 8,
  },
  radioInfo: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 14,
    color: "var(--text-color)",
    marginBottom: 20,
    maxWidth: 600,
  },
  radioContent: {
    position: "relative",
    marginLeft: 15,
  },
  submitBtn: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "var(--primary-color)",
    borderRadius: 16,
    paddingLeft: 80,
    paddingRight: 80,
    paddingTop: 18,
    paddingBottom: 18,
    color: "#B0B0B0",
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    marginTop: 30,
    backgroundColor: "var(--white-color)",
  },
  verifyBtn: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "var(--primary-color)",
    borderRadius: 16,
    paddingLeft: 80,
    paddingRight: 80,
    paddingTop: 18,
    paddingBottom: 18,
    color: "white",
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    marginTop: 30,
    backgroundColor: "blue",
  },
  deleteWrap: {
    borderTopWidth: 1,
    borderTopColor: "#D9D9D9",
    borderTopStyle: "solid",
    marginTop: 40,
    paddingTop: 25,
  },
  deleteInfo: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 16,
    color: "var(--text-color)",
  },
  deleteTitle: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "#EA4335",
    marginBottom: 12,
  },
  editIconWrap: {
    background: "none",
    border: 0,
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 14,
    color: "#333",
    // position:"absolute",
    // right:10,
    // top:0,
    borderWidth: 1,
    borderColor: "rgb(217, 217, 217)",
    borderStyle: "solid",
    borderRadius: 6,
    padding: 7,
    width: 100,
  },
  addressEditIconWrap: {
    position: "absolute",
    right: 10,
    background: "none",
    border: 0,
    top: 0,
  },
  imageProfileEditIcon:{
    position: "absolute",
    right: 10,
    top: 0,
  },
  editIcon: {
    width: 20,
  },
};
const mobileStyles: Partial<Styles> = {
  profileImageWrap: {
    width: 80,
    height: 80,
    position:"relative",
    borderRadius: "50%", // Use '50%' to make it a circle
    overflow: "hidden", // Hide any overflow to maintain the circle shape
    margin:"auto",
    marginBottom:20
  },
  name: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 14,
    color: "var(--text-color)",
  },
  phone: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 14,
    color: "var(--light-text-color)",
  },
  email: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 14,
    color: "var(--light-text-color)",
  },
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};
