import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Table,
  Spinner,
  Overlay,
  InputGroup,
} from "react-bootstrap";
import { getStyles } from "./OrderList.style";
import Section from "../../Components/Section/Section";
import {
  FaAngleRight,
  FaArrowRight,
  FaRegClock,
  FaSearch,
  FaSort,
} from "react-icons/fa";
import Search from "../../Components/Search/Search";
import shopImage from "../../assets/images/default-shop.png";
import Sort from "../../assets/images/sort.png";
import ProductImage from "../../assets/images/onion.png";
import { Link, useNavigate } from "react-router-dom";
import {
  My_Store,
  Order_list,
  Search_Store_Orders,
  Store_Product_Details,
} from "../../Api";
import { base_url } from "../../Api/ApiConst";
import noProduct from "../../assets/images/no-product.png";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import FormControl from "react-bootstrap/esm/FormControl";
import Popover from "react-bootstrap/esm/Popover";
import moment from "moment";
import { useTranslation } from "react-i18next";

const OrderList: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);

  const breadcrumbs: { text: string; link?: string }[] = [
    { text: "Home", link: "/" },
    { text: "Orders" },
  ];

  const handleSearch = () => {
    // Implement your search logic here
    fetchSearchOrders();
    if (searchText === "") {
      setIsSearchResult(false);
      return handleAPI();
    }
    setIsSearchResult(true);
  };
  interface Category {
    id: string;
    amount: string;
    order_date: string;
    // order_date: string;
    // PRDPrice: number;
    // PRDWeight: string;
    // product_subcategory: any;
    // name: string
    status:string;
  }

  const [sortBy, setSortBy] = useState<string>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [storeName, setStoreName] = useState<string | null>("");
  const [storeId, setStoreId] = useState<string | null>("");
  const [image, setImage] = useState<string | null>("");
  const [product, setProduct] = useState<Category[]>([]);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [pages, setPages] = useState<any>({});
  const [store, setStore] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [showSortPopover, setShowSortPopover] = useState(false);
  const target = useRef(null);
  const [isSearchResult, setIsSearchResult] = useState<boolean>(false); // Track if the current view is search results
  const [storeLogo, setStoreLogo] = useState<any>("");

  // const handleSort = (criteria: string) => {
  //   // Implement your sorting logic here
  //   setSortBy(criteria);
  // };

  const sortData = [
    {
      id: 1,
      Sorttype: "id",
      name: t("storeSortModal.productNameAsc"),
      Ascending: true,
    },
    {
      id: 2,
      Sorttype: "id",
      name: t("storeSortModal.productNameDesc"),
      Ascending: false,
    },
    {
      id: 3,
      Sorttype: "order_date",
      name: t("orderSortModal.orderDateAsc"),
      Ascending: true,
    },
    {
      id: 4,
      Sorttype: "order_date",
      name: t("orderSortModal.orderDateDesc"),
      Ascending: false,
    },
    {
      id: 5,
      Sorttype: "status",
      name: t("orderSortModal.orderStatusAsc"),
      Ascending: true,
    },
    {
      id: 6,
      Sorttype: "status",
      name: t("orderSortModal.orderStatusDesc"),
      Ascending: false,
    },
    {
      id: 7,
      Sorttype: "amount",
      name: t("orderSortModal.totalAmountAsc"),
      Ascending: true,
    },
    {
      id: 8,
      Sorttype: "amount",
      name: t("orderSortModal.totalAmountDesc"),
      Ascending: false,
    },
  ];

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    setStoreName(localStorage.getItem(STORAGE_CONSTANTS.STORE_NAME));
    setStoreId(localStorage.getItem(STORAGE_CONSTANTS.STORE_ID));
    setStoreLogo(localStorage.getItem(STORAGE_CONSTANTS.STORE_LOGO));
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (userToken) {
  //     const handleAPI = async () => {
  //       try {
  //         const response = await Order_list(userToken);
  //         if (response && response.status === 200) {
  //           console.log(response.data,"order---------->");
  //           setProduct(response.data.order_list)
  //           setPages(response.data.count);
  //           setImage(response.data.order_list[0].order_details[0].product.product_image)
  //         } else {
  //           console.log("Error fetching Order details");
  //         }
  //       } catch (error) {
  //         console.log("Error fetching Order details", error);
  //       }
  //     };
  //     handleAPI();
  //   }
  // }, [userToken])

  useEffect(() => {
    if (searchText) {
      fetchSearchOrders(); // <- Both function calls are now within a block statement
    } else {
      handleAPI();
    }
  }, [storeId, currentPage]);

  const handleAPI = async () => {
    try {
      setLoading(true);
      const response = await Order_list(userToken, currentPage);
      if (response && response.status === 200) {
        console.log(response.data, "order---------->");
        setProduct(response.data.order_list);
        setPages(response.data.count);
        setImage(
          response.data.order_list[0].order_details[0].product.product_image
        );
      } else {
        console.log("Error fetching Order details");
      }
    } catch (error) {
      console.log("Error fetching Order details", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchOrders = async () => {
    try {
      const payload = {
        // "id": storeId,
        product_name: searchText,
        store_based: true,
      };
      const response = await Search_Store_Orders(payload, currentPage);
      if (response && response.status === 200) {
        console.log(response.data, "search------------------->");
        // setPages(response.data);
        // setCurrentPage(parseInt(response.data.count));
        setProduct(response.data.user_order_list);
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    }
  };

  const handleSort = (item: any) => {
    setSortBy(item);
    if (item?.name) {
      let sortedProducts: any;
      if (item.Sorttype === "status") {
        sortedProducts = product.sort((a: any, b: any) => {
          const statusOrder: any = {
            PENDING: 1,
            UNDERWAY: 2,
            COMPLETE: 3,
            REFUNDED: 4,
            Cancelled: 5,
          };
          const statusA = a[item.Sorttype];
          const statusB = b[item.Sorttype];
          return item.Ascending
            ? statusOrder[statusA] - statusOrder[statusB]
            : statusOrder[statusB] - statusOrder[statusA];
        });
      } else {
        sortedProducts = product.sort((a: any, b: any) => {
          const itemA =
            item.Sorttype === "order_date"
              ? moment(a[item.Sorttype])
              : a[item.Sorttype];
          const itemB =
            item.Sorttype === "order_date"
              ? moment(b[item.Sorttype])
              : b[item.Sorttype];
          return item.Ascending ? itemA - itemB : itemB - itemA;
        });
      }
      setProduct(sortedProducts);
    } else {
      setProduct((prev) => prev?.sort((a: any, b: any) => b.id - a.id));
    }
  };

  // useEffect(() => {
  //   if (storeId) {
  //     fetchStoreList()
  //   }
  // }, [storeId])

  // const fetchStoreList = async () => {
  //   try {
  //     const response = await Store_Product_Details(storeId);
  //     if (response && response.status === 200) {
  //       // console.log(response.data.store_product_list[0].id);
  //       setProduct(response.data.store_product_list)
  //     } else {
  //       console.log("Error fetching Store Product Details");
  //     }
  //   } catch (error) {
  //     console.log("Error fetching Store Product Details", error);
  //   }
  // };

  const handleClick = (id: any) => {
    localStorage.setItem(STORAGE_CONSTANTS.ORDER_ID, id);
    navigate("/view-order");
  };

  console.log(storeName);
  const handleOverlayClose = () => {
    setShowSortPopover(false);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <>
      <Section className="orderList" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    {crumb.link ? (
                      <Link
                        to={crumb.link}
                        style={{
                          color:
                            index === 0 ? "var(--primary-color)" : "#BEB8B8",
                          fontFamily: "var(--primary-font-medium)",
                          fontSize: 16,
                        }}
                      >
                        {crumb.text}
                      </Link>
                    ) : (
                      <span
                        style={{
                          color:
                            index === 0 ? "var(--primary-color)" : "#BEB8B8",
                          fontFamily: "var(--primary-font-medium)",
                          fontSize: 16,
                        }}
                      >
                        {crumb.text}
                      </span>
                    )}
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <Row style={styles.searchRow}>
            <InputGroup>
              <FormControl
                type="text"
                placeholder={t("commonHeader.globalSearch")}
                className="mr-sm-2 search-input"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <Button
                variant="outline-primary search-btn"
                onClick={handleSearch}
              >
                <FaSearch />
              </Button>
            </InputGroup>
          </Row>
        </Container>
      </Section>
      <Section className="orders" style={{ marginTop: -30 }}>
        <div style={styles.whiteWrap}>
          <Container>
            <div className="d-flex align-items-center justify-content-center flex-row">
              <div style={styles.shopImageWrap} className="mx-2">
                <Image
                  src={storeLogo ? `${base_url}${storeLogo}` : shopImage}
                  style={styles.shopImage}
                />
              </div>
              <div style={styles.shopNameWrap}>
                <h3 style={styles.shopName}>{storeName}</h3>
              </div>
            </div>
            {/* <div style={styles.normalText}>01/01/2024 to 01/01/2024</div> */}
            <div
              className="d-flex align-items-center justify-content-between flex-row mt-3"
              style={{ position: "relative" }}
            >
              <div className="">
                <h3 style={styles.orderTitle}>{t("orderList.orders")}</h3>
                {/* <p className="mb-0" style={styles.orderStatus}> {t("orderList.status")} : Completed</p> */}
              </div>
              <div style={styles.normalText} className="">
                {t("orderList.noOfProducts")} : {product.length}
              </div>
              <div ref={target}>
                <Button
                  style={styles.btnSort}
                  variant="outline-primary"
                  onClick={() => setShowSortPopover(!showSortPopover)}
                >
                  <Image src={Sort} className="sortImage" />
                </Button>
                <Overlay
                  show={showSortPopover}
                  target={target.current}
                  placement="bottom"
                  containerPadding={20}
                  container={document.body}
                  onHide={handleOverlayClose}
                  rootClose
                >
                  <Popover id="popover-contained">
                    <Popover.Body>
                      <ul
                        className="sortPopover"
                        style={{ listStyleType: "none", padding: 0 }}
                      >
                        {sortData &&
                          sortData.map((ele) => {
                            return (
                              <li>
                                <Button
                                  variant="link"
                                  onClick={() => handleSort(ele)}
                                >
                                  {ele?.name}
                                </Button>
                              </li>
                            );
                          })}
                      </ul>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </div>

            <div style={styles.orderTableWrap}>
              {loading && (
                <Row>
                  <Col className="d-flex align-items-center justify-content-center">
                    <Spinner animation="grow" variant="primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                </Row>
              )}
              <Table hover style={styles.orderTable} className="productTable">
                <tbody>
                  {!loading && product.length === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h3 style={styles.noProduct}>
                        <Image
                          src={noProduct}
                          className="mx-2"
                          style={{ width: 32, height: 32 }}
                        />{" "}
                        {t("userProfile.noOrdersFound")}
                      </h3>
                    </div>
                  ) : (
                    !loading &&
                    product.length !== 0 &&
                    product &&
                    product.map((item) => {
                      return (
                        <tr
                          key={item.id}
                          onClick={() => handleClick(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>
                            {" "}
                            <input
                              type="radio"
                              name="productSelect"
                              style={styles.orderRadio}
                            />
                          </td>
                          <td>
                            <div style={styles.orderImageWrap}>
                              <Image
                                src={`${base_url}/${image}`}
                                style={styles.orderImage}
                              />
                            </div>
                          </td>
                          <td style={styles.orderInfo}>{item.id}</td>
                          <td style={styles.orderInfo}>
                            {String(item.order_date).split("T")[0]}
                          </td>
                          <td style={styles.orderInfo}>{item.amount}</td>
                          <td style={styles.completed}>{item.status}</td>
                          {/* <td style={styles.orderInfo}>411052</td> */}
                          <td style={styles.orderInfo}>
                            <FaAngleRight
                              onClick={() => handleClick(item.id)}
                              style={{ cursor: "pointer", fontSize: 24 }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  )}
                  {/* <tr>
                    <td> <input type="radio" name="productSelect" style={styles.productRadio} /></td>
                    <td>
                      <div style={styles.productImageWrap}>
                        <Image src={ProductImage} style={styles.productImage} />
                      </div>
                    </td>
                    <td style={styles.productInfo}>Onion</td>
                    <td style={styles.productInfo}>Vegetables</td>
                    <td style={styles.productInfo}>50/- kg</td>
                    <td style={styles.productInfo}><FaAngleRight /></td>
                  </tr>
                  <tr>
                    <td> <input type="radio" name="productSelect" style={styles.productRadio} /></td>
                    <td>
                      <div style={styles.productImageWrap}>
                        <Image src={ProductImage} style={styles.productImage} />
                      </div>
                    </td>
                    <td style={styles.productInfo}>Onion</td>
                    <td style={styles.productInfo}>Vegetables</td>
                    <td style={styles.productInfo}>50/- kg</td>
                    <td style={styles.productInfo}><FaAngleRight /></td>
                  </tr> */}
                </tbody>
              </Table>
              {!loading && product.length !== 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    float: "right",
                  }}
                >
                  {(isSearchResult
                    ? pages <= 10 && product.length >= 10
                    : true) && (
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            onClick={() =>
                              setCurrentPage(Math.max(currentPage - 1, 1))
                            }
                          >
                            {t("userProfile.previous")}
                          </a>
                        </li>
                        {Array.from({ length: pages }).map((_, index) => (
                          <li
                            className={`page-item ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                            key={index}
                          >
                            <a
                              className="page-link"
                              onClick={() => setCurrentPage(index + 1)}
                            >
                              {index + 1}
                            </a>
                          </li>
                        ))}
                        <li
                          className={`page-item ${
                            currentPage === pages ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            onClick={() =>
                              setCurrentPage(Math.min(currentPage + 1, pages))
                            }
                          >
                            {t("userProfile.next")}
                          </a>
                        </li>
                      </ul>
                    </nav>
                  )}
                </div>
              )}
            </div>
          </Container>
        </div>
      </Section>
    </>
  );
};

export default OrderList;
