
import { CSSProperties } from "react";

export interface Styles {
    customRow:CSSProperties;
    remove:CSSProperties;
    productName:CSSProperties;
    error:CSSProperties;
    btnCancel: CSSProperties;
    
}

const baseStyles: Styles = {
    customRow:{
        alignItems:"center",
        display:"flex",
        justifyContent:"space-between"
    },
    btnCancel:{
      width:200,
      backgroundColor:"#fff",
      color:"#333",
      fontFamily:"var(--primary-font-semibold)",
      fontSize:16,
      borderColor:"#333",
      borderRadius:16,
      paddingTop:10,
      paddingBottom:10
     },
    remove:{
        backgroundColor:"var(--white-color)",
        borderRadius:6,
        borderColor:"#D9D9D9",
        borderWidth:1,
        borderStyle:"solid",
        color:"#808080",
        marginLeft:5,
        fontSize:16,
        fontFamily:"var(--primary-font-medium)",
    },
    error: {
      color: "#EA4335",
      fontSize: 16,
      fontFamily: "var(--primary-font-medium)",
    },
    productName:{
        fontSize:16,
        fontFamily:"var(--primary-font-semibold)",
        color:"var(--text-color)"
    },
};

const mobileStyles: Partial<Styles> = {
  btnCancel:{
    width:150,
    backgroundColor:"#fff",
    color:"#333",
    fontFamily:"var(--primary-font-semibold)",
    fontSize:14,
    borderColor:"#333",
    borderRadius:16,
    paddingTop:10,
    paddingBottom:10
   },
  };
  export const getStyles = (isMobile: boolean): Styles => {
    if (isMobile) {
      return {
        ...baseStyles,
        ...mobileStyles,
      };
    }
    return baseStyles;
  };