import axios from "axios";
import { base_url } from "./ApiConst";
import { Token } from "./Token";
import { STORAGE_CONSTANTS } from "../shared/Constants/Constants";
// import env from "react-dotenv";
// const base_url = env.APPLICATION_BASE_URL;

export const categories = async () => {
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  try {
    const response = await axios.get(
      `${base_url}api/v0/dashboard/main-categories/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const Nearest_Shops = async (
  selectedBusinessType,
  userLatitude,
  userLongitude,
  userPincode
) => {
  let params = {
    business_type: selectedBusinessType,
    lat: userLatitude,
    long: userLongitude,
    pincode: userPincode,
  };
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  try {
    const response = await axios.post(
      `${base_url}api/v0/dashboard/category/store_list/`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const Sub_categories = async (id) => {
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  try {
    const response = await axios.get(
      `${base_url}api/v0/dashboard/subcategory/${id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const Product_list = async (
  subId,
  userLatitude,
  userLongitude,
  userPincode
) => {
  let params = {
    sub_category: subId,
    lat: userLatitude,
    long: userLongitude,
    pincode: userPincode,
  };
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  try {
    const response = await axios.post(
      `${base_url}api/v0/product/home_product_list/`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const Top_Deals = async (payload) => {
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  // let pd = JSON.parse(payload)
  console.log("FFFFFFF" + typeof payload);
  // try {
  return await axios.get(`${base_url}api/v0/offer/top-picks/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: Token,
    },
    params: payload,
  });

  // } catch (error) {
  //     throw new Error(error);
  // }
};

export const Search_Products = async (
  searchText,
  userLatitude,
  userLongitude,
  userPincode
) => {
  console.log(userLatitude);
  console.log(userLongitude);
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  return await axios.get(`${base_url}api/v0/dashboard/search/products/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: Token,
    },
    params: {
      search_text: searchText,
      lat: userLatitude,
      long: userLongitude,
      pincode: userPincode,
    },
  });
};

export const Search_Stores = async (
  searchText,
  userLatitude,
  userLongitude,
  userPincode
) => {
  console.log(userLatitude);
  console.log(userLongitude);
  console.log(searchText);
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  return await axios.get(`${base_url}api/v0/dashboard/search/stores/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: Token,
    },
    params: {
      search_text: searchText,
      lat: userLatitude,
      long: userLongitude,
      pincode: userPincode,
    },
  });
};

export const Store_Registration = async (
  formData,
  userToken,
  contactNumber,
  contactPersonName,
  selectedOption,
  address,
  city,
  pincode,
  storeLat,
  storeLong,
  openingTime,
  closingTime
) => {
  const istDate = new Date(openingTime);
  // Convert to UTC and format in ISO 8601
  const utcDate = new Date(
    istDate.getTime() - istDate.getTimezoneOffset() * 60000
  );
  const isoString = utcDate.toISOString();
  const formattedOpenTime = new Date(isoString).toISOString().substring(11, 19);

  const istDat = new Date(closingTime);
  // Convert to UTC and format in ISO 8601
  const utcDat = new Date(
    istDat.getTime() - istDat.getTimezoneOffset() * 60000
  );
  const isostring = utcDat.toISOString();
  const formattedCloseTime = new Date(isostring)
    .toISOString()
    .substring(11, 19);

  let params = {
    owner_name: formData.ownerName,
    store_name: formData.businessName,
    email: formData.email,
    contact_number: contactNumber,
    business_registration_number: formData.registrationNumber,
    service_area_pin_code: null,
    store_type: selectedOption,
    address: address,
    city: city ?? null,
    pincode: pincode ?? null,
    latitude: storeLat ?? null,
    longitude: storeLong ?? null,
    landmark: formData.landMark,
    contact_person_name: contactPersonName ?? null,
    contact_person_number: formData.contactPersonNumber ?? null,
    buisness_type: formData.businessType,
    opening_time: openingTime && formattedOpenTime,
    closing_time: closingTime && formattedCloseTime,
    language_data: [
      {
        language: "1",
        address: formData.nepaliAddress,
        store_name: formData.nepaliName,
        city: formData.nepaliCity,
      },
    ],
  };
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);

  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe"

  return await axios.post(`${base_url}api/v0/store/stores/create/`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + userToken1,
    },
  });
};

export const User_LogIn = async (loginData) => {
  // let params = {
  //   mobile_number: number,
  // };
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  const { mobile_number, registered_device, device_type } = loginData;
  const params = {
    mobile_number,
    registered_device,
    device_type,
  };

  try {
    const response = await axios.post(`${base_url}api/v0/auth/login/`, params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: Token,
      },
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const login_OTP = async (userid, enteredOtp) => {
  let params = {
    user_id: userid,
    otp: enteredOtp,
  };
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";

  return await axios.post(`${base_url}api/v0/auth/otp/`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: Token,
    },
  });
};

export const Languages = async () => {
  try {
    const response = await axios.get(
      `${base_url}api/v0/language/language_list`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const Store_BankDetails = async (
  data,
  userToken,
  idNumber,
  idNumber2,
  chequeFile,
  idNumber1File,
  idNumber2File,
  prevChequeFile
) => {
  let params = new FormData();

  // Append form data fields to the FormData object
  params.append("service_area_pin_code", data?.areaPinCode);
  params.append("bank_name", data?.bank);
  params.append("account_number", data?.accountNumber);
  params.append("cheque_copy", chequeFile ? chequeFile : prevChequeFile);
  params.append("account_name", data?.accountHolderName);
  //  params.append("contact_person_number", contactNumber);
  params.append("id_document_1", idNumber);
  params.append("id_document_2", idNumber2);
  params.append("country", data?.branch);
  idNumber1File && params.append("id_document_1_file", idNumber1File);
  idNumber2File && params.append("id_document_2_file", idNumber2File);

  // let params = {
  //   // service_area_pin_code:parseInt(data.areaPinCode),
  //   bank_name: data.bank,
  //   account_number: data.accountNumber,
  //   service_area_pin_code: areaPinCode,
  //   swift_code: data.ifscCode, // IFSC code
  //   account_name: data.accountHolderName,
  //   id_document_1: idNumber,
  //   id_document_2: idNumber2, // holder name
  //   country: null,
  //   paypal_email: null,
  //   description: null,
  // };
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);

  return await axios.post(
    `${base_url}api/v0/store/stores/bank-details/`,
    params,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token " + userToken1,
      },
    }
  );
};

export const Store_View = async (storeId, mainId) => {
  let params = {
    store_id: storeId,
    main_category: mainId,
  };
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  try {
    const response = await axios.post(
      `${base_url}api/v0/store/product_list/${storeId}/category/`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const Store_SubCategory_View = async (storeId, selectedSubcategory) => {
  let params = {
    store_id: storeId,
    sub_category: selectedSubcategory,
  };
  try {
    const response = await axios.post(
      `${base_url}api/v0/store/product_list/${storeId}/category/`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const Set_Store = async (storeId, storeToken) => {
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe"
  try {
    const response = await axios.get(
      `${base_url}api/v0/store/stores/details/${storeId}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${storeToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const Store_Update = async (
  id,
  data,
  registerNumber,
  address,
  productImage,
  languageId,
  openingTime,
  closingTime
) => {
  const istDate = new Date(openingTime);
  // Convert to UTC and format in ISO 8601
  const utcDate = new Date(
    istDate.getTime() - istDate.getTimezoneOffset() * 60000
  );
  const isoString = utcDate.toISOString();
  const formattedOpenTime = new Date(isoString).toISOString().substring(11, 19);

  const istDat = new Date(closingTime);
  // Convert to UTC and format in ISO 8601
  const utcDat = new Date(
    istDat.getTime() - istDat.getTimezoneOffset() * 60000
  );
  const isostring = utcDat.toISOString();
  const formattedCloseTime = new Date(isostring)
    .toISOString()
    .substring(11, 19);

  let lan = [
    {
      language: "1",
      store: id,
      address: data.nepaliAddress,
      store_name: data.nepaliName,
      city: data.nepaliCity,
    },
  ];

  let params = new FormData();
  // console.log(JSON.stringify(lan), "hiiiiiiiiiiiiiii");
  // Append form data fields to the FormData object
  params.append("owner_name", data.ownerName);
  params.append("store_name", data.businessName);
  params.append("email", data.email);
  params.append("business_registration_number", registerNumber);
  params.append("city", data.city);
  params.append("landmark", data.landMark);
  params.append("address", address);
  params.append("contact_person_number", data?.contactPersonNumber);
  params.append("contact_person_name", data?.contactPersonName);
  params.append("buisness_type", data.businessType);
  params.append("language_data", JSON.stringify(lan));
  openingTime && params.append("opening_time", formattedOpenTime);
  closingTime && params.append("closing_time", formattedCloseTime);
  productImage && params.append("logo", productImage);

  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);

  return await axios.put(`${base_url}api/v0/store/stores/${id}/`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Token " + userToken1,
    },
  });
};

export const Add_Product = async (
  data,
  productImage,
  additionalImage1,
  additionalImage2
) => {
  let params = new FormData();

  // Append form data fields to the FormData object
  console.log(productImage, "images------------>");
  params.append("product_name", data?.productName ?? null);
  params.append("product_description", data?.description ?? null);
  productImage && params.append("product_image", productImage);
  additionalImage1 && params.append("additional_image_1", additionalImage1); // additional_image_1
  additionalImage2 && params.append("additional_image_2", additionalImage2); // additional_image_2
  params.append("product_subcategory", data?.category);
  params.append("pieces", 0);
  params.append("available", data?.inventory);
  params.append("unit_measure", data?.unitOfMeasure);
  params.append("PRDWeight", data?.variants ?? null);
  // data?.minQuantity ? parseInt(data?.minQuantity) : 0
  params.append("PRDPrice", parseInt(data?.priceInNepali));
  params.append("USDPrice", parseInt(data?.price));
  params.append("nepali_name", data?.productNameInNepali ?? null);
  params.append("nepali_description", data?.descriptionInNepali ?? null);
  params.append("minimum_qty", parseInt(data?.minQuantity ?? null));
  params.append("maximum_qty", data?.maxQuantity ?? null);
  params.append("sku", data?.sku ?? null);
  params.append("brand", data?.brand ?? null);
  // data?.variants && params.append("variants", [data?.variants]);
  // params.append("language_data_list", []);
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);

  return await axios.post(`${base_url}api/v0/product/add/`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Token " + userToken1,
    },
  });
};

export const Edit_Product = async (
  userToken,
  data,
  productImage,
  product_id,
  additionalImage1,
  additionalImage2
) => {
  // let params = {
  //   product_name: productName,
  //   product_description: description,
  //   product_image: null,
  //   additional_image_1: null,
  //   additional_image_2: null,
  //   product_subcategory: parseInt(category),
  //   PRDPrice: parseInt(priceInNepali),
  //   USDPrice: parseInt(price),
  //   unit_measure: unitOfMeasure,
  //   width: null,
  //   height: null,
  //   PRDWeight: null,
  //   pieces: parseInt(minQuantity),
  //   available: parseInt(inventory),
  //   is_active: "True",
  // };
  console.log(productImage);

  let params = new FormData();

  // Append form data fields to the FormData object
  params.append("product_name", data.productName);
  params.append("product_description", data.description);
  productImage && params.append("product_image", productImage);
  additionalImage1 && params.append("additional_image_1", additionalImage1);
  additionalImage2 && params.append("additional_image_2", additionalImage2);
  params.append("product_subcategory", parseInt(data.category));
  params.append("pieces", parseInt(data.minQuantity));
  params.append("available", parseInt(data.inventory));
  params.append("unit_measure", data.unitOfMeasure);
  params.append("PRDWeight", parseInt(data.variants));
  params.append("weight", parseInt(0));
  params.append("height", parseInt(0));
  params.append("PRDPrice", parseInt(data.priceInNepali));
  params.append("USDPrice", parseInt(data.price));
  params.append("variants", data.variants);
  params.append("nepali_name", data.productNameInNepali);
  params.append("nepali_description", data.descriptionInNepali);
  params.append("minimum_qty", parseInt(data?.minQuantity));
  params.append("maximum_qty", data?.maxQuantity ?? null);
  params.append("sku", data?.sku ?? null);
  params.append("brand", data?.brand ?? null);

  // params.append("variants", ["L"]);
  // params.append("language_data_list", []);
  // "is_active" = "True",
  // "nepali_name":"Nepali String",
  // "nepali_description":"Nepali description"

  return await axios.put(
    `${base_url}api/v0/product/update_product/${product_id}/`,
    params,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token " + userToken,
      },
    }
  );
};

export const Store_Product_Details = async (storeId, currentPage) => {
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  try {
    const response = await axios.get(
      `${base_url}api/v0/store/store_product_list/${storeId}/?page=${currentPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const Search_Store_Product = async (
  searchTerm,
  storeId,
  currentPage
) => {
  const data = {
    store_id: storeId,
    product_name: searchTerm,
  };
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  return await axios.post(
    `${base_url}api/v0/store/search_product/?page=${currentPage}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: Token,
      },
    }
  );
};

export const Search_Store_Orders = async (payload, currentPage) => {
  // const data = {
  //   // id: storeId,
  //   product_name: searchText,
  //   store_based: searchText ? false : true,
  // };
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  return await axios.post(
    `${base_url}api/v0/orders/search-order/?page=${currentPage}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + userToken1,
      },
    }
  );
};

export const My_Store = async (userToken) => {
  try {
    const response = await axios.get(
      `${base_url}api/v0/store/stores/my_stores/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + userToken,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const Product_Details = async (
  product_id,
  userLatitude,
  userLongitude,
  userPincode
) => {
  try {
    const response = await axios.get(
      `${base_url}api/v0/product/product_deatils/${product_id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
        params: {
          lat: userLatitude,
          long: userLongitude,
          pincode: userPincode,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const Add_To_Cart = async (id, currency, quantity) => {
  // console.log(
  //   "DDDDDDDDDDDDDDDDDDD",
  //   localStorage.getItem(STORAGE_CONSTANTS.ORDER_ID)
  // );
  console.log(quantity, "quan========>");
  const orderId = localStorage.getItem(STORAGE_CONSTANTS.ORDER_ID);
  const data = {
    currency: currency === "NPR" ? "NPR" : "USD",
    qty: quantity,
    product_id: id,
    name_variation: null,
  };
  if (orderId) {
    data["order_id"] = orderId;
  }
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";ORDER_

  return await axios.post(`${base_url}api/v0/orders/add_to_cart/`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken1 ? `Token ${userToken1}` : Token,
    },
  });
};

export const Update_Cart = async (currency, quantity, id, order) => {
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  const data = {
    currency: currency === "NPR" ? "NPR" : "USD",
    qty: quantity,
    product_id: id,
    name_variation: null,
  };
  if (order) {
    data["order_id"] = order;
  }

  return await axios({
    method: "put",
    url: `${base_url}api/v0/orders/add_to_cart/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken1 ? `Token ${userToken1}` : Token,
    },
    data,
  });
};

export const Remove_From_Cart = async (currency, id, order) => {
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  const params = {
    currency: currency == "NPR" ? "NPR" : "USD",
    product_id: parseInt(id),
    name_variation: "S",
    order_id: parseInt(order),
  };
  try {
    const response = await axios.delete(
      `${base_url}api/v0/orders/add_to_cart/`,
      {
        data: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: userToken1 ? `Token ${userToken1}` : Token,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const View_Cart = async () => {
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  let order = localStorage.getItem(STORAGE_CONSTANTS.ORDER_ID);
  console.log(order, "order------------>");

  return await axios.get(`${base_url}api/v1/orders/cart/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken1 ? `Token ${userToken1}` : Token,
    },
    params: {
      order_id: localStorage.getItem(STORAGE_CONSTANTS.ORDER_ID),
      currency: "NPR",
    },
  });
};

export const getOutOfStockProductList = async (payload) => {
  // console.log(order,"order------------>")

  return await axios.post(
    `${base_url}api/v0/product/check-zero-product/?all=true`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe",
      },
    }
  );
};

export const verifyEmail = async (verifyOTP) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  let userId = localStorage.getItem(STORAGE_CONSTANTS.USER_ID);

  const data = {
    email_otp: verifyOTP,
  };

  return await axios.post(
    `${base_url}api/v0/auth/user/${userId}/verify-email/`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken1}`,
      },
    }
  );
};

export const OTP_TO_VERIFY_EMAIL = async () => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  let userId = localStorage.getItem(STORAGE_CONSTANTS.USER_ID);

  return await axios.get(
    `${base_url}api/v0/auth/user/${userId}/email-verification/`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken1}`,
      },
    }
  );
};

export const Cart_OrderId = async () => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  // console.log(order,"order------------>")

  return await axios.get(`${base_url}api/v0/orders/cart/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken1}`,
    },
  });
};

export const applyCoupon = async (currency, code, userToken) => {
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  return await axios.post(`${base_url}api/v0/product/cart/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken ? `Token ${userToken}` : Token,
    },
    data: {
      currency: currency == "NPR" ? "NPR" : "USD",
      code: code,
      status: false,
    },
  });
};

export const Add_To_Wishlist = async (userToken, id) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  const data = {
    product_id: id,
  };

  return await axios.post(`${base_url}api/v0/orders/wishlist/`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken1}`,
    },
  });
};

export const View_Wishlist = async (userToken) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);

  return await axios.get(`${base_url}api/v0/orders/wishlist/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken1}`,
    },
  });
};

export const Delete_Wishlist = async (userToken, id) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);

  return await axios.delete(`${base_url}api/v0/orders/wishlist/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken1}`,
    },
    data: {
      product_id: Number(id),
    },
  });
};

export const Product_Delete = async (product_id, userToken) => {
  return await axios.delete(
    `${base_url}api/v0/product/product_delete/${product_id}/`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + userToken,
      },
    }
  );
};

export const All_Subcategories = async () => {
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  try {
    const response = await axios.get(
      `${base_url}api/v0/dashboard/subcategory/${0}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const Order_list = async (userToken, currentPage) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);

  try {
    const response = await axios.get(
      `${base_url}api/v0/store/order_list/?page=${currentPage}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + userToken1,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

// export const View_Orders = async (userToken, orderId) => {
//   let params = {
//     id: parseInt(orderId),
//   };

//   try {
//     const response = await axios.post(
//       `${base_url}api/v0/orders/search-order/`,
//       params,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: "Token " + userToken,
//         },
//       }
//     );
//     return response;
//   } catch (error) {
//     throw new Error(error);
//   }
// };

export const Create_Offers = async (
  userToken,
  storeId,
  data,
  amount,
  limit,
  percent
) => {
  let params = {
    store: storeId,
    offer_type: data.offer,
    percent: percent ? parseInt(percent) : null,
    amount: amount ? parseInt(amount) : null,
    limited: limit ? parseInt(limit) : null,
    product: parseInt(data.productType),
    send_per: data.sendPer,
    status: "True",
    valid_from: data.validFrom,
    valid_to: data.validTo,
  };
  return await axios.post(`${base_url}api/v0/offer/store-offers/`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + userToken,
    },
  });
};

export const Edit_Offer = async (
  id,
  userToken,
  storeId,
  data,
  amount,
  limit,
  percent
) => {
  let params = {
    store: storeId,
    offer_type: data.offer,
    percent: percent ? parseInt(percent) : null,
    amount: amount ? parseInt(amount) : null,
    limited: limit ? parseInt(limit) : null,
    product: parseInt(data.productType),
    send_per: data.sendPer,
    status: "True",
    valid_from: data.validFrom,
    valid_to: data.validTo,
  };

  return await axios.put(
    `${base_url}api/v0/offer/offer-modify/${id}/`,
    params,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + userToken,
      },
    }
  );
};

export const All_Product_List = async (storeId) => {
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  try {
    const response = await axios.get(
      `${base_url}api/v0/store/store_product_list/${storeId}/?all=true`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const Offers_List = async (userToken, params) => {
  // let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  console.log(userToken, "user======>");
  return await axios.get(`${base_url}api/v0/offer/store-offers/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken}`,
    },
    params: params,
  });
};

export const getOfferDetails = async (userToken, offerID) => {
  return await axios.get(`${base_url}api/v0/offer/offer-modify/${offerID}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + userToken,
    },
  });
};
// export const Submit_Cart = async (storeId, selectedSubcategory) => {
//   // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
//   let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);

//   let params = {
//     currency: "NPR",
//     code: "",
//     order_id: 34,
//     submit: "",

//     submit: "True",
//     first_name: "",
//     last_name: "",
//     email: "",
//     mobile_number: "",
//     address: "",
//     city: "",
//     pincode: "",
//     country: "",
//     state: "",
//     payment_gateway: "",
//     gateway_data: {},
//     success_val:
//       "Current False to reject else True for success check to create a Transaction",
//   };

//   const response = await axios.post(`${base_url}api/v0/orders/cart/`, params, {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: userToken1 ? `Token ${userToken1}` : Token,
//     },
//   });
//   return response;
// };

export const Saved_Address = async (userToken) => {
  return await axios.get(`${base_url}api/v0/auth/user/addresses/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + userToken,
    },
  });
};

export const Add_Address = async (userToken, formData, data, address) => {
  const params = {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    type: formData.addressType,
    address_str: address,
    landmark: data.landMark,
    city: data.city,
    state: data.state,
    country: "",
    pincode: data.zipCode,
    mobile: data.phoneNumber,
  };

  return await axios.post(`${base_url}api/v0/auth/user/addresses/`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken}`,
    },
  });
};

export const Delete_Address = async (userToken, id) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  console.log(userToken1);

  return await axios.delete(
    `${base_url}api/v0/auth/user/address-modify/${id}/`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken1}`,
      },
    }
  );
};

export const Edit_Address = async (data, formData, addressId, address) => {
  let params = {
    first_name: data.firstName,
    last_name: data.lastName,
    type: formData.addressType,
    address_str: address,
    landmark: data.landMark,
    city: data.city,
    state: data.state,
    country: "Nepal",
    pincode: data.zipCode,
    mobile: data.phoneNumber,
    email: data.email,
  };

  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);

  return await axios.put(
    `${base_url}api/v0/auth/user/address-modify/${addressId}/`,
    params,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + userToken1,
      },
    }
  );
};

// export const Get_Bulk_Upload = async (userToken) => {
//   let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
//   try {
//     const response = await axios.get(
//       `${base_url}api/v0/store/product/bulk_upload/`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: "Token " + userToken1,
//         },
//       }
//     );
//     return response;
//   } catch (error) {
//     throw new Error(error);
//   }
// };

export const Store_Revenue = async (payload) => {
  // let data = {
  //   from_date: fromDate,
  //   till_date: toDate,
  //   store_id: storeId //
  // };

  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);

  return await axios.post(`${base_url}/api/v0/store/store-revenue/`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + userToken1,
    },
  });
};

export const Add_Bulk_Upload = async (
  userToken,
  storeId,
  file,
  zip,
  fileType
) => {
  let params = new FormData();
  // Append form data fields to the FormData object
  fileType && params.append("file_type", fileType);
  zip && params.append("zip_media", zip);
  params.append("store_id", storeId);
  params.append("my_file", file);

  return await axios.post(
    `${base_url}api/v0/store/product/bulk_upload/`,
    params,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token " + userToken,
      },
    }
  );
};

export const Master_Product = async () => {
  return await axios.get(`${base_url}api/v0/product/master-product/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: Token,
    },
  });
};

export const Edit_User_Details = async (
  first,
  last,
  userNum,
  email,
  imageFile
) => {
  let params = new FormData();
  // Append form data fields to the FormData object
  imageFile && params.append("image", imageFile);
  params.append("first_name", first);
  params.append("last_name", last);
  // params.append("display_name", "");
  // params.append("bio", "");
  params.append("mobile_number", userNum);
  // params.append("address", "");
  // params.append("post_code", "");
  // params.append("country", "");
  // params.append("state", "");
  params.append("email", email);
  // params.append("gender", "");
  // params.append("type", "");
  // params.append("code", "");
  // params.append("recommended_by", "");
  // params.append("referrals", "");
  // params.append("blance", "");
  // params.append("request", "");
  // params.append("date_of_birth", "");
  // params.append("date", "");
  // params.append("date_update", "");

  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  let userId = localStorage.getItem(STORAGE_CONSTANTS.USER_ID);
  // console.log(userToken1, "ffffffffffff");

  return await axios.put(`${base_url}api/v0/auth/user/${userId}/`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Token " + userToken1,
    },
  });
};

export const Get_User_Details = async () => {
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  let userId = localStorage.getItem(STORAGE_CONSTANTS.USER_ID);

  return await axios.get(`${base_url}api/v0/auth/user/${userId}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + userToken1,
    },
  });
};

export const Brought_Together = async (
  userLatitude,
  userLongitude,
  userPincode
) => {
  let params = {
    lat: userLatitude,
    long: userLongitude,
    pincode: userPincode,
  };

  return await axios.post(
    `${base_url}api/v0/product/top-pics-products/`,
    params,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: Token,
      },
    }
  );
};

export const COD_Payment = async (payload) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);

  return await axios.post(`${base_url}api/v0/orders/cart/`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken1}`,
    },
  });
};

export const Past_Orders = async (currentPage) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  return await axios.get(
    `${base_url}api/v0/orders/order_summery/?page=${currentPage}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + userToken1,
      },
    }
  );
};

export const Todays_Offers = async () => {
  return await axios.get(`${base_url}api/v0/offer/today-store-offers/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: Token,
    },
  });
};

export const Payment_Status = async (uuid) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  console.log("USER_TPKRN", userToken1);
  let enc_code = window.location.href;
  console.log("enc_CODEREEEE", enc_code);
  const data = enc_code.split("data=")[enc_code.split("data=").length - 1];
  // let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  let success = true ? data.includes("confirm-order") : false;
  let check_url = success
    ? `${base_url}payments/check_payment/esewa/${
        uuid.split("uuid=")[1].split("&")[0]
      }/`
    : `${base_url}payments/check_payment/esewa/${
        uuid.split("uuid=")[1].split("&")[0]
      }/?data=${data}`;
  // console.log("ENCCCCCCCCCC SPLIT", enc_code.split('data=')[enc_code.split('data=').length - 1])
  return await axios.get(check_url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken1}`,
    },
  });
};

export const Coupons = async () => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  return await axios.get(`${base_url}api/v0/offer/platform-offers/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken1}`,
    },
  });
};

export const Search_Past_Orders = async (id, storeId, currentPage) => {
  const data = {
    id: storeId,
    product_name: id,
    store_based: false,
  };
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  return await axios.post(
    `${base_url}api/v0/orders/search-order/?page=${currentPage}`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + userToken1,
      },
    }
  );
};

export const Cancel_Order = async (payload) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  return await axios.post(`${base_url}api/v0/orders/cancel-order/`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken1}`,
    },
  });
};

export const GET_BANK_DETAILS = async (userToken) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  return await axios.get(`${base_url}api/v0/store/stores/bank-details/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + userToken1,
    },
  });
};

export const Unit_Of_Measure = async () => {
  return await axios.get(`${base_url}api/v0/product/unit-of-measures/list/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: Token,
    },
  });
};

export const View_Past_Orders = async (id) => {
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  // let order_id = localStorage.getItem(STORAGE_CONSTANTS.ORDER_ID);
  console.log(id, "order------------>");

  return await axios.get(`${base_url}api/v1/orders/order/${id}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken1}`,
    },
  });
};

export const getDetailedLocationWithLatandLong = async (
  latitude,
  longitude,
  apiKey
) => {
  // console.log(latitude)
  // console.log(longitude)
  // const token = "9i8h7g6f-5e4d-3c2b-1a0j-dcba098765fe";

  // try {
  return await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
  );
};

export const Products_for_Review = async (currentPage, limit) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  console.log(currentPage, "current====>");
  return await axios.get(
    `${base_url}api/v0/product/order-product-details/?page=${currentPage}&limit=${limit}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken1}`,
      },
    }
  );
};

export const Product_Ratings = async (id) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  return await axios.get(`${base_url}api/v0/product/product-rating/${id}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken1}`,
    },
  });
};

export const Product_Details_Rating = async (id) => {
  return await axios.get(`${base_url}api/v0/product/product-rating/${id}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: Token,
    },
  });
};

export const User_Product_Rating = async (payload, id) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  return await axios.post(
    `${base_url}api/v0/product/product-rating/${id}/`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken1}`,
      },
    }
  );
};

export const Store_Ratings = async (currentPage, limit) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  return await axios.get(
    `${base_url}api/v0/product/order-store-details/?page=${currentPage}&limit=${limit}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken1}`,
      },
    }
  );
};

export const Get_Store_Ratings = async (id) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  return await axios.get(`${base_url}api/v0/store/store-rating/${id}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken1}`,
    },
  });
};

export const Selected_Store_Rating = async (id) => {
  return await axios.get(`${base_url}api/v0/store/store-rating/${id}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: Token,
    },
  });
};

export const User_Store_Rating = async (payload, id) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  return await axios.post(
    `${base_url}api/v0/store/store-rating/${id}/`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken1}`,
      },
    }
  );
};

export const User_Last_Viewed = async (id) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  const data = {
    product_id: id,
  };
  return await axios.post(
    `${base_url}api/v0/product/last-viewed-products/`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken1}`,
      },
    }
  );
};

export const Last_Viewed = async () => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);
  return await axios.get(`${base_url}api/v0/product/last-viewed-products/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${userToken1}`,
    },
  });
};

export const Delivery_Method = async (orderId, storeId, method) => {
  let userToken1 = localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN);

  let data = {
    order_id: orderId,
    store_id: storeId,
    delivery_method: method === "Delivery" ? "DELIVERY" : method === "Pick Up" ? "PICKED_UP" : "",
  };

  return await axios.post(
    `${base_url}api/v0/orders/order/delivery_method/`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken1}`,
      },
    }
  );
};
