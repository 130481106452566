import { CSSProperties } from "react";
import { Right } from "react-bootstrap/lib/Media";

export interface Styles {
  imageWrap: CSSProperties;
  productImage: CSSProperties;
  productContent: CSSProperties;
  shopName: CSSProperties;
  quantity: CSSProperties;
  productName: CSSProperties;
  priceWrap: CSSProperties;
  shopIcon: CSSProperties;
  price: CSSProperties;
  offerPrice: CSSProperties;
  product: CSSProperties;
  btnAdd: CSSProperties;
  offerBadgeWrap: CSSProperties;
  arrowRight: CSSProperties;
  heartImage: CSSProperties;
  heartImageWrap: CSSProperties;
  head: CSSProperties;
  noProduct: CSSProperties;
  text:CSSProperties;
}

const styles: Styles = {
  imageWrap: {
    backgroundColor: "var(--gray-light)",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    textAlign: "center",
    position: "relative",
  },
  productImage: {
    width: 115,
    height: "6rem",
  },
  shopIcon: {
    width: 30,
  },
  productContent: {
    padding: 15,
  },
  head: {
    fontSize: 12,
    fontFamily: "var(--primary-font-semibold)",
  },
  noProduct: {
    fontSize: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "var(--primary-font-semibold)",
    backgroundColor: "var(--primary-color)",
    color: "#fff",
    padding: 20,
    borderRadius: 16,
  },
  shopName: {
    color: "var(--text-color)",
    fontSize: 13,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 235,
    fontFamily: "var(--primary-font-semibold)",
  },
  quantity: {
    fontSize: 16,
    fontFamily: "var(--primary-font-regular)",
    color: "var(--black-color)",
  },
  productName: {
    fontSize: 18,
    fontFamily: "var(--primary-font-semibold)",
    color: "var(--text-color)",
  },
  product: {
    marginBottom: 20,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "var(--gray-light)",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    cursor: "pointer"
  },
  priceWrap: { marginBottom: 15 },
  price: {
    fontSize: 12,
    fontFamily: "var(--primary-font-medium)",
    color: "#AFABAB",
    textDecoration: "line-through",
    marginLeft: 5,
  },
  offerPrice: {
    fontSize: 16,
    fontFamily: "var(--primary-font-bold)",
    color: "var(--black-color)",
  },
  btnAdd: {
    borderWidth: 2,
    borderColor: "var(--primary-color)",
    borderStyle: "solid",
    backgroundColor: "var(--white-color)",
    color: "var(--text-color)",
    fontSize: 16,
    fontFamily: "var(--primary-font-semibold)",
  },

  offerBadgeWrap: {
    width: 41,
    height: 51,
    backgroundColor: "#EA4335",
    position: "absolute",
    top:0,left:10,
    paddingTop:2,
  },

  arrowRight: {
    width: 0,
    height: 0,
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderBottom: " 20px solid #f5f5f5",
    position: "absolute",
    bottom: -1,
    left: 0,
   
  },
  text:{
    fontSize: 10,
    fontFamily: "var(--primary-font-bold)",
    color: "var(--white-color)",
    
  },
  heartImageWrap: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  heartImage: {
    width: 24,
    height: 24,
  },
};

export default styles;
