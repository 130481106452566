import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    // mainCategory : [],
    selected_latitude : "",   
    selected_longitude : "",
    locality_name:"",
    pincode:""
    //27.614329
    //84.4304
    
};

export const Location = createSlice({
    name: "Location",
    initialState,
    reducers: {
        SET_SELECTED_LATITUDE: (state, action) => {
            state.selected_latitude = action.payload;
        },
        SET_SELECTED_LONGITUDE: (state, action) => {
            state.selected_longitude = action.payload;
        },
        SET_SELECTED_LOCALITY: (state, action) => {
            state.locality_name = action.payload;
        },
        SET_SELECTED_PINCODE: (state, action) => {
            state.pincode = action.payload;
        },
        // CLEAR_LATITUDE:(state) =>{
        //     state.selected_latitude = ""
        // },
        // CLEAR_LONGITUDE:(state) =>{
        //     state.selected_longitude = ""
        // },
        // CLEAR_LOCATION_DATA:(state) =>{
        //     state.locality_name = "";
        //     state.pincode = ""
        // }
    },
});


export const {  SET_SELECTED_LATITUDE,SET_SELECTED_LONGITUDE,SET_SELECTED_LOCALITY,SET_SELECTED_PINCODE } = Location.actions;
export default Location.reducer;