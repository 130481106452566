import { CSSProperties } from "react";

export interface Styles {
  label: CSSProperties;
  whiteWrap: CSSProperties;
  error: CSSProperties;
  btnBlue: CSSProperties;
  uploadTxt: CSSProperties;
  heading: CSSProperties;
  labelText: CSSProperties;
  templateIcon: CSSProperties;
  templateText: CSSProperties;
  download:CSSProperties;
  uploadedFile : CSSProperties;
}

const baseStyles: Styles = {
  label: {
    fontFamily: "var(--primary-font-medium)",
    fontSize: 28,
    color: "var(--text-color)",
    lineHeight: 1.2,
    display: "block",
  },

  error: {
    color: "#EA4335",
    fontSize: 16,
    fontFamily: "var(--primary-font-medium)",
  },
  whiteWrap: {
    paddingLeft: 50,
    paddingRight: 50,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingTop: 100,
    paddingBottom: 60,
  },
  btnBlue: {
    width: 700,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "var(--white-color)",
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 60,
  },
  heading: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 20,
    color: "var(--text-color)",
  },
  uploadTxt: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 14,
    color: "var(--text-color)",
    border: "1px solid #AFABAB",
    padding: 10,
    borderRadius: 6,
    width: 400,
  },
  uploadedFile:{
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 14,
    color: "var(--text-color)",
    marginTop:10
  },
  labelText: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 15,
    color: "var(--text-color)",
    borderTopWidth:1,
    borderTopColor:"#F5F5F5",
    borderTopStyle:"solid",
    marginTop:20,
    paddingTop:20,
    marginBottom:20
  },
  templateIcon: {
    backgroundColor: "#F5F5F5",
    borderRadius: 6,
    paddingLeft: 13,
    paddingRight:13,
    paddingTop:10,
    paddingBottom:10,
    fontSize: 24,
    marginRight:20,
    color: "var(--text-color)",
  },
  templateText: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 18,
    color: "var(--text-color)",
  },
  download:{
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 18,
    color: "var(--primary-color)",
    backgroundColor:"transparent",
    borderWidth:0
  }
};

const mobileStyles: Partial<Styles> = {
  labelText: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 12,
    color: "var(--text-color)",
    borderTopWidth:1,
    borderTopColor:"#F5F5F5",
    borderTopStyle:"solid",
    marginTop:20,
    paddingTop:20,
    marginBottom:20
  },
  whiteWrap: {
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: "var(--white-color)",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingTop: 10,
    paddingBottom: 10,
  },
  uploadTxt: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 14,
    color: "var(--text-color)",
    border: "1px solid #AFABAB",
    padding: 10,
    borderRadius: 6,
    width:"100%",
  },
};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};
