import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    paymentList : [],
};

export const Payment = createSlice({
    name: "Payment",
    initialState,
    reducers: {
        SET_PAYMENT_LINK: (state, action) => {
            state.paymentList = action.payload;
        },
        
    },
});


export const { SET_PAYMENT_LINK } = Payment.actions;
export default Payment.reducer;