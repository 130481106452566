import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEL_SJR7heME4upzAwWpRFd5CD9h3O-RM",
  authDomain: "easylife-507b5.firebaseapp.com",
  projectId: "easylife-507b5",
  storageBucket: "easylife-507b5.appspot.com",
  messagingSenderId: "81090566157",
  appId: "1:81090566157:web:0b3c826f67214bf15b9d44",
  measurementId: "G-775K4XTX2W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { app, messaging };
