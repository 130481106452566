import { createSlice } from '@reduxjs/toolkit';

const languageSlice = createSlice({
  name: 'lan',
  initialState: [],
  reducers: {
    languageId:(state, action)=>{
      return action.payload;
    },
  },
});

export const { languageId } = languageSlice.actions;

export default languageSlice.reducer;