import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    // mainCategory : [],
    selected_product_id : [],
    user_image: false,
    selectedSubcategory_id:"",
};

export const productId = createSlice({
    name: "productId",
    initialState,
    reducers: {
        SET_SELECTED_PRODUCT: (state, action) => {
            state.selected_product_id = action.payload;
        },
        SET_SELECTED_SUBCATEGORY_ID: (state, action) => {
            state.selectedSubcategory_id = action.payload;
        },
        USER_IMAGE:(state, action) => {
            state.user_image = action.payload;
        },
        CLEAR_IMAGE:(state) =>{
            state.user_image = ""
        }
    },
});


export const {  SET_SELECTED_PRODUCT,USER_IMAGE,CLEAR_IMAGE,SET_SELECTED_SUBCATEGORY_ID } = productId.actions;
export default productId.reducer;
