// Login.style.ts

import { CSSProperties } from "react";
import TitleBg from "../../assets/images/title-bg.png";

export interface Styles {
  bg: CSSProperties;
  bottomText: CSSProperties;
  storeWrap: CSSProperties;
  storeImageWrap: CSSProperties;
  storeImage: CSSProperties;
  searchRow :CSSProperties;
  storeNameWrap: CSSProperties;
  storeName: CSSProperties;
  storeHeader: CSSProperties;
  timeWrap: CSSProperties;
  time: CSSProperties;
  location: CSSProperties;
  ratingWrap: CSSProperties;
}

const baseStyles: Styles = {
  searchRow: {
    marginBottom: 70,
    maxWidth: 1142,
    marginLeft: "auto",
    marginRight: "auto",
  },
  bg: {
    backgroundImage: `url(${TitleBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    paddingTop: 40,
    paddingBottom: 40,
    marginBottom: 20,
  },
  bottomText: {
    fontFamily: "var(--primary-font-semibold)",
    fontSize: 12,
    lineHeight: 1.2,
    color: "var(--text-color)",
    marginTop: 10,
    position: "absolute",
    left: "auto",
    right: "auto",
    bottom: 10,
  },
  storeWrap: {},
  storeImageWrap: {width:70,height:70},
  storeImage: {objectFit:"contain",width:"100%",height:"100%",borderRadius:15},
  storeNameWrap: {},
  storeName: {
    marginLeft:20,
    fontFamily:'var(--primary-font-semibold)',
    fontSize:36,
    color:'var(--text-color)',
    marginBottom:0
  },
  storeHeader: {
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    marginBottom:15,
    marginTop:30
  },
  timeWrap: {
    display:"flex",
    backgroundColor:"#D9D9D9",
    borderRadius:3,
    alignItems:"center",
    justifyContent:"center",
    width:150,
    textAlign:"center",
    padding:10
  },
  time: {
    marginBottom:0,
    fontFamily:'var(--primary-font-medium)',
    fontSize:14,
    color:'var(--black-color)'
  },
  ratingWrap: {
    display:"flex",
    alignItems:"center",
    marginTop:0,
    marginBottom:0,
    float:"left",
    cursor:"pointer",
    marginLeft:20,
    // justifyContent:"center"
  },
  location: {
    fontFamily:'var(--primary-font-medium)',
    fontSize:24,
    color:'#AFABAB',
    marginBottom:15
  },
};

const mobileStyles: Partial<Styles> = {
  storeName: {
    marginLeft:20,
    fontFamily:'var(--primary-font-semibold)',
    fontSize:20,
    color:'var(--text-color)'
  },
  storeImageWrap: {width:40,height:40},

};
export const getStyles = (isMobile: boolean): Styles => {
  if (isMobile) {
    return {
      ...baseStyles,
      ...mobileStyles,
    };
  }
  return baseStyles;
};